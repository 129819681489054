import {Action, createReducer, on} from '@ngrx/store';
import * as LoginActions from './LoginUserStoreActions';
import {BaseClient} from '../../../../../shared/domain/Response/BaseClient.entity';

export interface LoginUserState {
  token: string;
  sessionInfo: BaseClient;
  error: string;
}

const initialLoginState: LoginUserState = {
  token: null,
  sessionInfo: null,
  error: null,
};

const reducer = createReducer(
  initialLoginState,
  on(LoginActions.addToken, (state, payload) =>
    ({
      ...state,
      token: payload.token,
      sessionInfo: state.sessionInfo,
      error: null
    })),
  on(LoginActions.addUserSessionInfo, (state, payload) =>
    ({
      ...state,
      token: state.token,
      sessionInfo: payload.sessionInfo,
      error: null
    })),
  on(LoginActions.loginFailure, (state, payload) =>
    ({
      ...state,
      token: null,
      sessionInfo: state.sessionInfo,
      error: payload.error
    })),
  on(LoginActions.clearLogin, () => initialLoginState)
);

export function LoginUserReducer(state: LoginUserState, action: Action) {
  return reducer(state, action);
}
