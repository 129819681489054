import {Injectable} from '@angular/core';
import {HttpClientBase} from '../../../../shared/Infrastructure/Http/HttpClientBase';
import {BillRepository} from '../../domain/bill.repository';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {HttpParams} from '@angular/common/http';
import {Bill} from '../../domain/entities/bill.entity';
import {BaseSimpleTx} from '../../../../shared/domain/Response/BaseSimpleTx.entity';

@Injectable({providedIn: 'root'})
export class BillServices extends HttpClientBase implements BillRepository {

  serverApi = environment.urlApi;

  getBill(token: string): Observable<Bill> {
    const resource = 'bill';
    const billParams = new HttpParams()
      .set('token', token);

    return this.getWithParameters(`${this.serverApi}/${resource}`, billParams)
      .pipe(
        map(response => response.data),
        catchError(err => throwError(err))
      );
  }

  generateBill(
    usdValue: number,
    coinSymbol: string,
    network: string,
    customerUserId?: string,
    customerUsername?: string): Observable<BaseSimpleTx> {
    const resource = 'own-bill';
    const body = {
      usdValue,
      coinSymbol,
      network,
      ...(customerUserId) && {customerUserId},
      ...(customerUsername) && {customerUsername}
    };

    return this.post(`${this.serverApi}/${resource}`, body)
      .pipe(
        map(response => response.success ? response.data : null),
        catchError(err => throwError(err))
      );
  }
}
