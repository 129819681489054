<div class="page-not-found">
  <div class="page-not-found__content">
    <div class="page-not-found__content-title">
      <div class="page-not-found__content-title-group">
        <label class="page-not-found__content-title-group--four">4</label>
        <img class="page-not-found__content-title-group--futlogo" ngSrc="assets/img/home-main/fut-logo.png" disableOptimizedSrcset width="382" height="410" alt="...">
        <label class="page-not-found__content-title-group--four">4</label>
      </div>

      <div class="page-not-found__content-title--message">
        <span class="page-not-found__content-title--message-text">{{'PAGE_NOT_FOUND.OPS' | translate}}</span>
        <span class="page-not-found__content-title--message-text">{{'PAGE_NOT_FOUND.MESSAGE' | translate}}</span>
      </div>

      <div class="page-not-found__content-title--back">
        <button (click)="redirect()" class="page-not-found__content-title--back-button">{{'PAGE_NOT_FOUND.MESSAGE_BUTTON' | translate}}</button>
      </div>
    </div>
    <div class="page-not-found__content-img">
      <img ngSrc="assets/img/404-page/server.png" disableOptimizedSrcset width="661" height="364" alt="...">
    </div>
  </div>
  <div class="firefly" *ngFor="let i of fireflies"></div>
</div>
