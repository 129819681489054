import {Component, OnInit} from '@angular/core';
import {HomeModulesApplication} from '../../../application/home-modules.application';
import {VerticalMenuServices} from '../../../infrastructure/services/vertical-menu.services';
import {FacadeMenuStore} from '../../../infrastructure/vertical-menu/facade-menu.store';
import {LoginUserService} from '../../../../auth/Infrastructure/Services/LoginUser.service';
import {FacadeLoginUserStore} from '../../../../auth/Infrastructure/Store/LoginUser/FacadeLoginUserStore';
import {LoginUserApplication} from '../../../../auth/Application/LoginUserApplication';
import {StorageService} from '../../../../../shared/Infrastructure/Services/storage.service';
import {MatDialog} from '@angular/material/dialog';
import {VerticalMenuStateService} from '../../../../../shared/Infrastructure/Services/vertical-menu-state.service';
import {Module} from '../../../../../shared/domain/Response/Modules.entity';
import {RolModules} from '../../../../../shared/domain/Response/RolModules.entity';
import {GroupedModules} from '../collapsible-menu-group/collapsible-menu-group.component';

@Component({
  selector: 'app-desktop-vertical-menu',
  templateUrl: './desktop-vertical-menu.component.html',
  styleUrls: ['./desktop-vertical-menu.component.scss']
})
export class DesktopVerticalMenuComponent implements OnInit {

  public menuElements: Array<Module>;
  public infoRole: RolModules;
  private homeModulesApplication: HomeModulesApplication;
  private loginUserApplication: LoginUserApplication;
  public userSessionEmail: string;

  public groupedModules: Array<GroupedModules> = [];

  constructor(private verticalMenuServices: VerticalMenuServices,
              private verticalMenuStore: FacadeMenuStore,
              private loginUserService: LoginUserService,
              private loginUserStoreFacade: FacadeLoginUserStore,
              private verticalMenuState: VerticalMenuStateService,
              private matDialog: MatDialog,
              private storageService: StorageService) {
    this.homeModulesApplication = new HomeModulesApplication(verticalMenuServices, verticalMenuStore);
    this.loginUserApplication = new LoginUserApplication(loginUserService, loginUserStoreFacade);
  }

  async ngOnInit() {
    this.homeModulesApplication.getRoleAccess();
    this.homeModulesApplication.roles$.subscribe((value) => {
      if (value) {
        const allMenuElements = value.moduleActions.map(ma => ma.module);
        const groupedElements = allMenuElements.filter(m => !!m.group);
        this.menuElements = value ? allMenuElements.filter(m => !m.group)
          : this.menuElements;
        this.toGroupedMenuElements(groupedElements);
        this.storageService.setDataModule(allMenuElements);
        this.infoRole = value ?? this.infoRole;
        this.storageService.setDataRole(this.infoRole);
      }
    });
    this.loginUserApplication.userSessionInfo$.subscribe(value => this.userSessionEmail = value?.email ?? '');
  }

  toGroupedMenuElements(modules: Array<Module>) {
    let group: GroupedModules;
    modules.forEach((m, i, array) => {
      if (m.group) {
        group = {
          group: m.group,
          modules: array.filter(el => el.group.name === m.group.name)
        };
        const groupExists = this.groupedModules.find(gm => gm.group.name === group.group.name);
        if (groupExists) {
          this.groupedModules = this.groupedModules.map(g => {
            return g.group === group.group ? group : g;
          });
        } else {
          this.groupedModules.push(group);
        }
      }
    });
  }
}
