import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RolesState} from './roles-store-reducer';

export const ROLES_STATE = 'roles';
const RolesState = createFeatureSelector<RolesState>(ROLES_STATE);

export const selectRoles = createSelector(RolesState, (state) => state.roles);
export const selectRoleDetail = createSelector(RolesState, (state) => state.roleDetail);
export const selectError = createSelector(RolesState, (state) => state.error);

