import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
  name: 'substringParam'
})
export class SubstringParamPipe implements PipeTransform {

  transform(value: any, from?: number, length?: number): any {
    if (typeof value === 'number') {
      return value?.toString().substr(from ? from : 0, length ? length : 8);
    } else if ( typeof value === 'string'){
      return value?.substr(from ? from : 0, length ? length : 8) + '...';
    }
  }

}
