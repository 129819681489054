<div class="main">
  <div class="main__title">
    <span>{{'REPORTS.CLIENT' | translate}}: {{client}}</span>
  </div>
  <div class="main__cards">
    <div class="cards">
      <span class="cards__title">{{'DASHBOARD.CHARTS_TITLE_WITHDRAWALS' | translate}}</span>
      <div class="card" *ngFor="let withdrawal of withdrawals">
        <div class="card__field">
          <label class="card__field--name">{{fields[0] | translate}}</label>
          <span>{{withdrawal?.finishDate | date:  dateUtils.datePipeFormatDays}}</span>
        </div>
        <div class="card__field">
          <label class="card__field--name">{{fields[1] | translate}}</label>
          <span>{{withdrawal?.invoiceId.slice(-8)}}</span>
        </div>
        <div class="card__field">
          <label class="card__field--name">{{fields[2] | translate}}</label>
          <span>{{withdrawal?.quantity}}</span>
        </div>
        <div class="card__field">
          <label class="card__field--name">{{fields[3] | translate}}</label>
          <span class="card__value">{{toCurrency(withdrawal?.totalTransactionsAmount)}}</span>
        </div>
        <div class="card__field">
          <label class="card__field--name">{{fields[4] | translate}}</label>
          <span>{{withdrawal?.percentage + '%'}}</span>
        </div>
        <div class="card__field">
          <label class="card__field--name">{{fields[5] | translate}}</label>
          <span>{{toCurrency(withdrawal?.rate)}}</span>
        </div>
        <div class="card__field">
          <label class="card__field--name">{{fields[6] | translate}}</label>
          <button (click)="goToDetail(withdrawal?.invoiceId)">{{ 'TRANSACTIONS.VIEW_DETAIL' | translate }}</button>
        </div>
      </div>
      <span class="cards__title">{{'DASHBOARD.CHARTS_TITLE_DEPOSITS' | translate}}</span>
      <div class="card" *ngFor="let deposit of deposits">
        <div class="card__field">
          <label class="card__field--name">{{fields[0] | translate}}</label>
          <span>{{deposit?.finishDate | date: dateUtils.datePipeFormatDays }}</span>
        </div>
        <div class="card__field">
          <label class="card__field--name">{{fields[1] | translate}}</label>
          <span>{{deposit?.invoiceId.slice(-8)}}</span>
        </div>
        <div class="card__field">
          <label class="card__field--name">{{fields[2] | translate}}</label>
          <span>{{deposit?.quantity}}</span>
        </div>
        <div class="card__field">
          <label class="card__field--name">{{fields[3] | translate}}</label>
          <span class="card__value">{{toCurrency(deposit?.totalTransactionsAmount)}}</span>
        </div>
        <div class="card__field">
          <label class="card__field--name">{{fields[4] | translate}}</label>
          <span>{{deposit?.percentage + '%'}}</span>
        </div>
        <div class="card__field">
          <label class="card__field--name">{{fields[5] | translate}}</label>
          <span>{{toCurrency(deposit?.rate)}}</span>
        </div>
        <div class="card__field">
          <label class="card__field--name">{{fields[6] | translate}}</label>
          <button (click)="goToDetail(deposit?.invoiceId)">{{ 'TRANSACTIONS.VIEW_DETAIL' | translate }}</button>
        </div>
      </div>
    </div>
    <div class="main__totals totals">
      <table class="totals__table">
        <tbody class="table__body">
        <tr class="table__body--row">
          <td class="table__body--cell table__body--bold">{{"REPORTS.DEPOSITS" | translate}}:</td>
          <td class="table__body--cell">{{toCurrency(totalDeposits)}}</td>
          <td class="table__body--cell table__body--bold">{{"REPORTS.WITHDRAWALS" | translate}}:</td>
          <td class="table__body--cell">{{toCurrency(totalWithdrawals)}}</td>
          <td class="table__body--cell table__body--bold">{{'Total'}}:</td>
          <td class="table__body--cell">{{toCurrency(total)}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="main__totals totals totals--column column">
      <table class="column__table">
        <tbody class="table__body">
        <tr class="table__body--row">
          <td class="table__body--cell table__body--bold">{{"REPORTS.DEPOSITS" | translate}}:</td>
          <td class="table__body--cell">{{toCurrency(totalDeposits)}}</td>
        </tr>
        <tr class="table__body--row">
          <td class="table__body--cell table__body--bold">{{"REPORTS.WITHDRAWALS" | translate}}:</td>
          <td class="table__body--cell">{{toCurrency(totalWithdrawals)}}</td>
        </tr>
        <tr class="table__body--row">
          <td class="table__body--cell table__body--bold">{{'Total'}}:</td>
          <td class="table__body--cell">{{toCurrency(total)}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
