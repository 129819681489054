import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Param} from '../search-param/search-param.component';
import {BaseListRequestParams} from '../../../domain/Request/BaseListRequestParams.entity';
import {MatDialog} from '@angular/material/dialog';
import {SelectedCoin} from '../select-coin-attach-file/select-coin-attach-file.component';
import {Actions} from '../../../domain/constants/actions.constant';
import {Coin} from '../../../domain/Response/Coin.entity';
import {CurrencyUtils} from '../../../domain/Utils/CurrencyUtils';
import {CoinConstants} from '../../../domain/constants/coin.constants';

@Component({
    selector: 'app-list-modules-header',
    templateUrl: './list-modules-header.component.html',
    styleUrls: ['./list-modules-header.component.scss']
})
export class ListModulesHeaderComponent {

    @ViewChild('Select') coinSelectHTML: HTMLDivElement;

    public UserSearch: string;
    public useUserSearch: boolean;

    @Input('searchParams')
    searchParamOptions: Array<Param>;

    @Input()
    statusOpts: Array<string>;

    @Input()
    statusI18nDir: string;

    @Input()
    startDate: Date;

    @Input()
    finalDate: Date;

    @Input('sortParams')
    sortParamOptions: Array<Param>;

    @Input()
    set userSearch(value: string) {
        this.UserSearch = value;
        this.useUserSearch = true;
    }

    @Input()
    showClientView: boolean;

    @Input()
    matDialog: MatDialog;

    @Input()
    sharedBtnId: string;

    @Input()
    title: string;

    @Input()
    tooltip: string;

    @Input()
    actions: Array<string> = [];

    @Input()
    swapMovements: Array<string>;

    @Input()
    movementI18nDir: string;

    @Input()
    swapTypes: Array<string>;

    @Input()
    typeI18nDir: string;

    @Input()
    coinSelectOptions: Array<Coin>;

    @Input()
    selectedCoin: Coin;

    @Input('defaultSearchParam') selectedSearchParam: string;
    @Input('defaultSearchValue') selectedSearchValue: string;
    @Input('defaultSortParam') selectedSortParam: string;
    @Input('defaultSortValue') selectedSortValue: string;

    @Output()
    selectedCoinChange: EventEmitter<Coin> = new EventEmitter<Coin>();

    @Output()
    downloadFile: EventEmitter<BaseListRequestParams> = new EventEmitter<BaseListRequestParams>();

    @Output()
    search: EventEmitter<BaseListRequestParams> = new EventEmitter<BaseListRequestParams>();

    @Output()
    uploadFile: EventEmitter<{ file: File, selectedCoin: SelectedCoin }> = new EventEmitter<{ file: File, selectedCoin: SelectedCoin }>();

    @Output()
    generateBill: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    goDeposit: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    goCreateNotification: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    userSearchChange: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    newUser: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    newRole: EventEmitter<any> = new EventEmitter<any>();

    public searchParam: string;
    public searchValue: string;
    public sortParam: string;
    public sortValue: string;

    constructor(private currencyUtils: CurrencyUtils) {
    }

    onSearch() {
        this.search.emit({
            page: '1',
            searchParam: this.searchParam,
            searchValue: this.searchValue,
            startDate: this.startDate,
            finalDate: this.finalDate,
            sortParam: this.sortParam,
            sortType: this.sortValue
        });
    }

    upload(data: { file: File, selectedCoin: SelectedCoin }) {
        this.uploadFile.emit(data);
    }

    download(req: BaseListRequestParams) {
        this.downloadFile.emit(req);
    }

    setParams(param: Param) {
        this.searchParam = param.param;
        this.searchValue = param.value;
        this.sortParam = null;
        this.sortValue = null;
        this.onSearch();
    }

    setSortParams(param: Param) {
        this.sortParam = param.param;
        this.sortValue = param.type;
        this.onSearch();
    }

    openGenerateBillModal() {
        this.generateBill.emit();
    }

    goToDeposit() {
        this.goDeposit.emit();
    }

    createNotification() {
        this.goCreateNotification.emit();
    }

    public get action() {
        return Actions;
    }

    onUserSearch() {
        this.userSearchChange.emit(this.UserSearch);
    }

    coinOptionToShow(coin: Coin): string {
        const balance = this.currencyUtils.formatCurrency(coin?.balance, coin?.symbol);
        const blockchain = coin?.blockchainSymbol === this.coinConstants.TRX.networkSymbol ? 'TRC20' :
            coin?.blockchainSymbol === this.coinConstants.ETH.networkSymbol ? 'ERC20' : coin?.blockchainSymbol;
        return `${coin?.symbol} (${blockchain})     ${balance}`;
    }

    setMenuSize(el: HTMLDivElement) {
        this.coinSelectHTML = el;
        document.documentElement.style.setProperty('--coin-select-width', this.coinSelectHTML.clientWidth + 'px');
    }

    onSelectedCoin(coin: Coin) {
        this.selectedCoinChange.emit(coin);
        this.onSearch();
    }

    onCreateUser() {
        this.newUser.emit();
    }

    get coinConstants() {
        return CoinConstants;
    }

    get useCoinSelect(): boolean {
        if (this.coinSelectOptions && this.coinSelectOptions.length > 0) {
            document.documentElement.style.setProperty('--skip-row-action', '1');
        }
        return (this.coinSelectOptions && this.coinSelectOptions.length > 0);
    }

    onCreateRol() {
        this.newRole.emit();
    }


}
