import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() totalDocs = 0;
  @Input() totalPages = 1;
  @Input() page = 1;
  @Input() pages = [];
  @Input() limitPages = 4;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
  }

  ngOnInit() {
    if (!this.totalDocs) {
      this.totalDocs = 0;
    }

    if (!this.totalPages) {
      this.totalPages = 1;
    }

    if (!this.page) {
      this.page = 1;
    }

    if (!this.pages) {
      this.getPages();
    }
  }

  ngOnChanges() {
    this.getPages();
  }

  getPages() {
    if (this.totalPages > 0) {
      let lengthPages;
      let startPage;

      lengthPages = (this.totalPages < this.limitPages) ? this.totalPages : this.limitPages;

      startPage = (this.page - this.limitPages) >= 0 ? (this.page - this.limitPages) + 1 : 1;
      this.pages = Array.from({length: lengthPages}, (v, i) => i + startPage);
    }
  }

  selectPage(page: number) {
    this.pageChange.emit(page);
  }

}
