import {Injectable} from '@angular/core';
import {HomeModulesStore} from '../../domain/home-modules.store';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {addAlerts, addHomeModules, addModulesError, addRoles, clearHomeModulesStore} from './menu-store-actions';
import {selectAlerts, selectHomeModules, selectModulesError, selectRoles} from './menu-store.selector';
import {Module} from '../../../../shared/domain/Response/Modules.entity';
import {BaseListResponse} from '../../../../shared/domain/Response/BaseListResponse.entity';
import {Alerts} from '../../domain/alerts';
import {RolModules} from '../../../../shared/domain/Response/RolModules.entity';

@Injectable({providedIn: 'root'})
export class FacadeMenuStore implements HomeModulesStore {

  constructor(private store: Store) {
  }

  homeModules$ = (): Observable<Module> => this.store.select(selectHomeModules);
  roles$ = (): Observable<RolModules> => this.store.select(selectRoles);
  modulesError$ = (): Observable<string> => this.store.select(selectModulesError);
  alerts$ = (): Observable<BaseListResponse<Alerts>> => this.store.select(selectAlerts);

  successHomeModules(homeModules: Module): void {
    this.store.dispatch(addHomeModules({homeModules}));
  }

  successRolesAccess(roles: RolModules): void {
    this.store.dispatch(addRoles({roles}));
  }

  successAlerts(alerts: BaseListResponse<Alerts>): void {
    this.store.dispatch(addAlerts({alerts}));
  }

  errorHomeModules(modulesError: string): void {
    this.store.dispatch(addModulesError({modulesError}));
  }

  clearHomeModulesStore(): void {
    this.store.dispatch(clearHomeModulesStore());
  }


}
