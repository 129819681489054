import {AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {SwiperOptions} from 'swiper';
import {SwiperContainer} from 'swiper/element';

@Directive({
  selector: '[fmSwiper]'
})
export class SwiperDirective implements AfterViewInit, OnChanges {

  private readonly swiperElement: SwiperContainer;

  @Input('config')
  config?: SwiperOptions;

  @Input()
  loaded: boolean;

  constructor(private el: ElementRef<SwiperContainer>) {
    this.swiperElement = el.nativeElement;
  }

  ngAfterViewInit() {
    Object.assign(this.swiperElement, this.config);

    this.swiperElement.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.loaded) {
      this.swiperElement.swiper?.init();
    }
  }
}
