import {Component, DoCheck, ElementRef, HostListener, OnInit} from '@angular/core';

import {Router} from '@angular/router';
import {RouterConstants} from '../../../../shared/Infrastructure/router.constants';
import {StorageService} from '../../../../shared/Infrastructure/Services/storage.service';
import {LoginUserApplication} from '../../../auth/Application/LoginUserApplication';
import {LoginUserService} from '../../../auth/Infrastructure/Services/LoginUser.service';
import {FacadeLoginUserStore} from '../../../auth/Infrastructure/Store/LoginUser/FacadeLoginUserStore';
import {VerticalMenuStateService} from '../../../../shared/Infrastructure/Services/vertical-menu-state.service';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {LogoutModalComponent} from '../../../../shared/ui/modals/logout-modal/logout-modal.component';
import {
  ChangePasswordModalComponent
} from '../../../../shared/ui/modals/change-password-modal/change-password-modal.component';
import {SnackBarService} from '../../../../shared/Infrastructure/Services/snack-bar.service';
import {VerticalMenuServices} from '../../infrastructure/services/vertical-menu.services';
import {NotificationSocket} from '../../socket/notificationSocket';
import {HomeConstants} from '../../domain/home.constants';
import {HomeModulesApplication} from '../../application/home-modules.application';
import {FacadeMenuStore} from '../../infrastructure/vertical-menu/facade-menu.store';
import {Alerts} from '../../domain/alerts';
import {DashboardConstants} from '../../../admin/dashboard/domain/constants/dashboard.constants';
import {Company} from '../../../../shared/domain/Response/Company.entity';
import {BaseListResponse} from '../../../../shared/domain/Response/BaseListResponse.entity';
import {broadCastChannel} from '../../../app.component';
import {RolModules} from '../../../../shared/domain/Response/RolModules.entity';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, DoCheck {

  public menuState = false;
  public menuProfileOptionsShow = false;

  private loginUserApplication: LoginUserApplication;
  private homeApplication: HomeModulesApplication;

  public userSessionEmail: string;
  public companyId: string;
  public name: string;
  public lastName: string;

  public selectedCompanyName: string;

  public showContent: boolean;

  public searchLimit = 15;
  public searchValue: string;
  public listValues: Array<Company> = [];

  public inputValue: string;
  public dl;

  private token: string;
  public notificationSocket: NotificationSocket;
  public notification: number;

  public page = 1;
  public limit = 8;
  public alerts: Array<Alerts>;

  constructor(loginUserService: LoginUserService,
              loginUserStoreFacade: FacadeLoginUserStore,
              homeService: VerticalMenuServices,
              homeStoreFacade: FacadeMenuStore,
              private eRef: ElementRef,
              public router: Router,
              private matDialog: MatDialog,
              private snackBarService: SnackBarService,
              private storageService: StorageService,
              private verticalMenuService: VerticalMenuServices,
              public verticalMenuState: VerticalMenuStateService) {
    this.loginUserApplication = new LoginUserApplication(loginUserService, loginUserStoreFacade);
    this.homeApplication = new HomeModulesApplication(homeService, homeStoreFacade);
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.menuProfileOptionsShow = false;
    }
  }

  ngOnInit(): void {
    this.showContent = environment.isAdmin;
    this.loginUserApplication.getUserSessionInfo();
    this.loginUserApplication.userSessionInfo$.subscribe(value => this.userSessionEmail = value ? value.email : '');
    this.loginUserApplication.userSessionInfo$.subscribe(value => {
      if (value) {
        this.companyId = value.companyId;
        this.storageService.setCompanyID(this.companyId);
        this.storageService.setLangIsoCode(value.langIsoCode);
        this.name = value.name;
        this.lastName = value.lastname;
      }
    });

    this.token = this.storageService.getUserToken();
    if (!this.showContent) {
      this.notificationSocket = new NotificationSocket('alerts', this.token);
      this.notificationSocket.io.on('connect', () => {
        this.notificationSocket.notifications().subscribe(value => {
          this.notification = value.data.value;
        });
        this.notificationSocket.io.emit(HomeConstants.HANDLE_COUNT);
      });
      this.homeApplication.getAlerts(this.page.toString(), this.limit.toString());
      this.homeApplication.alerts$.subscribe(value => this.successAlerts(value));
    } else {
      document.documentElement.style.setProperty('--justify-menu-icons', 'center');
    }
  }

  ngDoCheck(): void {
    this.selectedCompanyName = this.storageService.getSelectedCompanyName();
    if (this.selectedCompanyName) {
      this.inputValue = '';
    }
  }

  toggleMenu() {
    this.verticalMenuState.setVerticalMenuState(!this.menuState);
    this.verticalMenuState.menuState$.subscribe(menuState => this.menuState = menuState);
  }

  private successAlerts(value: BaseListResponse<Alerts>) {
    if (value) {
      this.alerts = value.data;
    }
  }


  public openLogoutModal() {
    this.showMenuProfileOptions();
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.data = this.userSessionEmail;

    let dialogModal: MatDialogRef<LogoutModalComponent>;
    dialogModal = this.matDialog.open(LogoutModalComponent, matDialogConfig);
    return dialogModal.afterClosed().subscribe(async value => value ? await this.logoutUser() : null);
  }

  public openChangePasswordModal() {
    this.showMenuProfileOptions();
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.data = this.userSessionEmail;
    matDialogConfig.panelClass = 'change-password-modal-container';

    let dialogModal: MatDialogRef<ChangePasswordModalComponent>;
    dialogModal = this.matDialog.open(ChangePasswordModalComponent, matDialogConfig);
    return dialogModal.afterClosed().subscribe(value => value ? this.snackBarService.openSuccessToast('CHANGE_PASSWORD.SUCCESSFUL_PASSWORD_CHANGED') : null);
  }

  public async logoutUser() {
    this.storageService.logOut();
    this.loginUserApplication.userLogOut();
    await this.router.navigate([RouterConstants.LOGIN]);
    broadCastChannel.postMessage('logout');
  }

  showMenuProfileOptions() {
    this.menuProfileOptionsShow = !this.menuProfileOptionsShow;
  }

  public getUsernameFromSessionInfo() {
    const user = this.userSessionEmail.substring(0, this.userSessionEmail.indexOf('@'));
    const email = this.userSessionEmail.substring(this.userSessionEmail.indexOf('@') + 1).replace('', '');
    return user.charAt(0).toUpperCase() + user.slice(1) + '@' + email.charAt(0).toUpperCase() + email.slice(1);
  }

  characterLock(event) {
    return event.charCode === 32 || event.charCode === 45 ||
      event.charCode >= 48 && event.charCode <= 57 ||
      event.charCode >= 65 && event.charCode <= 90 ||
      event.charCode >= 97 && event.charCode <= 122;
  }

  async searchFilter() {
    if (this.inputValue?.length > 2) {
      this.verticalMenuService.getCompanyFinder(this.inputValue, this.searchLimit)
        .subscribe(
          resp => {
            this.listValues = resp;
          }
        );
    }
  }

  clearSearch() {
    this.inputValue = '';
    this.storageService.clearSelectedCompanyName();
    this.storageService.clearSelectedCompanyId();
    this.selectedCompanyName = '';
    this.storageService.company$.next('');
  }

  setSelectedCompany() {
    if (this.inputValue) {
      const selectedLv = this.listValues.find(resp =>
        resp.name === this.inputValue || resp.companyId === this.inputValue);
      if (selectedLv) {
        this.storageService.setSelectedCompanyName(selectedLv.name);
        this.storageService.setSelectedCompanyId(selectedLv.companyId);
        this.storageService.company$.next(selectedLv.companyId);
      }
    }
    this.selectedCompanyName = this.storageService.getSelectedCompanyName();
  }

  get dashboardConstants() {
    return DashboardConstants;
  }

  async goToDashboardNotification() {
    await this.router.navigate([RouterConstants.DASHBOARD]);
  }


  async goToEditProfile() {
    await this.router.navigate([RouterConstants.PROFILE]);
  }
}
