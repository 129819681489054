import {Component, Input, OnInit} from '@angular/core';
import {VerticalMenuStateService} from '../../../../../shared/Infrastructure/Services/vertical-menu-state.service';
import {NavigationEnd, Router} from '@angular/router';
import {Module} from '../../../../../shared/domain/Response/Modules.entity';
import {environment} from '../../../../../environments/environment';
import {filter} from 'rxjs';

@Component({
  selector: 'app-collapsible-menu-item',
  templateUrl: './collapsible-menu-item.component.html',
  styleUrls: ['./collapsible-menu-item.component.scss']
})
export class CollapsibleMenuItemComponent implements OnInit {

  @Input() moduleIcon: string;
  @Input() moduleName: string;
  @Input() modulePath: string;
  @Input() moduleStatus: string;
  @Input() isRoot: boolean;
  @Input() subModules: Array<Module>;

  @Input() expanded = false;

  constructor(public verticalMenuState: VerticalMenuStateService,
              public router: Router) {
    this.router.events
      .pipe(
        filter(value => value instanceof NavigationEnd)
      )
      .subscribe((value: NavigationEnd) => {
        const pathToCompare = (path) => environment.isAdmin ? `/admin/${path}` : `/${value}`;
        if (this.subModules) {
          const submoduleActive = this.subModules.find(mod => {
            return pathToCompare(mod.path) === value.urlAfterRedirects;
          });
          this.expanded = !(submoduleActive || pathToCompare(this.modulePath) === value.urlAfterRedirects);
        }
      });
  }

  ngOnInit(): void {
    if (this.subModules) {
      const pathToCompare = (path) => environment.isAdmin ? `/admin/${path}` : this.router.url;
      const submoduleActive = this.subModules.find(mod => pathToCompare(mod.path) === this.router.url);
      this.expanded = !(submoduleActive || pathToCompare(this.modulePath) === this.router.url);
    }
  }

  getRoute = () => {
    if (environment.isAdmin) {
      if (this.modulePath) {
        return '/admin/' + this.modulePath;
      } else {
        return this.router.url;
      }
    } else {
      return !this.modulePath ? this.router.url : '/' + this.modulePath;
    }
  }
}
