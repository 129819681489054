import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {environment} from '../../../../environments/environment';
import {HttpClientBase} from '../../../../shared/Infrastructure/Http/HttpClientBase';
import {TraderInformationRepository} from '../../domain/trader-information-repository';
import {TraderInfo} from '../../domain/entities/trader-info.entity';
import {TwoFAQr} from '../../domain/entities/2fa-qr.entity';
import {OtcPlanEntity} from '../../domain/entities/otc-plan.entity';
import {HttpParams} from '@angular/common/http';
import {CountryCodeEntity} from '../../domain/entities/country-code.entity';

@Injectable({providedIn: 'root'})
export class TraderInformationServices extends HttpClientBase implements TraderInformationRepository {

  serverApi = environment.urlApi;

  getTraderInformation(): Observable<TraderInfo> {
    const resource = 'company';
    return this.get(`${this.serverApi}/${resource}`)
      .pipe(
        map(response => response.success ? response.data : null),
        catchError(err => throwError(err))
      );
  }

  updateTraderInformation(scheduledMassWithdrawals: boolean, internalFeeByCompany: boolean): Observable<TraderInfo> {
    const resource = 'company/update-one';

    const body = {
      scheduledMassWithdrawals,
      internalFeeByCompany
    };

    return this.put(`${this.serverApi}/${resource}`, body)
      .pipe(
        map(response => response.success ? response.data : null),
        catchError(err => throwError(err))
      );
  }

  faGenerateQr(): Observable<TwoFAQr> {
    const resource = '2fa/generate';
    const body = {};

    return this.post(`${this.serverApi}/${resource}`, body)
      .pipe(
        map(response => response.success ? response.data : null),
        catchError(err => throwError(err))
      );
  }

  faQrStatus(): Observable<{ status: boolean }> {
    const resource = '2fa/status';
    return this.get(`${this.serverApi}/${resource}`)
      .pipe(
        map(response => response.success ? response.data : null),
        catchError(err => throwError(err))
      );
  }

  faVerify(twoFactorAuthenticationCode: string): Observable<boolean> {
    const resource = '2fa/verify';
    const body = {
      twoFactorAuthenticationCode
    };

    return this.post(`${this.serverApi}/${resource}`, body)
      .pipe(
        map(response => response.success ? response.data?.isValid : null),
        catchError(err => throwError(err))
      );
  }

  faDeleteQr(code: string, otpCode: string): Observable<boolean> {
    const resource = '2fa/remove';
    const body = {
      code,
      otpCode
    };

    return this.delete(`${this.serverApi}/${resource}`, body)
      .pipe(
        map(response => response.success),
        catchError(err => throwError(err))
      );
  }

  activate2FA(twoFactorAuthCode: string, otpCode: string): Observable<boolean> {
    const resource = '2fa/activate';
    const body = {
      twoFactorAuthCode,
      otpCode
    };

    return this.put(`${this.serverApi}/${resource}`, body)
      .pipe(
        map(response => response.success),
        catchError(err => throwError(err))
      );
  }

  send2faOtp(langIsoCode: string): Observable<boolean> {
    const resource = '2fa/send-code';
    const body = {
      langIsoCode
    };

    return this.post(`${this.serverApi}/${resource}`, body)
      .pipe(
        map(response => response.success),
        catchError(err => throwError(err))
      );
  }

  ActivateOtc(bankName: string,
              bankAccount: string,
              otcPlanId: string,
              countryIso2code: string,
              twoFA: string): Observable<TraderInfo> {
    const resource = 'company/activate-otc';

    const body = {
      bankName,
      bankAccount,
      otcPlanId,
      countryIso2code,
      twoFA
    };

    return this.patch(`${this.serverApi}/${resource}`, body)
      .pipe(
        map(response => response.success ? response.data : null),
        catchError(err => throwError(err))
      );
  }

  getOtcPlan(): Observable<Array<OtcPlanEntity>> {
    const resource = 'otc-plan/all';
    return this.get(`${this.serverApi}/${resource}`)
      .pipe(
        map(response => response.success ? response.data : null),
        catchError(err => throwError(err))
      );
  }

  getCountryAll(): Observable<Array<CountryCodeEntity>> {
    const resource = 'country-code/all';
    return this.get(`${this.serverApi}/${resource}`)
      .pipe(
        map(response => response.success ? response.data : null),
        catchError(err => throwError(err))
      );
  }


  getCountryCode(countryIso2code: string): Observable<CountryCodeEntity> {
    const resource = 'country-code/code';

    const paramsRechargeDetail = new HttpParams()
      .set('countryIso2code', countryIso2code);

    return this.getWithParameters(`${this.serverApi}/${resource}`, paramsRechargeDetail)
      .pipe(
        map(response => response.data),
        catchError(error => throwError(error))
      );
  }

}
