import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SwapState} from './swap-store.reducer';


export const SWAP_STATE = 'swap';
const swapState = createFeatureSelector<SwapState>(SWAP_STATE);

export const selectSwap = createSelector(swapState, state => state.swap);
export const selectSwapDetail = createSelector(swapState, state => state.swapDetail);
export const selectError = createSelector(swapState, state => state.error);
