import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {from, lastValueFrom, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {StorageService} from '../Services/storage.service';
import {Router} from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router,
              private translateService: TranslateService,
              private storageService: StorageService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.getSessionWithAuthReq(request, next));
  }

  async getSessionWithAuthReq(request: HttpRequest<any>, next: HttpHandler) {
    const userToken = this.storageService.getUserToken();
    if (userToken) {
      const reqToSend: HttpRequest<any> = await new Promise((resolve) => {
        const isExtraApi = request.url.startsWith(environment.urlCountriesApi);
        const headers = new Headers();
        headers.append('Authorization', userToken);

        if (userToken && !isExtraApi) {
          const req = request.clone({
            setHeaders: {
              Authorization: 'Bearer ' + userToken
            }
          });
          return resolve(req);
        }
        return resolve(request);
      });
      return lastValueFrom(next.handle(reqToSend));
    }
    return lastValueFrom(next.handle(request));
  }
}

