import {createAction, props} from '@ngrx/store';
import {BaseListResponse} from '../../../../../shared/domain/Response/BaseListResponse.entity';
import {RechargeRequest} from '../../domain/entities/RechargeRequest.entity';

export const addRecharge = createAction('[recharge] Add Recharge', props<{
  recharge: BaseListResponse<RechargeRequest>
}>());
export const addRechargeDetail = createAction('[recharge] Add Recharge Details', props<{
  rechargeDetail: RechargeRequest
}>());
export const addRechargeError = createAction('[recharge] Add Error of Recharge', props<{ error: string }>());
export const clearRecharge = createAction('[recharge] clear Recharge');
