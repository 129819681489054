import {Component} from '@angular/core';
import {RouterConstants} from '../../../Infrastructure/router.constants';
import {Router} from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent  {
  public fireflies = [];

  constructor(public router: Router) {
    this.createFirefly();
  }

  public async redirect() {
    await this.router.navigate([RouterConstants.DASHBOARD]);
  }

  createFirefly(){
    for (let i = 0; i < 30; i++){
      this.fireflies.push(i);
    }
  }

}
