import {Component, DoCheck, Inject, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {StorageService} from '../../../../../shared/Infrastructure/Services/storage.service';
import {SnackBarService} from '../../../../../shared/Infrastructure/Services/snack-bar.service';
import {NotificationsService} from '../../infrastructure/services/notifications.service';
import {notificationsConstants} from '../../../../../shared/domain/constants/notification';
import {DateUtils} from '../../../../../shared/domain/Utils/DateUtils';
import {FiltersUtils} from '../../../../../shared/domain/Utils/FiltersUtils';

@Component({
  selector: 'app-generate-notification-modal',
  templateUrl: './generate-notification-modal.component.html',
  styleUrls: ['./generate-notification-modal.component.scss']
})
export class GenerateNotificationModalComponent implements OnInit, DoCheck, OnDestroy{

  public notificationForm: FormGroup;

  public selectedCompanyId: string;
  public inputValue: string;
  public priorityName: string;

  private actualModule = 'notifications';

  public startDate: Date;
  public endDate: Date;

  public filters;

  constructor(private snackBarService: SnackBarService,
              private notificationService: NotificationsService,
              private translateService: TranslateService,
              private formBuilder: FormBuilder,
              private filtersUtils: FiltersUtils,
              private storageService: StorageService,
              public dialogRef: MatDialogRef<GenerateNotificationModalComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.filters = this.filtersUtils?.getFilters(this.actualModule);
  }

  ngOnInit(): void {
    this.buildGenerateNotificationForm();
    this.startDate = this.filters.startDate;
    this.endDate = this.filters.endDate;
  }

  ngOnDestroy() {
    this.storageService.clearTableFilters(this.actualModule, 'dateRange');
  }

  priorityInvalid = () => this.notificationForm.get('priorityName').invalid && this.notificationForm.get('priorityName').touched;
  descriptionInvalid = () => this.notificationForm.get('description').invalid && this.notificationForm.get('description').touched;

  accept = () => this.dialogRef.close(true);
  cancel = () => this.dialogRef.close(false);


  ngDoCheck(): void {
    this.selectedCompanyId = this.storageService.getSelectedCompanyName();
    this.notificationForm.controls.companyId.setValue(this.selectedCompanyId);
    if (this.selectedCompanyId) {
      this.inputValue = '';
    }
  }

  buildGenerateNotificationForm() {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    this.notificationForm = new FormGroup(
      {
        priorityName: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        startDate: new FormControl(new Date(year, month, 1)),
        endDate: new FormControl(new Date(year, month + 1, 0)),
        companyId: new FormControl(this.selectedCompanyId, Validators.required)
      }
    );
  }

  createNotification(event: Event, notificationForm) {
    const {endDate, startDate, ...object} = notificationForm.value;
    object.finishDate = DateUtils.setEndHours(this.endDate);
    object.startDate = DateUtils.setStartHours(this.startDate);
    event.preventDefault();
    if (this.notificationForm.valid) {
      const {priorityName} = this.notificationForm.value;
      this.notificationForm.disable();
      switch (priorityName) {
        case notificationsConstants.PRI.status:
          this.priorityName = notificationsConstants.PRI.status;
          break;
        case notificationsConstants.WAR.status:
          this.priorityName = notificationsConstants.WAR.status;
          break;
        case notificationsConstants.STA.status:
          this.priorityName = notificationsConstants.STA.status;
          break;
        case notificationsConstants.INFO.status:
          this.priorityName = notificationsConstants.INFO.status;
          break;
      }
      const company = this.storageService.getSelectedCompanyId();
      this.notificationService.generateNotification
      (object.startDate, object.finishDate, object.description, company, this.priorityName)
        .subscribe(
          () => {
            this.accept();
            this.snackBarService.openSuccessToast('NOTIFICATION.GENERATE_NOTIFICATION.NOTIFICATION_SUCCESS');
          },
          () => this.snackBarService.openErrorToast('NOTIFICATION.GENERATE_NOTIFICATION.NOTIFICATION_ERROR')
        );
    } else {
      this.notificationForm.markAllAsTouched();
    }
  }

  startDateEvent(date) {
    this.startDate = date;
  }

  finalDateEvent(date) {
    this.endDate = date;
    this.storageService.setTableFilters(this.actualModule, 'dateRange', {startDate: this.startDate, endDate: date});
  }


}
