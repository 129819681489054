import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

import {SharedModule} from '../../shared/shared.module';
import {HomeMainComponent} from './ui/home-main/home-main.component';
import {HeaderComponent} from './ui/header/header.component';
import {MobileVerticalMenuComponent} from './ui/vertical-menu/mobile-vertical-menu/mobile-vertical-menu.component';
import {DesktopVerticalMenuComponent} from './ui/vertical-menu/desktop-vertical-menu/desktop-vertical-menu.component';
import {CollapsibleMenuItemComponent} from './ui/vertical-menu/collapsible-menu-item/collapsible-menu-item.component';
import {HomeRoutingModule} from './home-routing.module';
import {NgProgressModule} from 'ngx-progressbar';
import {NgProgressHttpModule} from 'ngx-progressbar/http';
import {MaterialModule} from '../core/material/material.module';
import {MatBadgeModule} from '@angular/material/badge';
import { CollapsibleMenuGroupComponent } from './ui/vertical-menu/collapsible-menu-group/collapsible-menu-group.component';


@NgModule({
  declarations: [
    HomeMainComponent,
    HeaderComponent,
    MobileVerticalMenuComponent,
    DesktopVerticalMenuComponent,
    CollapsibleMenuItemComponent,
    CollapsibleMenuGroupComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    HomeRoutingModule,
    MaterialModule,
    NgProgressHttpModule,
    NgProgressModule.withConfig({
      meteor: true,
      color: '#39224E',
      spinner: false,
    }),
    MatBadgeModule,
    NgOptimizedImage,
  ]
})
export class HomeModule {
}
