import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fixedNoRound'
})
export class FixedNoRoundPipe implements PipeTransform {

  transform(value: number, precision?: number): string {
    const isInteger = Number.isInteger(value);
    const factor = Math.pow(10, precision ?? 1);
    return !isInteger ? (Math.floor(value * factor) / factor).toFixed(precision) : String(value);
  }
}
