export const environment = {
  production: false,
  urlApi: 'https:///144.217.88.168:5000/api',
  hmr: false,
  urlCountriesApi: 'https://restcountries.eu/',
  isAdmin: true,

  // local

  captcha_key_v3: '6LfAQmUcAAAAAKxUq7JT-nd_jyv0gLaBRvjeP_bO',
  captcha_key_v2: '6Ldt0GgcAAAAABgQ8Nvz2tN5LD2XO9cHYOyOAS-a',
};
