import {Component, Input} from '@angular/core';
import {Coin} from '../../../domain/Response/Coin.entity';
import {CurrencyUtils} from '../../../domain/Utils/CurrencyUtils';
import {CoinConstants} from '../../../domain/constants/coin.constants';

@Component({
  selector: 'coin-card',
  templateUrl: './coin-card.component.html',
  styleUrls: ['./coin-card.component.scss']
})
export class CoinCardComponent {

  @Input({
    alias: 'coin',
    required: true
  })
  coin: Coin;

  @Input()
  loading: boolean;

  constructor(public currencyUtils: CurrencyUtils) {
  }

  get coinParsedName(): string {
    let blockchain;
    switch (this.coin.blockchainSymbol) {
      case this.coinConstants.ETH.networkSymbol:
        blockchain = 'ERC20';
        break;
      case this.coinConstants.TRX.networkSymbol:
        blockchain = 'TRC20';
        break;
      case this.coinConstants.BNB.networkSymbol:
        blockchain = 'BSC';
        break;
      case this.coinConstants.MATIC.networkSymbol:
        blockchain = 'Polygon';
        break;
      default:
        blockchain = null;
        break;
    }
    if (this.coin.symbol === this.coinConstants.USDT.symbol && blockchain) {
      return `${this.coin.name} (${blockchain})`;
    }
    return `${this.coin.name}`;
  }
  get coinConstants() {
    return CoinConstants;
  }
}
