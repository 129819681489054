import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'ftswp-toggle',
  templateUrl: './ftswp-toggle.component.html',
  styleUrls: ['./ftswp-toggle.component.scss']
})
export class FtswpToggleComponent {

  @Input()
  checked: boolean;

  @Output()
  toggle: EventEmitter<boolean> = new EventEmitter<boolean>();



  check(ev: any) {
    this.toggle.emit(ev);
  }
}
