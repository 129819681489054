import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VerticalMenuStateService {

  private menuState = new BehaviorSubject<boolean>(false);
  menuState$ = this.menuState.asObservable();

  constructor() {
  }

  setVerticalMenuState(state: boolean) {
    this.menuState.next(state);
  }
}
