import {Action, createReducer, on} from '@ngrx/store';
import {addError, addNotifications, clearStore} from './notifications-store.actions';
import {NotificationsListResponse} from '../../domain/notifications-list.response';

export interface NotificationsState {
  notifications: NotificationsListResponse;
  error: string;
}

const initialNotificationsState: NotificationsState = {
  notifications: null,
  error: null
};

const reducer = createReducer(initialNotificationsState,
  on(addNotifications, (state, payload) =>
    ({
      ...state,
      notifications: payload.notifications,
      error: state.error
    })),
  on(addError, (state, payload) =>
    ({
      ...state,
      notifications: state.notifications,
      error: payload.error
    })),
  on(clearStore, () => initialNotificationsState)
);

export function NotificationsReducer(state: NotificationsState, action: Action) {
  return reducer(state, action);
}
