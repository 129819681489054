<div class="auth-factors-modal">
  <div class="auth-factors-modal-body">
    <div class="auth-factors-modal-body__button">
      <button>
        <mat-icon (click)="cancel()"> close</mat-icon>
      </button>
    </div>
    <div class="auth-factors-modal-body__title">
      <span>{{"AUTH_FACTORS.TITLE" | translate}}</span>
      <p>{{"AUTH_FACTORS.SUBTITLE" | translate}}</p>
    </div>
    <div class="auth-factors-modal-body__info">
      <div class="auth-factors-modal-body__info-name">
        <img ngSrc="../../../../assets/img/2fv/pngwing.com.png" disableOptimizedSrcset width="131" height="131" alt="">
      </div>
      <div class="auth-factors-modal-body__info-value">
        <span>{{"AUTH_FACTORS.DOWNLOAD" | translate}}</span>
        <p>{{"AUTH_FACTORS.DOWNLOAD_MESSAGE" | translate}}</p>
      </div>
    </div>
    <div class="auth-factors-modal-body__info">
      <div class="auth-factors-modal-body__info-name">
        <qr-code *ngIf="!geneQr"
                    [value]=""
                    [ngClass]="'bill-qrcode' + isNotQRCreate()"
                    (click)="generateQr()"
                    [size]="100">
        </qr-code>
        <p (click)="generateQr()" *ngIf="!geneQr">{{'AUTH_FACTORS.CLICK' | translate}}</p>
        <qr-code *ngIf="geneQr"
                    [value]="qrInfo"
                    [size]="100">
        </qr-code>
      </div>
      <div class="auth-factors-modal-body__info-value">
        <span>{{"AUTH_FACTORS.QR" | translate}}</span>
        <p>{{"AUTH_FACTORS.QR_MESSAGE" | translate}}</p>
        <span *ngIf="geneQr">{{"AUTH_FACTORS.KEY" | translate}}</span>
        <p *ngIf="geneQr" style="font-weight:bold;">{{base32S}}</p>
      </div>
    </div>
    <div class="auth-factors-modal-body__info">
      <div class="auth-factors-modal-body__info-name">
        <img ngSrc="../../../../assets/img/2fv/Grupo%205828.png" disableOptimizedSrcset width="117" height="161" alt="">
      </div>
      <div class="auth-factors-modal-body__info-value form">
        <form class="auth-factors-modal-body__info-value-actions" [formGroup]="secondFvForm"
              (ngSubmit)="activate2FA($event)">
          <div class="auth-factors-modal-body__info-value-actions--title">
            <span>{{"AUTH_FACTORS.ACTIVATE" | translate}}</span>
            <span>{{"AUTH_FACTORS.ACTIVATE_NOTE" | translate}}</span>
          </div>
          <div class="auth-factors-modal-body__info-value-actions--form-field">
            <span>{{"AUTH_FACTORS.APP_CODE" | translate}}</span>
            <p>{{"AUTH_FACTORS.ACTIVATE_MS_APP" | translate}}</p>
            <mat-form-field
              class="form-field"
              [class.disabled]="!geneQr"
              appearance="fill">
              <input formControlName="twoFactorAuthenticationCode" type="text" pattern="[0-9]{6}"  matInput [disabled]="!geneQr" [readonly]="!geneQr">
              <mat-error
                *ngIf="showError('twoFactorAuthenticationCode')">{{getErrorMessage('twoFactorAuthenticationCode') | translate}}</mat-error>
              <img *ngIf="secondFvForm.controls['twoFactorAuthenticationCode'].valid"
                   ngSrc="/assets/img/general/checked-icon.svg" disableOptimizedSrcset width="26" height="26" alt="..." class="form-field__btn img" matSuffix>
            </mat-form-field>
          </div>
          <div class="auth-factors-modal-body__info-value-actions--form-field">
            <span>{{"AUTH_FACTORS.EMAIL_CODE" | translate}}</span>
            <p>{{"AUTH_FACTORS.ACTIVATE_MS_EMAIL" | translate}}</p>
            <mat-form-field
              class="form-field"
              [class.disabled]="!geneQr"
              appearance="fill">
              <input formControlName="emailCode" type="text" pattern="[0-9]{6}" matInput maxlength="6" [disabled]="!geneQr" [readonly]="!geneQr">
              <mat-error *ngIf="showError('emailCode')">{{getErrorMessage('emailCode') | translate}}</mat-error>
              <button *ngIf="(secondFvForm.controls['emailCode'].invalid || !emailSent) && geneQr" class="form-field__btn"
                      mat-icon-button matSuffix (click)="sendEmailOtp()" type="button">
                {{ (emailSent ? 'WALLETS.PIN.SENT' : 'WALLETS.PIN.SEND') | translate }}
              </button>
              <img *ngIf="secondFvForm.controls['emailCode'].valid && emailSent" alt="..."
                   ngSrc="/assets/img/general/checked-icon.svg" disableOptimizedSrcset width="26" height="26" class="form-field__btn img" matSuffix>
            </mat-form-field>
          </div>
          <button mat-flat-button
                  color="accent"
                  type="submit">
            {{"AUTH_FACTORS.ACTIVATE" | translate}}
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
