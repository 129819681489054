<form class="user-search">
  <mat-form-field class="example-full-width earn__search-by-user-input" subscriptSizing="dynamic" appearance="fill">
    <mat-label>{{ 'EARNINGS.USER_INPUT.PLACEHOLDER' | translate }}</mat-label>
    <input matInput
           [(ngModel)]="userSearch"
           autocomplete="off"
           name="searchValue"
           placeholder="...">
  </mat-form-field>
  <button class="user-search__button btn" (click)="search()">
    <mat-icon class="btn__icon">search</mat-icon>
  </button>
</form>
