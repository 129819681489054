import {createFeatureSelector, createSelector} from '@ngrx/store';
import {HomeModulesState} from './menu-store.reducer';

export const HOME_MODULES_STATE = 'home-modules';
const homeModulesState = createFeatureSelector<HomeModulesState>(HOME_MODULES_STATE);

export const selectHomeModules = createSelector(homeModulesState, state => state.homeModules);
export const selectRoles = createSelector(homeModulesState, state => state.roles);
export const selectAlerts = createSelector(homeModulesState, state => state.alerts);
export const selectModulesError = createSelector(homeModulesState, state => state.modulesError);
