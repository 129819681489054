import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {

  @Output() public tabSelectedChange = new EventEmitter<string>();
  @Input() public tabNames: string[];
  @Input() public initializedTab: string;

  public selectedTab: string;

  constructor() {
  }

  ngOnInit(): void {
    if (this.initializedTab) {
      const tabIndex = this.tabNames.indexOf(this.initializedTab);
      this.tabChanged(this.tabNames[tabIndex]);
    } else {
      this.tabChanged(this.tabNames[0]);
    }
  }

  tabChanged(tabName: string) {
    this.selectedTab = tabName;
    this.tabSelectedChange.emit(tabName);
  }
}
