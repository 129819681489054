import {addClients, addClientsDetail, clearClients, clearErrorStore, clientsError} from './ClientsStoreActions';
import {Action, createReducer, on} from '@ngrx/store';
import {BaseListResponse} from '../../../../../../shared/domain/Response/BaseListResponse.entity';
import {BaseClient} from '../../../../../../shared/domain/Response/BaseClient.entity';

export interface ClientsState {
  clients: BaseListResponse<BaseClient>;
  clientsDetail: BaseClient;
  error: string;
}

const initialClientsState: ClientsState = {
  clients: null,
  clientsDetail: null,
  error: null
};

const reducer = createReducer(
  initialClientsState,
  on(addClients, (state, payload) =>
    ({
      ...state,
      clients: payload.clients,
      clientsDetail: state.clientsDetail,
      error: null
    })),
  on(addClientsDetail, (state, payload) =>
    ({
      ...state,
      clients: state.clients,
      clientsDetail: payload.clientsDetail,
      error: null
    })),
  on(clientsError, (state, payload) =>
    ({
      ...state,
      clients: null,
      error: payload.error
    })),
  on(clearErrorStore, (state) =>
    ({
      ...state,
      clients: state.clients,
      clientsDetail: state.clientsDetail,
      error: null
    })),
  on(clearClients, () => initialClientsState)
);

export function ClientsReducer(state: ClientsState, action: Action) {
  return reducer(state, action);
}
