import {Injectable} from '@angular/core';
import {HttpClientBase} from '../../../../../shared/Infrastructure/Http/HttpClientBase';
import {NotificationsListRepository} from '../../domain/notifications-list.repository';
import {environment} from '../../../../../environments/environment';
import {Observable, Subject, throwError} from 'rxjs';
import {NotificationsListResponse} from '../../domain/notifications-list.response';
import {HttpParams} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {NotificationCreationResponse} from '../../domain/notification-creation-response';
import {NotificationDeleteResponse} from '../../domain/notification-delete-response';

@Injectable({providedIn: 'root'})
export class NotificationsService extends HttpClientBase implements NotificationsListRepository {

  private refresh = new Subject<void>();

  serverApi = environment.urlApi;

  get refreshTable() {
    return this.refresh;
  }

  getNotificationsList(
    page: string,
    limit: string,
    companyId: string,
    searchParam?: string,
    searchValue?: string,
    startDate?: Date,
    finalDate?: Date,
    sortParam?: string,
    sortType?: string
  ): Observable<NotificationsListResponse> {
    const resource = 'alert/all';

    let params = new HttpParams()
      .set('page', page)
      .set('limit', limit)
      .set('companyId', companyId);

    if (searchParam && searchValue) {
      params = params.append('searchParam', searchParam)
        .append('searchValue', searchValue);
    }
    if (startDate) {
      params = params.append('startDate', this.dateUtils.dateToISOString(startDate));
    }
    if (finalDate) {
      params = params.append('finalDate', this.dateUtils.dateToISOString(finalDate));
    }
    if (sortParam && sortType) {
      params = params.append('sortParam', sortParam)
        .append('sortType', sortType);
    }

    return this.getWithParameters(`${this.serverApi}/${resource}`, params)
      .pipe(
        map(response => response.data),
        catchError(err => throwError(err))
      );
  }

  generateNotification(startDate: Date,
                       finishDate: Date,
                       description: string,
                       companyId: string,
                       priorityName: string): Observable<NotificationCreationResponse> {
    const resource = 'alert/create-one';
    const body = {
      startDate,
      finishDate,
      description,
      ...(companyId) && {companyId},
      priorityName
    };
    return this.post(`${this.serverApi}/${resource}`, body)
      .pipe(
        map(response => response),
        tap(() => {
          this.refreshTable.next();
        }),
        catchError(err => throwError(err)
        )
      );
  }

  deleteNotification(
    alertId: string,
    companyId: string
  ): Observable<NotificationDeleteResponse>{
    const resource = 'alert/id';
    const body = {
      alertId,
      companyId
    };

    return this.delete(`${this.serverApi}/${resource}`, body)
      .pipe(
        map(response => response),
        tap(() => this.refreshTable.next()),
        catchError(err => throwError(err))
      );
  }
}
