<ng-progress></ng-progress>
<div class="header">
  <div class="header-content">
    <div class="header-content__ham-button" (click)="toggleMenu()">
      <img ngSrc="../../../../assets/img/home-main/menu-button-logo.svg" disableOptimizedSrcset width="22" height="15" alt="menu-button">
    </div>
    <div [class]=" showContent ? 'header-content__logo' : 'header-content__logo2'">
      <img [class]=" showContent ? 'header-content__logo-img' : 'header-content__logo2-img'" ngSrc="/assets/img/home-main/logo.svg" priority width="425" height="104"  alt="logo">
    </div>
    <div class="header-content__nav" *ngIf="showContent">
      <mat-icon matSuffix>search</mat-icon>
      <input class="header-content__nav--search"
             list="company-names"
             type="text"
             autocomplete="off"
             [(ngModel)]="inputValue"
             (keypress)="characterLock($event)"
             (input)="searchFilter()"
             [placeholder]="'SEARCH_TABLE.SEARCH' | translate"
             (ngModelChange)="setSelectedCompany()"
             name="searchValue">
      <datalist class="header-content__nav--search-dataList" id="company-names">
        <option *ngFor="let lv of listValues" [label]="lv.companyId" [value]="lv.name">
      </datalist>
      <span class="header-content__nav--selected-company-title">{{'HEADER.SELECTED_COMPANY' | translate}}{{':'}}</span>
      <span class="header-content__nav--selected-company-value">{{selectedCompanyName}}</span>
      <mat-icon *ngIf="selectedCompanyName" (click)="clearSearch()">clear</mat-icon>
    </div>
    <div class="header-content__menu">
      <div class="header-content__menu--bell" *ngIf="!showContent">
        <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon [matBadge]="notification ? notification : ''" matBadgeColor="warn" class="material-symbols-outlined">notifications</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button style="width: 600px" mat-menu-item *ngFor="let alert of alerts" (click)="goToDashboardNotification()">
            <mat-icon
              [class.label-priority]="alert.priorityName === dashboardConstants.PRIORITY"
              [class.label-warning-notification]="alert.priorityName === dashboardConstants.WARNING"
              [class.label-satisfactory]="alert.priorityName === dashboardConstants.SATISFACTORY"
              [class.label-informative]="alert.priorityName === dashboardConstants.INFORMATIVE">notifications</mat-icon>
            <span>{{alert.description}}</span>
          </button>
          <button style="width: 600px" mat-menu-item (click)="goToDashboardNotification()">
            <mat-icon>notifications</mat-icon>
            <span>...</span>
          </button>
        </mat-menu>
      </div>
      <div class="header-content__menu--user" (click)="showMenuProfileOptions()">
        <span class="header-content__menu--user-icon material-icons">account_circle</span>
      </div>
    </div>
  </div>
  <div class="header-dropdown-menu" [class.dropdown-menu-show]="menuProfileOptionsShow">
    <div class="header-dropdown-menu-content">
      <img class="header-dropdown-menu-content__profile-image"
           ngSrc="../../../../assets/img/home-main/user-placeholder.png" width="400" height="400" disableOptimizedSrcset alt="">
      <div class="header-dropdown-menu-content__profile-info">
        <span class="header-dropdown-menu-content__profile-info-name">{{ name + " " + lastName }}</span>
        <span class="header-dropdown-menu-content__profile-info-email">{{ userSessionEmail }}</span>
        <button *ngIf="!showContent" (click)="goToEditProfile()">{{'PROFILE_MENU.VIEW_PROFILE'| translate}}</button>
      </div>
    </div>
    <span class="header-dropdown-menu__divider"></span>
    <a class="header-dropdown-menu__change-password-button" (click)="openChangePasswordModal()">
      <span class="header-dropdown-menu__change-password-button-icon material-icons">lock</span>
      {{'PROFILE_MENU.CHANGE_PASSWORD'| translate}}</a>
    <a class="header-dropdown-menu__logout-button" (click)="openLogoutModal()">
      <span class="header-dropdown-menu__logout-button-icon material-icons">power_settings_new</span>
      {{'PROFILE_MENU.LOGOUT'| translate}}</a>
  </div>
</div>
