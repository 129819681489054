import * as TraderInformationActions from './trader-information-store.actions';
import {Action, createReducer, on} from '@ngrx/store';
import {TraderInfo} from '../../domain/entities/trader-info.entity';
import {HttpErrorResponse} from '@angular/common/http';
import {OtcPlanEntity} from '../../domain/entities/otc-plan.entity';
import {CountryCodeEntity} from '../../domain/entities/country-code.entity';

export interface TraderInformationState {
  traderInformation: TraderInfo;
  faQrStatus: { status: boolean };
  traderError: string | HttpErrorResponse;
  otcPlan: Array<OtcPlanEntity>;
  countryCode: Array<CountryCodeEntity>;
}

const initialTraderInformationState: TraderInformationState = {
  traderInformation: null,
  otcPlan: null,
  faQrStatus: null,
  traderError: null,
  countryCode: null
};

const reducer = createReducer(initialTraderInformationState,
  on(TraderInformationActions.addTraderInformation, (state, payload) =>
    ({
      ...state,
      traderInformation: payload.traderInformation,
      otcPlan: state.otcPlan,
      countryCode: state.countryCode,
      faQrStatus: state.faQrStatus,
      traderError: state.traderError
    })),
  on(TraderInformationActions.addOtcPlan, (state, payload) =>
    ({
      ...state,
      traderInformation: state.traderInformation,
      otcPlan: payload.otcPlan,
      countryCode: state.countryCode,
      faQrStatus: state.faQrStatus,
      traderError: state.traderError
    })),
  on(TraderInformationActions.addCountryCode, (state, payload) =>
    ({
      ...state,
      traderInformation: state.traderInformation,
      otcPlan: state.otcPlan,
      countryCode: payload.countryCode,
      faQrStatus: state.faQrStatus,
      traderError: state.traderError
    })),
  on(TraderInformationActions.addSecond2FAStatus, (state, payload) =>
    ({
      ...state,
      traderInformation: state.traderInformation,
      otcPlan: state.otcPlan,
      countryCode: state.countryCode,
      faQrStatus: payload.faQrStatus,
      traderError: state.traderError
    })),
  on(TraderInformationActions.addTraderInformationError, (state, payload) =>
    ({
      ...state,
      traderInformation: state.traderInformation,
      otcPlan: state.otcPlan,
      countryCode: state.countryCode,
      faQrStatus: state.faQrStatus,
      traderError: payload.traderError
    })),
  on(TraderInformationActions.clearTraderInformationStore, () => initialTraderInformationState)
);

export function TraderInformationReducer(state: TraderInformationState, action: Action) {
  return reducer(state, action);
}
