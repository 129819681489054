<div class="collapsible-item-menu-container">
  <div class="collapsible-item-menu" [class.collapsible-item-menu-expanded]="expanded">
    <div class="collapsible-left-group" [routerLink]="route"
         (click)="expanded = true; verticalMenuState.setVerticalMenuState(false)">
      <span class="collapsible-item-icon material-icons">{{group.icon}}</span>
      <span class="collapsible-item-name">{{'MENU_MODULES.' + group.name | translate}}</span>
    </div>
    <div class="collapsible-right-symbol" (click)="expanded = !expanded"> +
    </div>
  </div>
</div>

<div class="collapsible-item-submenu-container"
     [class.collapsible-item-submenu-hidden]="!expanded"
     *ngFor="let module of modules">
  <app-collapsible-menu-item *ngIf="module"
                             [moduleIcon]="module.icon"
                             [moduleName]="module.name"
                             [modulePath]="module.path">
  </app-collapsible-menu-item>
</div>
