import {inject} from '@angular/core';
import {CanActivateChildFn, CanActivateFn, CanDeactivateFn, ResolveFn, Router} from '@angular/router';
import {StorageService} from '../../shared/Infrastructure/Services/storage.service';
import {RouterConstants} from '../../shared/Infrastructure/router.constants';
import {RolesConstants} from '../../shared/domain/constants/roles.constants';
import {RouterExtService} from '../../shared/domain/Utils/RouterUtils';
import {RolModules} from '../../shared/domain/Response/RolModules.entity';

export const USER_LOGGED_GUARD_DEACTIVATE: CanDeactivateFn<any> = () => {
  return inject(StorageService).getUserToken() ? true : inject(Router).createUrlTree([RouterConstants.LOGIN]);
};

export const USER_LOGGED_GUARD_CHILD: CanActivateChildFn = () => {
  return inject(StorageService).getUserToken() ? true : inject(Router).createUrlTree([RouterConstants.LOGIN]);
};

export const USER_ADMIN: CanActivateFn = (route, state) => {
  const role: RolModules = inject(StorageService).getDataRole();
  const prevUrl = inject(RouterExtService).getPreviousUrl();
  if (prevUrl?.startsWith(`/${RouterConstants.WITHDRAWAL_AUTHORIZATION}`)) {
    return inject(Router).createUrlTree([RouterConstants.WITHDRAWAL_AUTHORIZATION]);
  }
  if (role.name === RolesConstants.SUPER_ADMIN && (state.url.startsWith('/' + RouterConstants.ADMIN) || state.url.includes('login'))) {
    return true;
  }
  return role.name !== RolesConstants.SUPER_ADMIN && !state.url.startsWith(RouterConstants.ADMIN);
};
