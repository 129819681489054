import {ClassProvider} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from './auth.interceptor';
import {LoadingIndicatorInterceptor} from './loading-indicator.interceptor';
import {ErrorNotifierInterceptor} from './error-notifier.interceptor';


const interceptorProviderBase: ClassProvider = {
  provide: HTTP_INTERCEPTORS,
  multi: true,
  useClass: null
};

export const interceptors: Array<ClassProvider> = [
  {
    ...interceptorProviderBase,
    useClass: AuthInterceptor
  },
  {
    ...interceptorProviderBase,
    useClass: LoadingIndicatorInterceptor
  },
  {
    ...interceptorProviderBase,
    useClass: ErrorNotifierInterceptor
  }
];
