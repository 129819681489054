<div class="logout-modal">
  <div class="logout-modal-body">
    <img class="logout-modal-body__image" ngSrc="../../../../assets/img/home-main/user-placeholder.png" disableOptimizedSrcset width="400" height="400" alt="">
    <span class="logout-modal-body__user-name" *ngIf="userToLogout">{{ userToLogout }}</span>
    <hr/>
    <div class="logout-modal-body__message">{{'ALERT_MESSAGE.MESSAGE' | translate}}</div>
    <div class="logout-modal-body__footer">
      <button mat-flat-button color="accent" (click)="accept()" class="logout-modal-body__footer--button-accept">{{'CONFIRM_MODAL.ACCEPT' | translate}}</button>
      <button mat-stroked-button color="accent" (click)="cancel()" class="logout-modal-body__footer--button-cancel">{{'CONFIRM_MODAL.CANCEL' | translate}}</button>
    </div>
  </div>
</div>
