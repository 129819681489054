import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginUserService} from '../../../../app/auth/Infrastructure/Services/LoginUser.service';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {

  public userToChangePassword: string;
  formChangePassword: FormGroup;
  public newPasswordsAreEqual: boolean;
  public errorMessage;
  private passwordPattern: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!"#$%&'()*+,\-./:;<>?@[\]\\^_`{|}~¡´¿])\S{8,}$/;

  constructor(
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<ChangePasswordModalComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private formBuilder: FormBuilder,
    private loginUserService: LoginUserService
  ) {
    this.userToChangePassword = data;
  }

  ngOnInit(): void {
    this.formChangePassword = this.formBuilder.group({
      currentPassword: ['', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(this.passwordPattern)
      ])],
      newPassword: ['', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(this.passwordPattern)
      ])],
      newPasswordConfirmation: ['', Validators.compose([
        Validators.required,
        Validators.minLength(8),
      ])]
    });
    this.formChangePassword.valueChanges.subscribe(() => {
      this.isFull();
    });
  }

  getErrorMessage(field: string){
    return this.formChangePassword.controls[field]?.hasError('required') ? 'CHANGE_PASSWORD.PASSWORD_REQUIRED' :
      this.formChangePassword.controls[field]?.hasError('minlength') ? 'CHANGE_PASSWORD.PASSWORD_MIN_LENGTH' :
        this.formChangePassword.controls[field]?.hasError('pattern') ? 'CHANGE_PASSWORD.PASSWORD_DOES_NOT_MATCH_PATTERN_MESSAGE' :
          '';
  }

  async onSubmit() {
    const {newPassword, newPasswordConfirmation, currentPassword} = this.formChangePassword.value;
    this.newPasswordsAreEqual = newPassword === newPasswordConfirmation;
    if (this.formChangePassword.valid && this.newPasswordsAreEqual){
      await this.loginUserService.changePassword(currentPassword, newPassword)
        .subscribe(value => this.checkResult(value));
    } else if (this.formChangePassword.valid && !this.newPasswordsAreEqual){
      this.errorMessage = 'PASSWORDS_NOT_THE_SAME';
    } else {
      this.errorMessage = 'NOT_NULL_FIELDS';
    }
  }

  checkResult(value){
    if (value.success){
      this.accept();
    } else {
      this.errorMessage = value.message;
    }
  }

  isFull() {
    const {newPassword, newPasswordConfirmation, currentPassword} = this.formChangePassword.value;
    if (this.formChangePassword.valid) {
      this.errorMessage = null;
    } else if (newPassword && newPasswordConfirmation && currentPassword) {
      this.errorMessage = null;
    }
  }

  accept = () => this.dialogRef.close(true);
  cancel = () => this.dialogRef.close(false);

}
