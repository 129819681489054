<div class="app-home">
  <div class="app-header-container">
    <app-header></app-header>
  </div>
  <div class="home-main-container">
    <div class="app-mobile-vertical-menu-container">
      <app-mobile-vertical-menu></app-mobile-vertical-menu>
    </div>
    <div class="app-desktop-vertical-menu-container">
      <app-desktop-vertical-menu></app-desktop-vertical-menu>
    </div>
    <div class="home-container">
      <div class="home-content">
        <router-outlet>
        </router-outlet>
      </div>
      <p>&copy; Copyright 2024 | DecPay | All rights reserved</p>
    </div>
  </div>
</div>

