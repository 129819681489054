import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {addToken, addUserSessionInfo, clearLogin, loginFailure} from './LoginUserStoreActions';
import {selectError, selectToken, selectUserSessionInfo} from './LoginUserStoreSelectors';
import {LoginUserStore} from '../../../Domain/LoginUser.store';
import {BaseClient} from '../../../../../shared/domain/Response/BaseClient.entity';

@Injectable({providedIn: 'root'})
export class FacadeLoginUserStore implements LoginUserStore {

  constructor(private store: Store) {
  }

  token$ = (): Observable<string> => this.store.select(selectToken);
  error$ = (): Observable<string> => this.store.select(selectError);
  userSessionInfo$ = (): Observable<BaseClient> => this.store.select(selectUserSessionInfo);

  successfulLogin(token: string): void {
    this.store.dispatch(addToken({token}));
  }

  clearLogin() {
    this.store.dispatch(clearLogin());
  }

  errorLogin(error: string): void {
    this.store.dispatch(loginFailure({error}));
  }

  successfulUserSessionInfo(sessionInfo: BaseClient) {
    this.store.dispatch(addUserSessionInfo({sessionInfo}));
  }
}
