<div class="desktop-vertical-menu-container">
  <div class="desktop-vertical-menu-content">
    <app-collapsible-menu-item *ngFor="let element of menuElements"
                               [subModules]="element.submodules"
                               [moduleIcon]="element.icon"
                               [moduleName]="element.name"
                               [modulePath]="element.path"
                               [expanded]="!!element.submodules"
                               [isRoot]="element.isRoot">
    </app-collapsible-menu-item>
    <collapsible-menu-group *ngFor="let group of groupedModules" [group]="group">
    </collapsible-menu-group>
  </div>
</div>
