import {Observable} from 'rxjs';
import {io} from 'socket.io-client';
import {environment} from '../../../environments/environment';
import {HomeConstants} from '../domain/home.constants';

export class NotificationSocket {
  io: any;

  constructor(namespace: string, token: string) {
    this.io = io(`${environment.urlSocket}/${namespace}`, {
      autoConnect: true,
      extraHeaders: {
        authorization: `Bearer ${token}`
      }
    });
  }

  notifications(): Observable<any> {
    return new Observable((observer) => {
      this.io.on(HomeConstants.ALERTS_AMOUNT, (event: any) => {
        observer.next(event);
      });
    });
  }

  alert(): Observable<any> {
    return new Observable((observer) => {
      this.io.on(HomeConstants.ALERT_ONE, (event: any) => {
        observer.next(event);
      });
    });
  }


  userConnected(): Observable<any> {
    return new Observable((observer) => {
      this.io.on(HomeConstants.USER_CONNECTED, (event: any) => {
        observer.next(event);
      });
    });
  }

  errorEvent(): Observable<{}> {
    return new Observable((observer) => {
      this.io.on(HomeConstants.HANDLE_ERROR, (event: any) => {
        observer.next(event);
      });
    });
  }

}
