import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {RouterConstants} from '../shared/Infrastructure/router.constants';

export const broadCastChannel = new BroadcastChannel('authentication');
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {


  constructor(public translate: TranslateService,
              private router: Router) {
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es/) ? browserLang : 'en');

    broadCastChannel.onmessage = (event) => {
      if (event.data === 'logout') {
        localStorage.clear();
        this.router.navigate([RouterConstants.LOGIN]);
      }
    };
  }
}
