import {Observable, Subscription} from 'rxjs';
import {TraderInformationRepository} from '../domain/trader-information-repository';
import {TraderInformationStore} from '../domain/trader-information.store';
import {TraderInfo} from '../domain/entities/trader-info.entity';
import {HttpErrorResponse} from '@angular/common/http';
import {OtcPlanEntity} from "../domain/entities/otc-plan.entity";
import {CountryCodeEntity} from "../domain/entities/country-code.entity";

export class TraderInformationApplication {
  private subscriptionTraderInformation$: Subscription;

  constructor(private traderInformationRepository: TraderInformationRepository,
              private traderInformationStore: TraderInformationStore) {
  }

  destroySubscriptionTraderInformation(): void {
    if (this.subscriptionTraderInformation$) {
      this.subscriptionTraderInformation$.unsubscribe();
    }
  }

  get traderInformation$(): Observable<TraderInfo> {
    return this.traderInformationStore.traderInformation$();
  }

  get otcPlan$(): Observable<Array<OtcPlanEntity>> {
    return this.traderInformationStore.otcPlan$();
  }

  get countryCode$(): Observable<Array<CountryCodeEntity>> {
    return this.traderInformationStore.countryCode$();
  }
  get secondFAStatus$(): Observable<{ status: boolean }> {
    return this.traderInformationStore.secondFAStatus$();
  }

  get traderError$(): Observable<string | HttpErrorResponse> {
    return this.traderInformationStore.traderError$();
  }

  faQrStatus() {
    this.traderInformationRepository.faQrStatus()
      .subscribe(
        (response) => this.traderInformationStore.successSecondFAStatus(response),
        (error) => this.traderInformationStore.errorTraderInformation(error)
      );
  }

  getTraderInformation() {
    this.traderInformationRepository.getTraderInformation()
      .subscribe(
        (response) => this.traderInformationStore.successTraderInformation(response),
        (error) => this.traderInformationStore.errorTraderInformation(error)
      );
  }

  getOtcPlan() {
    this.traderInformationRepository.getOtcPlan()
      .subscribe(
        (response) => this.traderInformationStore.successOtcPlan(response),
        (error) => this.traderInformationStore.errorTraderInformation(error)
      );
  }

  getCountryAll() {
    this.traderInformationRepository.getCountryAll()
      .subscribe(
        (response) => this.traderInformationStore.successCountryCode(response),
        (error) => this.traderInformationStore.errorTraderInformation(error)
      );
  }
}
