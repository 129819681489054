import {Action, createReducer, on} from '@ngrx/store';
import {addCompanies, addReports, addReportsError, clearReports} from './reports-store.actions';
import {CompaniesResponse} from '../../domain/companies/companies.response';
import {BaseListResponse} from '../../../../../shared/domain/Response/BaseListResponse.entity';
import {Report} from '../../domain/reports-list/reports-list.response';

export interface ReportsState {
  reports: BaseListResponse<Report>;
  companies: Array<CompaniesResponse>;
  error: string;
}

const initialReportsState: ReportsState = {
  reports: null,
  companies: null,
  error: null
};

const reducer = createReducer(
  initialReportsState,
  on(addReports, (state, payload) =>
    ({
      ...state,
      reports: payload.reports,
      companies: state.companies,
      error: state.error
    })),
  on(addCompanies, (state, payload) =>
    ({
      ...state,
      reports: state.reports,
      companies: payload.companies,
      error: state.error
    })),
  on(addReportsError, (state, payload) =>
    ({
      ...state,
      reports: state.reports,
      companies: state.companies,
      error: payload.error
    })),
  on(clearReports, () => initialReportsState)
);

export function ReportsReducer(state: ReportsState, action: Action) {
  return reducer(state, action);
}
