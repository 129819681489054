import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'notification_substring'
})
export class NotificationSubstringPipe implements PipeTransform {

  transform(value: string): any {
    return value?.substr(0, 25) + '...';
  }

}
