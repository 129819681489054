import {StringValueObject} from '../../../../shared/domain/ValueObject/StringValueObject';
import {InvalidArgumentError} from '../../../../shared/domain/ValueObject/InvalidArgumentError';
import {EmailNotValidException} from '../../../../shared/domain/Exceptions/EmailNotValidException';

export class LoginUserEmail extends StringValueObject {

  constructor(value: string) {
    super(value);
    this.ensureTextIsNotEmpty(value);
    this.ensureEmailIsValid(value);
  }

  private ensureTextIsNotEmpty(value: string): void {
    if (!value) {
      throw new InvalidArgumentError('EMAIL_IS_REQUIRED');
    }
  }

  private ensureEmailIsValid(value: string) {
    const regexpEmail = new RegExp(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/);
    if (!regexpEmail.test(value)) {
      throw new EmailNotValidException('EMAIL_NOT_VALID');
    }
  }
}
