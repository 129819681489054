import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {RouterConstants} from '../../../Infrastructure/router.constants';

@Component({
  selector: 'app-login-redirection',
  templateUrl: './login-redirection.component.html',
  styleUrls: ['./login-redirection.component.scss']
})
export class LoginRedirectionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LoginRedirectionComponent>,
    private router: Router
  ) {
  }

  ngOnInit(): void {
  }

  async goLogin() {
    await this.router.navigate([RouterConstants.LOGIN]);
    this.dialogRef.close();
  }
}
