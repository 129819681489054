import {formatISO} from 'date-fns';
import {DatePipe} from '@angular/common';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class DateUtils {

  public datePipeFormat = 'dd/MM/yy, hh:mm a';
  public datePipeFormatDays = 'dd/MM/yy';
  public standardDateFormat = 'y-MM-ddTHH:mm:ss.SSSZZZZZ';
  public dateStr: string;


  constructor(private datePipe: DatePipe) {
  }

  static getCurrentDate() {
    const startDate = new Date();
    startDate.setDate(1);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0, 23, 59, 59, 999);
    return {
      startDate, endDate
    };
  }

  static toDatetimeLocal(datetimeString: string, endDate: boolean = false): string {
    let dateTimeLocale = null;
    try {
      const now = new Date(datetimeString);
      let dt = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
      if (endDate) {
        dt = this.setEndHours(dt);
      }
      dateTimeLocale = formatISO(dt);
    } catch (e) {
      console.warn('date not valid');
    }
    return dateTimeLocale;
  }

  static getCurrentDay() {
    return new Date().toISOString();
  }

  static setStartHours(dt: Date): Date {
    dt.setHours(0);
    dt.setMinutes(0);
    dt.setSeconds(0);
    dt.setMilliseconds(0);
    return dt;
  }

  static setEndHours(dt: Date): Date {
    dt.setHours(23);
    dt.setMinutes(59);
    dt.setSeconds(59);
    dt.setMilliseconds(999);
    return dt;
  }

  static getTimeForService(dateStr: string) {
    return dateStr.replace('T00:00:00.000Z', 'T23:59:59.000Z');
  }

  /**
   * Format a ISO 8601
   * USED TO SEND DATES TO SERVICES
   */
  dateToISOString(date: Date): string {
    return new Date(date).toISOString();
  }

  dateToString(date: Date): string {
    return this.datePipe.transform(date, this.standardDateFormat);
  }

  toMediumDate(date: Date | string): string {
    return this.datePipe.transform(date, 'mediumDate');
  }

  toShortDate(date: string | Date): string {
    return this.datePipe.transform(date, 'shortDate');
  }
}
