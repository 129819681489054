<div class="notification-modal">
  <div class="notification-modal__header_title">
    <span>{{'NOTIFICATION.USER_NOTIFICATION' | translate}}</span>
  </div>
  <form [formGroup]="notificationForm" (ngSubmit)="createNotification($event, notificationForm)">
    <div class="notification-modal__body_header">
      <div class="notification-modal__body_header-client">
        <mat-label>{{('NOTIFICATION.CLIENT' | translate)}}
          <span class="notification-modal__body_header-client-name_required">*</span>
        </mat-label>
        <mat-form-field appearance="fill">
          <mat-label>{{'NOTIFICATION.ENTER_CLIENT' | translate}}</mat-label>
          <input formControlName="companyId"
                 matInput
                 id="client">
        </mat-form-field>
      </div>

      <div class="notification-modal__body_header-priority">
        <mat-label>{{('NOTIFICATION.PRIORITY' | translate)}}
          <span class="notification-modal__body_header-priority-name_required">*</span>
        </mat-label>

        <mat-form-field appearance="fill">
          <mat-label>{{'NOTIFICATION.ENTER_PRIORITY' | translate}}</mat-label>
          <mat-select formControlName="priorityName" id="priority-name">
            <mat-option style="color: red" value="PRIORITY">{{'NOTIFICATION.PRIORITIZED' | translate}}</mat-option>
            <mat-option style="color: orangered" value="WARNING">{{'NOTIFICATION.WARNING' | translate}}</mat-option>
            <mat-option style="color: green"
                        value="SATISFACTORY">{{'NOTIFICATION.SATISFACTORY' | translate}}</mat-option>
            <mat-option style="color: blue" value="INFORMATIVE">{{'NOTIFICATION.INFORMATIVE' | translate}}</mat-option>
          </mat-select>
          <mat-error *ngIf="priorityInvalid()">{{'FORM_ERRORS.FIELD_REQUIRED' | translate}}</mat-error>
        </mat-form-field>
      </div>

      <div class="notification-modal__body_header-data_picker">
        <mat-label>{{'NOTIFICATION.MESSAGE_DATE' | translate}}
          <span class="notification-modal__body_header-data_picker-name_required">*</span>
        </mat-label>
        <div class="notification-modal__body_header-data_picker--content">
          <app-date-picker [(startDate)]="startDate"
                           [(endDate)]="endDate"
                           [noBtn]="true"
                           id="start-date">
          </app-date-picker>
        </div>
      </div>
    </div>

    <mat-form-field class="notification-modal_body">
      <mat-label>{{'NOTIFICATION.MESSAGE' | translate}}
      </mat-label>
      <textarea formControlName="description"
                type="text"
                minlength="4"
                maxlength="500"
                matInput
                cdkTextareaAutosize
                #textArea
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                id="description-name">
      </textarea>
      <mat-error *ngIf="descriptionInvalid()">{{'FORM_ERRORS.FIELD_REQUIRED' | translate}}</mat-error>
      <mat-hint align="end">{{textArea.value.length}}</mat-hint>
    </mat-form-field>


    <div class="notification-modal__footer">
      <button mat-stroked-button color="primary"
              (click)="cancel()"
              class="notification-modal__footer--button-cancel">
        {{'CONFIRM_MODAL.CANCEL' | translate}}</button>
      <button mat-flat-button color="primary"
              type="submit"
              class="notification-modal__footer--button-accept">
        {{'CONFIRM_MODAL.SEND' | translate}}</button>
    </div>
  </form>
</div>
