import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SnackBarService} from '../../../Infrastructure/Services/snack-bar.service';
import {CoinConstants} from '../../../domain/constants/coin.constants';

@Component({
  selector: 'app-select-coin-modal',
  templateUrl: './select-coin-modal.component.html',
  styleUrls: ['./select-coin-modal.component.scss']
})
export class SelectCoinModalComponent implements OnInit {


  constructor(private snackBarService: SnackBarService,
              private formBuilder: FormBuilder,
              private translateService: TranslateService,
              public dialogRef: MatDialogRef<SelectCoinModalComponent>,
              @Inject(MAT_DIALOG_DATA) data) {

  }

  public selectCoinForm: FormGroup;

  public disabledButton: boolean;

  coins: Title[] = [
    {
      name: CoinConstants.BTC.symbol,
      img: 'assets/img/dashboard/btc.svg',
      isSelected: false,
      coinSymbol: null,
      network: null
    },
    {
      name: CoinConstants.ETH.symbol,
      img: 'assets/img/dashboard/eth.svg',
      isSelected: false,
      coinSymbol: null,
      network: null
    },
    {
      name: CoinConstants.USDTE.symbol,
      img: 'assets/img/dashboard/tether.svg',
      isSelected: false,
      coinSymbol: null,
      network: null
    },
    {
      name: CoinConstants.USDTT.symbol,
      img: 'assets/img/dashboard/tether.svg',
      isSelected: false,
      coinSymbol: null,
      network: null
    },
    {
      name: CoinConstants.BNB.symbol,
      img: 'assets/img/dashboard/bnb.svg',
      isSelected: false,
      coinSymbol: null,
      network: null
    }
  ];

  titleSelected;

  x;

  ngOnInit(): void {
    this.buildForm();
    this.disabledButton = true;
  }

  // tslint:disable-next-line:no-shadowed-variable
  select(title) {
    this.coins.map(t => t.isSelected = false);
    title.isSelected = true;
    this.titleSelected = title;
    this.disabledButton = false;
  }

  accept = () => this.dialogRef.close(this.titleSelected);
  cancel = () => this.dialogRef.close(null);

  usdValue = () => this.selectCoinForm.get('usdValue');
  coinSymbol = () => this.selectCoinForm.get('coinSymbol');

  isCoinSymbolInvalid = () => !this.coins.find(value => value.isSelected);

  buildForm() {
    this.selectCoinForm = this.formBuilder.group({});
  }

  submitForm(event: Event) {

    event.preventDefault();
    if (this.selectCoinForm.valid) {
      this.disabledButton = true;
      this.selectCoinForm.disable();
      switch (this.titleSelected.name) {
        case CoinConstants.BTC.symbol:
          this.titleSelected.network = CoinConstants.BTC.symbol;
          this.titleSelected.coinSymbol = CoinConstants.BTC.symbol;
          break;
        case CoinConstants.ETH.symbol:
          this.titleSelected.network = CoinConstants.ETH.symbol;
          this.titleSelected.coinSymbol = CoinConstants.ETH.symbol;
          break;
        case CoinConstants.USDTE.symbol:
          this.titleSelected.network = CoinConstants.ETH.symbol;
          this.titleSelected.coinSymbol = CoinConstants.USDT.symbol;
          break;
        case CoinConstants.USDTT.symbol:
          this.titleSelected.network = CoinConstants.USDT.networkSymbol;
          this.titleSelected.coinSymbol = CoinConstants.USDT.symbol;
          break;
        case CoinConstants.BNB.symbol:
          this.titleSelected.network = CoinConstants.BNB.networkSymbol;
          this.titleSelected.coinSymbol = CoinConstants.BNB.symbol;
          break;
      }
      this.accept();
    } else {
      this.selectCoinForm.markAllAsTouched();
    }
  }

}

export interface Title {
  img: string;
  name: string;
  isSelected?: boolean;
  network: string;
  coinSymbol: string;
}
