import {Action, createReducer, on} from '@ngrx/store';
import {addRecharge, addRechargeDetail, addRechargeError, clearRecharge} from './recharge-request-store.actions';
import {BaseListResponse} from '../../../../../shared/domain/Response/BaseListResponse.entity';
import {RechargeRequest} from '../../domain/entities/RechargeRequest.entity';

export interface RechargeState {
  recharge: BaseListResponse<RechargeRequest>;
  rechargeDetail: RechargeRequest;
  error: string;
}

const initialRechargeState: RechargeState = {
  recharge: null,
  rechargeDetail: null,
  error: null
};

const reducer = createReducer(
  initialRechargeState,
  on(addRecharge, (state, payload) =>
    ({
      ...state,
      recharge: payload.recharge,
      rechargeDetail: state.rechargeDetail,
      error: state.error
    })),
  on(addRechargeDetail, (state, payload) =>
    ({
      ...state,
      recharge: state.recharge,
      rechargeDetail: payload.rechargeDetail,
      error: state.error
    })),
  on(addRechargeError, (state, payload) =>
    ({
      ...state,
      recharge: state.recharge,
      rechargeDetail: state.rechargeDetail,
      error: payload.error
    })),
  on(clearRecharge, () => initialRechargeState)
);

export function RechargeReducer(state: RechargeState, action: Action) {
  return reducer(state, action);
}
