<div class="change-password-modal">
  <div class="change-password-modal-header">
    <div class="change-password-modal-header__img-container">
      <img ngSrc="../../../../assets/img/home-main/change-password-logo.svg" disableOptimizedSrcset width="76" height="102" alt="" class="change-password-modal-header__image" />
      <span class="change-password-modal-header__title" >{{"CHANGE_PASSWORD.TITLE" | translate}}</span>
    </div>
    <div>
      <span class="change-password-modal-header__close" (click)="cancel()">
        <mat-icon>close</mat-icon>
      </span>
    </div>
  </div>
  <div class="change-password-modal-body">
    <form [formGroup]="formChangePassword" (submit)="onSubmit()">
      <div class="change-password-modal-body__item">
        <mat-form-field appearance="outline" class="change-password-modal-body__field">
          <mat-label>{{"CHANGE_PASSWORD.ACTUAL_PASSWORD" | translate}}</mat-label>
          <input
            type="password"
            formControlName="currentPassword"
            matInput>
          <mat-error *ngIf="formChangePassword.controls['currentPassword'].invalid">{{getErrorMessage("currentPassword") | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div class="change-password-modal-body__item">
        <mat-form-field appearance="outline" class="change-password-modal-body__field">
          <mat-label>{{"CHANGE_PASSWORD.NEW_PASSWORD" | translate}}</mat-label>
          <input
            type="password"
            formControlName="newPassword"
            matInput>
          <mat-error *ngIf="formChangePassword.controls['newPassword'].invalid">{{ getErrorMessage("newPassword") | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div class="change-password-modal-body__item">
        <mat-form-field appearance="outline" class="change-password-modal-body__field">
          <mat-label>{{"CHANGE_PASSWORD.REPEAT_NEW_PASSWORD" | translate}}</mat-label>
          <input
            type="password"
            formControlName="newPasswordConfirmation"
            matInput>
          <mat-error *ngIf="formChangePassword.controls['newPasswordConfirmation'].invalid">{{getErrorMessage("newPasswordConfirmation") | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div class="change-password-modal-footer">
        <button
          mat-flat-button
          color="primary"
          class="change-password-modal-footer__button"
          type="submit">
          <span>{{"CHANGE_PASSWORD.TITLE" | translate}}</span>
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="errorMessage" class="label-error-content">
    <label class="change-password-modal-label-error">{{'CHANGE_PASSWORD.' + errorMessage | translate}}</label>
  </div>
</div>
