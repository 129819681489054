import {ActionReducerMap} from '@ngrx/store';

import {
  LoginUserReducer,
  LoginUserState
} from '../../../app/auth/Infrastructure/Store/LoginUser/LoginUserStoreReducer';
import {HomeModulesReducer, HomeModulesState} from '../../../app/home/infrastructure/vertical-menu/menu-store.reducer';
import {AUTH_STATE} from '../../../app/auth/Infrastructure/Store/LoginUser/LoginUserStoreSelectors';
import {HOME_MODULES_STATE} from '../../../app/home/infrastructure/vertical-menu/menu-store.selector';
import {CLIENTS_STATE} from '../../../app/admin/clients/Infrastructure/Store/clients/ClientsStoreSelectors';
import {ClientsReducer, ClientsState} from '../../../app/admin/clients/Infrastructure/Store/clients/ClientsStoreReducer';
import {NOTIFICATIONS_STATE} from '../../../app/admin/notifications/infrastructure/store/notifications-store.selector';
import {NotificationsState, NotificationsReducer} from '../../../app/admin/notifications/infrastructure/store/notifications-store.reducer';
import {
  TraderInformationReducer, TraderInformationState
} from '../../../app/trader-information/infrastructure/store/trader-information-store.reducer';
import {
  TRADER_INFORMATION_STATE
} from '../../../app/trader-information/infrastructure/store/trader-information-store.selector';
import {EARNS_STATE} from '../../../app/admin/earn/infrastructure/store/earn-store.selector';
import {EarningsReducer, EarningsState} from '../../../app/admin/earn/infrastructure/store/earn-store.reducer';
import {REPORTS_STATE} from '../../../app/admin/reports/infrastructure/store/reports-store.selector';
import {ReportsReducer, ReportsState} from '../../../app/admin/reports/infrastructure/store/reports-store.reducer';
import {FutcardReducer, FutcardState} from '../../../app/admin/futcard/infrastructure/store/futcard-store.reducer';
import {FUTCARD_STATE} from '../../../app/admin/futcard/infrastructure/store/futcard-store.selector';
import {SWAP_STATE} from '../../../app/admin/swap/infrastructure/store/swap-store.selector';
import {SwapReducer, SwapState} from '../../../app/admin/swap/infrastructure/store/swap-store.reducer';
import {RECHARGE_REQUEST_STATE} from '../../../app/admin/recharge-request/infrastructure/store/recharge-request-store.selector';
import {RechargeReducer, RechargeState} from '../../../app/admin/recharge-request/infrastructure/store/recharge-request-store.reducer';
import {CARD_REQUEST_STATE} from '../../../app/admin/card-request/infrastructure/store/card-request-store.selector';
import {CardReducer, CardState} from '../../../app/admin/card-request/infrastructure/store/card-request-store.reducer';
import {SWAP_REPORTS_STATE} from '../../../app/admin/swap-reports/infrastructure/store/swap-reports-store.selector';
import {SwapReportsReducer, SwapReportsState} from '../../../app/admin/swap-reports/infrastructure/store/swap-reports-store.reducer';
import {UsersReducer, UsersState} from '../../../app/admin/users/Infrastructure/users/users-store-reducer';
import {USERS_STATE} from '../../../app/admin/users/Infrastructure/users/users-store-selector';
import {ROLES_STATE} from '../../../app/admin/roles/Infrastructure/roles/roles-store-selector';
import {RolesReducer, RolesState} from '../../../app/admin/roles/Infrastructure/roles/roles-store-reducer';

export interface AppState {
  [AUTH_STATE]: LoginUserState;
  [HOME_MODULES_STATE]: HomeModulesState;
  [CLIENTS_STATE]: ClientsState;
  [NOTIFICATIONS_STATE]: NotificationsState;
  [TRADER_INFORMATION_STATE]: TraderInformationState;
  [EARNS_STATE]: EarningsState;
  [REPORTS_STATE]: ReportsState;
  [FUTCARD_STATE]: FutcardState;
  [SWAP_STATE]: SwapState;
  [RECHARGE_REQUEST_STATE]: RechargeState;
  [CARD_REQUEST_STATE]: CardState;
  [SWAP_REPORTS_STATE]: SwapReportsState;
  [USERS_STATE]: UsersState;
  [ROLES_STATE]: RolesState;
}

export const appReducers: ActionReducerMap<AppState> = {
  [AUTH_STATE]: LoginUserReducer,
  [HOME_MODULES_STATE]: HomeModulesReducer,
  [CLIENTS_STATE]: ClientsReducer,
  [NOTIFICATIONS_STATE]: NotificationsReducer,
  [TRADER_INFORMATION_STATE]: TraderInformationReducer,
  [EARNS_STATE]: EarningsReducer,
  [REPORTS_STATE]: ReportsReducer,
  [FUTCARD_STATE]: FutcardReducer,
  [SWAP_STATE]: SwapReducer,
  [RECHARGE_REQUEST_STATE]: RechargeReducer,
  [CARD_REQUEST_STATE]: CardReducer,
  [SWAP_REPORTS_STATE]: SwapReportsReducer,
  [USERS_STATE]: UsersReducer,
  [ROLES_STATE]: RolesReducer
};
