import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {delay, delayWhen, interval, Subscription} from 'rxjs';
import {BusyNotifierService} from '../Infrastructure/Services/busy-notifier.service';

@Directive({
  selector: '[busy]'
})
export class BusyIndicatorDirective implements OnInit {

  @Input() showDelay = 50;
  @Input() hideDelay = 50;
  // @Input() hideDelay = 1000;
  @Input() reverse = false;
  hideTimer: Subscription;
  showTimer: Subscription;

  constructor(private el: ElementRef, private renderer: Renderer2, private busyNotifier: BusyNotifierService) {
  }

  cancelPendingHide() {
    if (this.hideTimer) {
      this.hideTimer.unsubscribe();
      delete this.hideTimer;
    }
  }

  cancelPendingShow() {
    if (this.showTimer) {
      this.showTimer.unsubscribe();
      delete this.showTimer;
    }
  }

  ngOnInit() {
    this.busyNotifier.busy$
      .subscribe(busy => {
        if (busy) {
          this.cancelPendingHide();
          if (!this.showTimer) {
            this.showTimer =
              interval(this.showDelay).subscribe(() => {
                if (this.reverse) {
                  this.renderer.addClass(this.el.nativeElement, 'inactive');
                } else {
                  this.renderer.removeClass(this.el.nativeElement, 'inactive--spinner');
                }
                this.showTimer.unsubscribe();
                this.showTimer = null;
              });
          }
        } else {
          this.cancelPendingShow();

          if (!this.hideTimer) {
            this.hideTimer =
              interval(this.hideDelay).subscribe(() => {
                if (this.reverse) {
                  this.renderer.removeClass(this.el.nativeElement, 'inactive');
                } else {
                  this.renderer.addClass(this.el.nativeElement, 'inactive--spinner');
                }
                this.hideTimer.unsubscribe();
                this.hideTimer = null;
              });
          }
        }
      });
  }
}
