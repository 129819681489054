import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TraderInformationServices} from '../../infrastructure/services/trader-information.services';
import {FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {SnackBarService} from '../../../../shared/Infrastructure/Services/snack-bar.service';

@Component({
  selector: 'app-delete-factors-modal',
  templateUrl: './delete-factors-modal.component.html',
  styleUrls: ['./delete-factors-modal.component.scss']
})
export class DeleteFactorsModalComponent implements OnInit {

  public deleteFvForm: FormGroup;

  public emailSent: boolean;

  constructor(private translateService: TranslateService,
              private formBuilder: FormBuilder,
              private tradeInformationService: TraderInformationServices,
              public dialogRef: MatDialogRef<DeleteFactorsModalComponent>,
              private matDialog: MatDialog,
              private snackBarService: SnackBarService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    const validators: ValidatorFn[] = [Validators.required, Validators.pattern(/[0-9]{6}/)];
    this.deleteFvForm = this.formBuilder.group(
      {
        appCode: new FormControl<string>(null, Validators.compose(validators)),
        emailCode: new FormControl<string>(null, Validators.compose(validators))
      }
    );
  }

  showError = (field: string): boolean => this.deleteFvForm.controls[field].invalid && this.deleteFvForm.controls[field].dirty;
  getErrorMessage(field: string) {
    return this.deleteFvForm.controls[field]?.hasError('required') ? 'FORM_ERRORS.FIELD_REQUIRED' :
      this.deleteFvForm.controls[field]?.hasError('pattern') ? 'FORM_ERRORS.INVALID_FORMAT' :
        '';
  }

  sendEmailOtp() {
    const langIsoCode = 'en';
    this.tradeInformationService.send2faOtp(langIsoCode)
      .subscribe((res) => {
        if (res) {
          this.emailSent = true;
          this.snackBarService.openSuccessToast(this.translateService.instant('WALLETS.PIN.SEND_EMAIL'));
        }
      }, () => {
        this.snackBarService.openErrorToast(this.translateService.instant('WALLETS.PIN.SEND_EMAIL_ERROR'));
      });
  }

  delete2FA(event: Event) {
    event.preventDefault();
    const {appCode, emailCode} = this.deleteFvForm.value;
    if (this.deleteFvForm.valid) {
      this.deleteFvForm.disable();
      this.tradeInformationService.faDeleteQr(String(appCode), String(emailCode))
        .subscribe((res) => {
            if (res) {
              this.accept();
              this.snackBarService.openSuccessToast('AUTH_FACTORS.SUCCESS_DELETE');
              this.dialogRef.close(true);
            } else {
              this.snackBarService.openErrorToast('AUTH_FACTORS.ERROR_DELETE');
              this.emailSent = false;
              this.deleteFvForm.enable();
            }
          },
          () => {
            this.snackBarService.openErrorToast('AUTH_FACTORS.ERROR_DELETE');
            this.emailSent = false;
            this.deleteFvForm.enable();
          }
        );
    } else {
      switch (true) {
        case !emailCode:
          this.snackBarService.openErrorToast(this.translateService.instant('AUTH_FACTORS.OTP_CODE_REQUIRED'));
          break;
        case !appCode:
          this.snackBarService.openErrorToast(this.translateService.instant('AUTH_FACTORS.TWOFA_CODE_REQUIRED'));
          break;
        case String(emailCode).length !== 6:
          this.snackBarService.openErrorToast(this.translateService.instant('AUTH_FACTORS.INVALID_OTP_LENGTH'));
          break;
        case String(appCode).length !== 6:
          this.snackBarService.openErrorToast(this.translateService.instant('AUTH_FACTORS.INVALID_TWOFA_CODE_LENGTH'));
          break;
      }
      this.deleteFvForm.markAllAsTouched();
    }
  }


  accept = () => this.dialogRef.close(true);
  cancel = () => this.dialogRef.close(false);

}
