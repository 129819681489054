<div class="confirm-modal">
  <div class="confirm-modal-body">
    <mat-icon class="confirm-modal-body__image"
      aria-label="Button that displays a tooltip when focused or hovered over">
      info
    </mat-icon >
    <div class="confirm-modal-body__message">{{description}}</div>
    <div class="confirm-modal-body__footer">
      <button mat-flat-button color="accent" (click)="accept()" class="confirm-modal-body__footer--button-accept">{{'CONFIRM_MODAL.ACCEPT' | translate}}</button>
      <button mat-stroked-button color="accent" (click)="cancel()" class="confirm-modal-body__footer--button-cancel">{{'CONFIRM_MODAL.CANCEL' | translate}}</button>
    </div>
  </div>
</div>
