import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BillServices} from '../../../bill/infraestructure/services/bill-services.service';
import {SnackBarService} from '../../../../shared/Infrastructure/Services/snack-bar.service';
import {CoinConstants} from '../../../../shared/domain/constants/coin.constants';
import {StorageService} from '../../../../shared/Infrastructure/Services/storage.service';

@Component({
  selector: 'app-generate-bill-modal',
  templateUrl: './generate-bill-modal.component.html',
  styleUrls: ['./generate-bill-modal.component.scss']
})
export class GenerateBillModalComponent implements OnInit {

  public billForm: FormGroup;
  public network: string;
  public coinSymbols: string;

  public coinTouched: boolean;

  bills: title[] = [
    {name: CoinConstants.BTC.symbol, img: 'assets/img/dashboard/btc.svg'},
    {name: CoinConstants.ETH.symbol, img: 'assets/img/dashboard/eth.svg'},
    {name: CoinConstants.USDTE.symbol, img: 'assets/img/dashboard/tether.svg'},
    {name: CoinConstants.USDTT.symbol, img: 'assets/img/dashboard/tether.svg'},
    {name: CoinConstants.BNB.symbol, img: 'assets/img/dashboard/bnb.svg'}
  ];
  titleSelected;


  constructor(private snackBarService: SnackBarService,
              private billServices: BillServices,
              private formBuilder: FormBuilder,
              private translateService: TranslateService,
              private storageService: StorageService,
              public dialogRef: MatDialogRef<GenerateBillModalComponent>,
              @Inject(MAT_DIALOG_DATA) data) {

  }

  ngOnInit(): void {
    this.buildGenerateBillForm();
  }

  // tslint:disable-next-line:no-shadowed-variable
  select(title) {
    this.bills.map(t => t.isSelected = false);
    title.isSelected = true;
    this.titleSelected = title;
    this.coinTouched = true;
  }

  accept = () => this.dialogRef.close(true);
  cancel = () => this.dialogRef.close(false);

  usdValue = () => this.billForm.get('usdValue');
  coinSymbol = () => this.billForm.get('coinSymbol');

  isUsdValueInvalid = () => this.billForm.get('usdValue').invalid && this.billForm.get('usdValue').touched;

  buildGenerateBillForm() {
    this.billForm = this.formBuilder.group(
      {
        usdValue: ['', [Validators.required, Validators.pattern(/[0-9]/)]]
      }
    );
  }

  createBill(event: Event) {
    const coinName = this.bills.filter((r => r.isSelected === true));
    event.preventDefault();
    if (this.billForm.valid) {
      const {usdValue} = this.billForm.value;
      this.billForm.disable();
      switch (coinName[0].name) {
        case CoinConstants.USDTE.symbol:
          this.network = CoinConstants.USDTE.networkSymbol;
          this.coinSymbols = CoinConstants.USDT.symbol;
          break;
        case CoinConstants.USDTT.symbol:
          this.network = CoinConstants.USDT.networkSymbol;
          this.coinSymbols = CoinConstants.USDT.symbol;
          break;
        default:
          this.network = CoinConstants[coinName[0].name]?.networkSymbol;
          this.coinSymbols = CoinConstants[coinName[0].name]?.symbol;
      }
      this.billServices.generateBill(+usdValue, this.coinSymbols, this.network)
        .subscribe({
          next: (bill) => {
            if (bill) {
              this.accept();
              this.snackBarService.openSuccessToast('BILL.GENERATE_BILL.BILL_SUCCESS');
              setTimeout(() => window.open(`${bill.paymentUrl}&ui=gui1001&id=${bill.billId}`, '_blank'), 2000);
            }
          },
          error: () => this.snackBarService.openErrorToast('BILL.GENERATE_BILL.BILL_ERROR')
        });
    } else {
      this.billForm.markAllAsTouched();
    }
  }

  get btnDisabled(): boolean {
    return this.billForm.invalid || !this.titleSelected;
  }

  get isCoinSymbolInvalid() {
    return this.coinTouched && !this.bills.find(value => value.isSelected);
  }
}

// tslint:disable-next-line:class-name
export class title {
  img: string;
  name: string;
  isSelected?: boolean;
}
