import {createFeatureSelector, createSelector} from '@ngrx/store';
import {TraderInformationState} from './trader-information-store.reducer';

export const TRADER_INFORMATION_STATE = 'trader-information';
const traderInformationState = createFeatureSelector<TraderInformationState>(TRADER_INFORMATION_STATE);

export const selectTraderInformation = createSelector(traderInformationState, state => state.traderInformation);
export const selectOtcPlan = createSelector(traderInformationState, state => state.otcPlan);
export const selectCountryCode = createSelector(traderInformationState, state => state.countryCode);
export const selectSecond2FAStatus = createSelector(traderInformationState, state => state.faQrStatus);
export const selectTraderError = createSelector(traderInformationState, state => state.traderError);
