import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {
  selectCountryCode,
  selectOtcPlan,
  selectSecond2FAStatus,
  selectTraderError,
  selectTraderInformation
} from './trader-information-store.selector';
import {TraderInformationStore} from '../../domain/trader-information.store';
import {
  addCountryCode,
  addOtcPlan,
  addSecond2FAStatus,
  addTraderInformation,
  addTraderInformationError,
  clearTraderInformationStore
} from './trader-information-store.actions';
import {TraderInfo} from '../../domain/entities/trader-info.entity';
import {HttpErrorResponse} from '@angular/common/http';
import {OtcPlanEntity} from '../../domain/entities/otc-plan.entity';
import {CountryCodeEntity} from '../../domain/entities/country-code.entity';

@Injectable({providedIn: 'root'})
export class FacadeTraderInformationStore implements TraderInformationStore {

  constructor(private store: Store) {
  }

  traderInformation$ = (): Observable<TraderInfo> => this.store.select(selectTraderInformation);

  otcPlan$ = (): Observable<Array<OtcPlanEntity>> => this.store.select(selectOtcPlan);
  countryCode$ = (): Observable<Array<CountryCodeEntity>> => this.store.select(selectCountryCode);
  secondFAStatus$ = (): Observable<{ status: boolean }> => this.store.select(selectSecond2FAStatus);
  traderError$ = (): Observable<string | HttpErrorResponse> => this.store.select(selectTraderError);

  successTraderInformation(traderInformation: TraderInfo): void {
    this.store.dispatch(addTraderInformation({traderInformation}));
  }

  successOtcPlan(otcPlan: Array<OtcPlanEntity>): void {
    this.store.dispatch(addOtcPlan({otcPlan}));
  }

  successCountryCode(countryCode: Array<CountryCodeEntity>): void {
    this.store.dispatch(addCountryCode({countryCode}));
  }

  successSecondFAStatus(faQrStatus: { status: boolean }): void {
    this.store.dispatch(addSecond2FAStatus({faQrStatus}));
  }

  errorTraderInformation(traderError: string | HttpErrorResponse): void {
    this.store.dispatch(addTraderInformationError({traderError}));
  }

  clearTraderInformationStore(): void {
    this.store.dispatch(clearTraderInformationStore());
  }


}
