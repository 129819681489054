import {Action, createReducer, on} from '@ngrx/store';
import {addEarningDetail, addEarningError, addEarnings, clearEarnings} from './earn-store.actions';
import {BaseListResponse} from '../../../../../shared/domain/Response/BaseListResponse.entity';
import {Earn} from '../../domain/entities/earn.entity';

export interface EarningsState {
  earnings: BaseListResponse<Earn>;
  earningDetail: Earn;
  error: string;
}

const initialEarningsState: EarningsState = {
  earnings: null,
  earningDetail: null,
  error: null
};

const reducer = createReducer(
  initialEarningsState,
  on(addEarnings, (state, payload) =>
    ({
      ...state,
      earnings: payload.earnings,
      earningDetail: state.earningDetail,
      error: state.error
    })),
  on(addEarningDetail, (state, payload) =>
    ({
      ...state,
      earnings: state.earnings,
      earningDetail: payload.earningDetail,
      error: state.error
    })),
  on(addEarningError, (state, payload) =>
    ({
      ...state,
      earnings: state.earnings,
      earningDetail: state.earningDetail,
      error: payload.error
    })),
  on(clearEarnings, () => initialEarningsState)
);

export function EarningsReducer(state: EarningsState, action: Action) {
  return reducer(state, action);
}
