<div class="select-coin-modal">
  <div class="select-coin-modal__header header">
    <img class="header__img" ngSrc="../../../../assets/img/home-main/logo.png"
         priority
         disableOptimizedSrcset
         width="425"
         height="104"
         alt="fut-logo">
  </div>
  <form [formGroup]="selectCoinForm" (ngSubmit)="submitForm($event)">
    <div class="select-coin-modal__main main">
      <div class="main__message message">
        <label class="message__text">{{'BILL.GENERATE_BILL.MESSAGE_BODY' | translate}}</label>
      </div>
      <br>

      <label class="main__select-title"> {{'BILL.GENERATE_BILL.SELECT_PAYMENT' | translate}}</label>
      <div class="main__select-list select">
        <div class="select__option-container option" *ngFor="let item of coins; index as i"
             [class.mat-elevation-z2]="!item.isSelected"
             [class.mat-elevation-z8]="item.isSelected">
          <mat-card class="option__card card" (click)="select(item)">
            <mat-card-content class="card__content content" [class.style-border]="item.isSelected">
              <img class="content__img" [ngSrc]="item.img" disableOptimizedSrcset width="60" height="60" alt="coin"/>
              <label class="content__label">{{item.name}}</label>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <mat-error *ngIf="isCoinSymbolInvalid() && selectCoinForm.touched">{{'FORM_ERRORS.FIELD_REQUIRED' | translate}}</mat-error>
    </div>

    <div class="select-coin-modal__footer footer">
      <div class="footer__buttons buttons">
        <button mat-flat-button
                matRipple
                class="buttons__btn buttons__btn--accept"
                color="primary"
                [disabled]="disabledButton"
                type="submit">
          {{'CONFIRM_MODAL.ACCEPT' | translate}}
        </button>
        <button mat-stroked-button
                class="buttons__btn buttons__btn--cancel"
                color="primary"
                type="button"
                (click)="cancel()">
          {{'CONFIRM_MODAL.CANCEL' | translate}}
        </button>
      </div>
    </div>
  </form>
</div>
