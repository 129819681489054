<div class="second-factor">
  <ftswp-loader class="spinner" busy></ftswp-loader>
  <div class="second-factor__header">
    <div class="second-factor__header-top">
      <app-back-link></app-back-link>
    </div>
  </div>
  <div class="second-factor-auth">
    <div class="second-factor-auth__header">
      <span class="second-factor-auth__header--title">
        {{ 'TRADER-INFORMATION.ACCESS_TOKENS' | translate }}
    </span>
    </div>
    <div class="second-factor-auth__border"></div>
    <div class="second-factor-auth__content" busy [reverse]="true">
      <div class="second-factor-auth__content--one">
        <div class="second-factor-auth__field">
          <div class="second-factor-auth__field">
            <label class="second-factor-auth__field--value">
              {{ 'TRADER-INFORMATION.PERSONAL_FIRST_PARAGRAPH' | translate }}
            </label>
          </div>
        </div>
      </div>
      <div class="second-factor-auth__content--two">
        <div class="second-factor-auth__field">
          <label class="second-factor-auth__field--name">
            {{ 'TRADER-INFORMATION.ACTIVE_PERSONAL_ACCESS' | translate}}{{ secondFAStatus ? ' 1' : ' 0' }}
          </label>
        </div>
        <div class="second-factor-auth__field">
          <label class="second-factor-auth__field--value"  *ngIf="secondFAStatus">
            {{ 'TRADER-INFORMATION.RESET_TOKEN' | translate}}
          </label>
          <button mat-flat-button
                  *ngIf="!secondFAStatus"
                  (click)="openAuthFactors()"
                  class="second-factor-auth__field--button"
                  color="accent">
            {{ 'TRADER-INFORMATION.CREATE_PERSONAL_AT' | translate}}
          </button>
          <button mat-raised-button
                  *ngIf="secondFAStatus"
                  (click)="openDeleteFactor()"
                  class="second-factor-auth__field--button"
                  color="warn">
            {{ 'TRADER-INFORMATION.DELETE' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
