import {Action, createReducer, on} from '@ngrx/store';
import {addFutcard, addFutcardDetail, addFutcardError, clearFutcard} from './futcard-store.actions';
import {BaseListResponse} from '../../../../../shared/domain/Response/BaseListResponse.entity';
import {Futcard} from '../../domain/entities/futcard.entity';

export interface FutcardState {
  futcard: BaseListResponse<Futcard>;
  futcardDetail: Futcard;
  error: string;
}

const initialFutcardState: FutcardState = {
  futcard: null,
  futcardDetail: null,
  error: null
};

const reducer = createReducer(
  initialFutcardState,
  on(addFutcard, (state, payload) =>
    ({
      ...state,
      futcard: payload.futcard,
      futcardDetail: state.futcardDetail,
      error: state.error
    })),
  on(addFutcardDetail, (state, payload) =>
    ({
      ...state,
      futcard: state.futcard,
      futcardDetail: payload.futcardDetail,
      error: state.error
    })),
  on(addFutcardError, (state, payload) =>
    ({
      ...state,
      futcard: state.futcard,
      futcardDetail: state.futcardDetail,
      error: payload.error
    })),
  on(clearFutcard, () => initialFutcardState)
);

export function FutcardReducer(state: FutcardState, action: Action) {
  return reducer(state, action);
}
