import {Action, createReducer, on} from '@ngrx/store';

import * as UsersActions from './users-store-actions';
import {UsersResponse} from '../../domain/users-list/users.response';

export interface UsersState {
  users: UsersResponse;
  error: string;
}

const initialUsersState: UsersState = {
  users: null,
  error: null
};

const reducer = createReducer(
  initialUsersState,
  on(UsersActions.addUsers, (state, payload) =>
    ({
      ...state,
      users: payload.users,
      error: null
    })),
  on(UsersActions.addError, (state, payload) => {
    return ({
      ...state,
      users: state.users,
      error: payload.error
    });
  }),
  on(UsersActions.clearUsers, state => initialUsersState)
);

export function UsersReducer(state: UsersState, action: Action) {
  return reducer(state, action);
}
