import {createAction, props} from '@ngrx/store';
import {BaseListResponse} from '../../../../../../shared/domain/Response/BaseListResponse.entity';
import {BaseClient} from '../../../../../../shared/domain/Response/BaseClient.entity';


export const addClients = createAction('[Clients] add Clients', props<{ clients: BaseListResponse<BaseClient> }>());
export const addClientsDetail = createAction('[Clients Detail] Add Client Detail', props<{
  clientsDetail: BaseClient
}>());
export const clientsError = createAction('[Clients] Error', props<{ error: string }>());
export const clearErrorStore = createAction('[Clients] Clear errors store');
export const clearClients = createAction('[Clients] Clear clients store');
