<div class="main">
  <div class="main__title">
    <span>{{'REPORTS.CLIENT' | translate}}: {{client}}</span>
  </div>
  <div class="main__tables">
    <div class="tables__table">
      <span class="table__title">{{'DASHBOARD.CHARTS_TITLE_WITHDRAWALS' | translate}}</span>
      <table class="table__tbl">
        <thead class="table__head">
        <tr class="table__head--row">
          <th class="table__head--cell" *ngFor="let column of tableHeaders">{{column | translate}}</th>
        </tr>
        </thead>
        <tbody class="table__body">
        <tr class="table__body--row" *ngFor="let withdrawal of withdrawals">
          <td class="table__body--cell">{{withdrawal?.finishDate | date: dateUtils.datePipeFormatDays}}</td>
          <td class="table__body--cell">{{withdrawal?.invoiceId.slice(-8)}}</td>
          <td class="table__body--cell table__money">{{withdrawal?.quantity}}</td>
          <td class="table__body--cell table__value table__money">{{toCurrency(withdrawal?.totalTransactionsAmount)}}</td>
          <td class="table__body--cell table__money">{{withdrawal?.percentage + '%'}}</td>
          <td class="table__body--cell table__money">{{toCurrency(withdrawal?.rate)}}</td>
          <td class="table__body--cell">
            <div class="cell table__actions">
              <button class="cell__btn" (click)="goToDetail(withdrawal.invoiceId)">
                +
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="tables__table">
      <span class="table__title">{{'DASHBOARD.CHARTS_TITLE_DEPOSITS' | translate}}</span>
      <table class="table__tbl">
        <thead class="table__head">
        <tr class="table__head--row">
          <th class="table__head--cell" *ngFor="let column of tableHeaders">{{column | translate}}</th>
        </tr>
        </thead>
        <tbody class="table__body">
        <tr class="table__body--row" *ngFor="let deposit of deposits">
          <td class="table__body--cell">{{deposit?.finishDate | date: dateUtils.datePipeFormatDays}}</td>
          <td class="table__body--cell">{{deposit?.invoiceId.slice(-8)}}</td>
          <td class="table__body--cell table__money">{{deposit?.quantity}}</td>
          <td class="table__body--cell table__value table__money">{{toCurrency(deposit?.totalTransactionsAmount)}}</td>
          <td class="table__body--cell table__money">{{deposit?.percentage + '%'}}</td>
          <td class="table__body--cell table__money">{{toCurrency(deposit?.rate)}}</td>
          <td class="table__body--cell">
            <div class="cell table__actions">
              <button class="cell__btn" (click)="goToDetail(deposit.invoiceId)">
<!--                {{ 'TRANSACTIONS.VIEW_DETAIL' | translate }}-->
                +
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="main__totals totals">
    <table class="totals__table">
      <tbody class="table__body">
      <tr class="table__body--row">
        <td class="table__body--cell table__body--bold">{{"REPORTS.DEPOSITS" | translate}}:</td>
        <td class="table__body--cell">{{toCurrency(totalDeposits)}}</td>
        <td class="table__body--cell table__body--bold">{{"REPORTS.WITHDRAWALS" | translate}}:</td>
        <td class="table__body--cell">{{toCurrency(totalWithdrawals)}}</td>
        <td class="table__body--cell table__body--bold">{{'Total'}}:</td>
        <td class="table__body--cell">{{toCurrency(total)}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
