import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BusyNotifierService} from '../Services/busy-notifier.service';
import {finalize, Observable} from 'rxjs';

@Injectable()
export class LoadingIndicatorInterceptor implements HttpInterceptor {

  private requestCounter = 0;

  constructor(private busyNotifier: BusyNotifierService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.urlMatches(req.url)) {
      this.beginRequest();
      return next.handle(req).pipe(
        finalize(() => {
          this.endRequest();
        })
      );
    }
    return next.handle(req)
      .pipe(
        finalize(() => {
          this.endRequest();
        })
      );
  }

  urlMatches(url: string): boolean {
    const urlsToIgnore = [
      'socket.io',
      '/company/finder',
      'download',
      'user/random-password',
      'activate-otc',
      'country-code',
    ];
    return !!urlsToIgnore.find(u => url.includes(u) || !!url.match(u)?.['0']);
  }

  beginRequest() {
    this.requestCounter = Math.max(this.requestCounter, 0) + 1;

    if (this.requestCounter === 1) {
      this.busyNotifier.busy$.next(true);
    }
  }

  endRequest() {
    this.requestCounter = Math.max(this.requestCounter, 1) - 1;

    if (this.requestCounter === 0) {
      this.busyNotifier.busy$.next(false);
    }
  }
}
