import {Component, OnDestroy, OnInit} from '@angular/core';
import {TraderInformationApplication} from '../../../../trader-information/application/trader-information.application';
import {
  TraderInformationServices
} from '../../../../trader-information/infrastructure/services/trader-information.services';
import {
  FacadeTraderInformationStore
} from '../../../../trader-information/infrastructure/store/facade-trader-information.store';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  AuthFactorsModalComponent
} from '../../../../trader-information/ui/auth-factors-modal/auth-factors-modal.component';
import {
  DeleteFactorsModalComponent
} from '../../../../trader-information/ui/delete-factors-modal/delete-factors-modal.component';
import {StorageService} from '../../../../../shared/Infrastructure/Services/storage.service';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';
import {BusyNotifierService} from '../../../../../shared/Infrastructure/Services/busy-notifier.service';

@Component({
  selector: 'app-second-factor-auth',
  templateUrl: './second-factor-auth.component.html',
  styleUrls: ['./second-factor-auth.component.scss']
})
export class SecondFactorAuthComponent implements OnInit, OnDestroy {

  private userInformationApplication: TraderInformationApplication;
  public secondFAStatus: boolean;

  private actualModule = 'security';

  public mode: ProgressSpinnerMode = 'indeterminate';

  constructor(
    private userInformationService: TraderInformationServices,
    private facadeUserInformationStore: FacadeTraderInformationStore,
    private matDialog: MatDialog,
    private storageService: StorageService,
    private busyNotifierService: BusyNotifierService
  ) {
    this.userInformationApplication = new TraderInformationApplication(userInformationService, facadeUserInformationStore);
    this.storageService.clearTableFilters(this.actualModule, null, true);
  }

  ngOnInit(): void {
    this.userInformationApplication.secondFAStatus$.subscribe(value => {
      if (value) {
        this.secondFAStatus = value.status;
        this.busyNotifierService.busy$.next(false);
      }
    });
    this.userInformationApplication.faQrStatus();
  }

  ngOnDestroy() {
    this.userInformationApplication.destroySubscriptionTraderInformation();
  }

  async openAuthFactors() {
    let dialogModal: MatDialogRef<AuthFactorsModalComponent>;
    dialogModal = this.matDialog.open(AuthFactorsModalComponent);
    return dialogModal.afterClosed()
      .subscribe(async value => {
        if (value) {
          this.secondFAStatus = true;
          this.userInformationApplication.faQrStatus();
        }
      });
  }

  async openDeleteFactor() {
    let dialogModal: MatDialogRef<DeleteFactorsModalComponent>;
    dialogModal = this.matDialog.open(DeleteFactorsModalComponent);
    return dialogModal.afterClosed()
      .subscribe(async value => {
        if (value) {
          this.secondFAStatus = false;
          this.userInformationApplication.faQrStatus();
        }
      });
  }
}
