export const CoinConstants = {
  BTC: {name: 'bitcoin', symbol: 'BTC', network: 'bitcoin', networkSymbol: 'BTC', networkURI: 'bitcoin'},
  ETH: {name: 'ethereum', symbol: 'ETH', network: 'ethereum', networkSymbol: 'ETH', networkURI: 'ethereum'},
  USDT: {name: 'tether', symbol: 'USDT', network: 'tron', networkSymbol: 'TRON', networkURI: 'tron'},
  USDTE: {name: 'tether', symbol: 'USDT(ETH)', network: 'ethereum', networkSymbol: 'ETH', networkURI: 'ethereum'},
  USDTT: {name: 'tether', symbol: 'USDT(TRON)', network: 'tron', networkSymbol: 'TRON', networkURI: 'tron'},
  BNB: {name: 'BNB', symbol: 'BNB', network: 'binance', networkSymbol: 'BSC', networkURI: 'ethereum'},
  TRX: {name: 'tronix', symbol: 'TRX', network: 'tron', networkSymbol: 'TRON', networkURI: 'tron'},
  MATIC: {name: 'Matic', symbol: 'MATIC', network: 'polygon', networkSymbol: 'POLYGON', networkURI: 'ethereum'},
  FTSWP: {name: 'Decpay', symbol: 'DCPi', network: 'polygon', networkSymbol: 'POLYGON'},
  ETHE: 'ETH',
  USD: 'USD'
};
