import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {SnackBarService} from '../Services/snack-bar.service';
import {catchError, tap} from 'rxjs/operators';
import {broadCastChannel} from '../../../app/app.component';
import {RouterConstants} from '../router.constants';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {throwError} from 'rxjs';

@Injectable()
export class ErrorNotifierInterceptor implements HttpInterceptor {

  constructor(private snackBarService: SnackBarService,
              private translateService: TranslateService,
              private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              localStorage.clear();
              this.router.navigate([RouterConstants.LOGIN]);
              broadCastChannel.postMessage('logout');
              return throwError(() => error.error.message);
            } else {
              // this.snackBarService.openErrorToast(`ERRORS.${this.getErrorTranslate(error.error ? error.error.message : null)}`);
              return throwError(() => this.getErrorTranslate(error.error ? error.error.message : null));
            }
            // return throwError(() => this.getErrorTranslate(error.error ? error.error.message : null));
          }
        })
      );
  }

  private getErrorTranslate(error: any): string {
    let textTranslate = '';
    if (error) {
      if (error instanceof Array) {
        error.forEach(err => {
          this.translateService
            .get(err)
            .subscribe(
              (text: string) => {
                textTranslate += ` ${text},`;
              });
        });
        return textTranslate.slice(0, -1);

      } else {
        this.translateService
          .get(error)
          .subscribe(
            (text: string) => {
              textTranslate = text;
            });
        return textTranslate;
      }
    }

    this.translateService
      .get('UNEXPECTED')
      .subscribe(
        (text: string) => {
          textTranslate = text;
        }
      );
    return textTranslate;
  }
}
