// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {environment as defaults} from './environment.defaults';

export const environment = {
  ...defaults,
  // urlApi: 'http://144.217.88.168:5050/api', // dev
   urlApi: 'https://unity-srv.decpay.co/api', // dev
  // urlApi: 'https://test.futswappay.com:6050/api', // test
  // urlApi: 'http://144.217.88.168:5010/api', // admin dev
  // urlApi: 'http://192.168.1.26:5001/api', // admin santi
  // urlApi: 'https://test.futswappay.com:6060/api', // admin test
  // isAdmin: true, // depende del environment si es admin
  isAdmin: false, // depende del environment si es cliente
  production: true,
  urlCountriesApi: 'https://restcountries.eu/',
  urlSocket: 'wss://unity-srv.decpay.co', //  socket dev
  // urlSocket: 'ws://144.217.88.168:5010', // socket admin dev
  // urlSocket: 'ws://localhost:5001', // socket test

  // local
  /* captcha_key_v3: '6LfAQmUcAAAAAKxUq7JT-nd_jyv0gLaBRvjeP_bO',
   captcha_key_v2: '6Ldt0GgcAAAAABgQ8Nvz2tN5LD2XO9cHYOyOAS-a',*/

  // produccion

  captcha_key_v3: '6LcUuQsfAAAAAHey_QVgGCkM-wttsDHzk4VpIhZr',
  captcha_key_v2: '6LcUuQsfAAAAADe6_ZIBIMCfJypbe7ki6VU-TTk-',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.login`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
