import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  private defaultTimeDuration = 4000;

  constructor(private matSnackBar: MatSnackBar,
              private translateService: TranslateService) { }

  openDefaultToast(message: string, time?: number) {
    this.matSnackBar.open(this.translateService.instant(message), '', {
      duration: time ? time : this.defaultTimeDuration,
      panelClass: ['default-toast']
    });
  }

  openSuccessToast(message: string, time?: number) {
    this.matSnackBar.open(this.translateService.instant(message), '', {
      duration: time ? time : this.defaultTimeDuration,
      panelClass: ['success-toast'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

  openErrorToast(message: string, time?: number) {
    this.matSnackBar.open(this.translateService.instant(message), '', {
      duration: time ? time : this.defaultTimeDuration,
      panelClass: ['error-toast'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
}
