<div class="table-footer">
  <div class="table-footer-inner">
    <div class="datatable-pager">
      <ul class="pager">
        <li [class.disabled]="this.page === 1" class="disabled">
          <a (click)="selectPage(1)">
            <i class="material-icons">skip_previous</i>
          </a>
        </li>
        <li [class.disabled]="this.page === 1" class="disabled">
          <a (click)="selectPage(this.page - 1)">
            <i class="material-icons">navigate_before</i>
          </a>
        </li>
        <li [class.active]="this.page === item" *ngFor="let item of this.pages">
          <a (click)="selectPage(item)">{{item}}</a>
        </li>
        <li [class.disabled]="this.page === this.totalPages">
          <a (click)="selectPage(this.page + 1)">
            <i class="material-icons">navigate_next</i>
          </a>
        </li>
        <li [class.disabled]="this.page === this.totalPages">
          <a (click)="selectPage(this.totalPages)">
            <i class="material-icons">skip_next</i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
