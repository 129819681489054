import {Action, createReducer, on} from '@ngrx/store';
import {addCard, addCardDetail, addCardError, clearCard} from './card-request-store.actions';
import {BaseListResponse} from '../../../../../shared/domain/Response/BaseListResponse.entity';
import {CardRequest} from '../../domain/entities/card-request.entity';

export interface CardState {
  card: BaseListResponse<CardRequest>;
  cardDetail: CardRequest;
  error: string;
}

const initialCardState: CardState = {
  card: null,
  cardDetail: null,
  error: null
};

const reducer = createReducer(
  initialCardState,
  on(addCard, (state, payload) =>
    ({
      ...state,
      card: payload.card,
      cardDetail: state.cardDetail,
      error: state.error
    })),
  on(addCardDetail, (state, payload) =>
    ({
      ...state,
      card: state.card,
      cardDetail: payload.cardDetail,
      error: state.error
    })),
  on(addCardError, (state, payload) =>
    ({
      ...state,
      card: state.card,
      cardDetail: state.cardDetail,
      error: payload.error
    })),
  on(clearCard, () => initialCardState)
);

export function CardReducer(state: CardState, action: Action) {
  return reducer(state, action);
}
