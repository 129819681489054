import {Component, Input} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {Module} from '../../../../../shared/domain/Response/Modules.entity';
import {NavigationEnd, Router} from '@angular/router';
import {VerticalMenuStateService} from '../../../../../shared/Infrastructure/Services/vertical-menu-state.service';
import {filter} from 'rxjs';

export interface GroupedModules {
  group: {
    icon: string,
    name: string
  };
  modules: Array<Module>;
}

@Component({
  selector: 'collapsible-menu-group',
  templateUrl: './collapsible-menu-group.component.html',
  styleUrls: ['../collapsible-menu-item/collapsible-menu-item.component.scss']
})
export class CollapsibleMenuGroupComponent {

  @Input({alias: 'group'}) groupedModules: GroupedModules;

  public expanded: boolean;

  constructor(private router: Router,
              public verticalMenuState: VerticalMenuStateService) {
    this.router.events
      .pipe(
        filter(value => value instanceof NavigationEnd)
      )
      .subscribe((value: NavigationEnd) => {
        const submoduleActive = this.groupedModules.modules.find(mod => {
          return this.pathToCompare(mod.path) === value.urlAfterRedirects;
        });
        this.expanded = !!submoduleActive;
      });
  }

  ngOnInit(): void {
    const submoduleActive = this.groupedModules.modules.find(mod => this.pathToCompare(mod.path) === this.router.url);
    this.expanded = !!submoduleActive;
  }

  pathToCompare = (path) => environment.isAdmin ? `/admin/${path}` : this.router.url;

  get route() {
    return environment.isAdmin ?
      `/admin/${this.groupedModules.modules[0].path}` :
      `/${this.groupedModules.modules[0].path}`;
  }

  get group() {
    return this.groupedModules.group;
  }

  get modules(): Array<Module> {
    return this.groupedModules.modules;
  }
}
