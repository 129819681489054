import {createFeatureSelector, createSelector} from '@ngrx/store';
import {LoginUserState} from './LoginUserStoreReducer';


export const AUTH_STATE = 'auth';
const authState = createFeatureSelector<LoginUserState>(AUTH_STATE);

export const selectToken = createSelector(authState, (state) => state.token);
export const selectError = createSelector(authState, (state) => state.error);
export const selectUserSessionInfo = createSelector(authState, (state) => state.sessionInfo);

