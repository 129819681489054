<form class="date-picker" [class.no-btn]="noBtn">
  <mat-form-field appearance="fill" subscriptSizing="dynamic" class="date-picker__field field">
    <mat-label class="field__label">{{"WALLETS.SENDING_MONEY.DATE_RANGE" | translate}}</mat-label>
    <mat-date-range-input [formGroup]="groupDataRange" [rangePicker]="picker">
      <input matStartDate formControlName="startDate"
             [placeholder]='"WALLETS.SENDING_MONEY.START_DATE" | translate'
             (dateChange)="emitStartDate()">
      <input matEndDate formControlName="endDate"
             [placeholder]='"WALLETS.SENDING_MONEY.END_DATE" | translate'
             (dateChange)="emitEndDate()">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
  <button class="date-picker__btn btn"
          *ngIf="!noBtn"
          (click)="onSearch()">
    <mat-icon class="btn__icon">search</mat-icon>
  </button>
</form>
