<div class="auth-2fa-modal">
  <div class="auth-2fa-modal__header">
    <label class="auth-2fa-modal__header--title">{{'WALLETS.PIN.VERIFICATION' | translate}}</label>
  </div>
  <form [formGroup]="pinForm" (ngSubmit)="accept()" style="width: 75%">
    <div class="auth-2fa-modal__main">
      <mat-form-field class="auth-2fa-modal__main--field">
        <mat-label class="auth-2fa-modal__main--field-name">{{'WALLETS.PIN.PIN_GOOGLE' | translate}}</mat-label>
        <input formControlName="twoFactorAuthCode"
               matInput
               type="text"
               maxlength="6"
               [placeholder]="'WALLETS.PIN.GOOGLE_CODE' | translate">
        <mat-error *ngIf=" isGoogleCodeInvalid()">{{'FORM_ERRORS.FIELD_REQUIRED' | translate}}</mat-error>
        <mat-error *ngIf="googleCode().hasError('pattern')">{{'FORM_ERRORS.INVALID_FORMAT' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div class="auth-2fa-modal__footer">
      <div class="auth-2fa-modal__footer--button">
        <button mat-flat-button
                [disabled] = "disabledAcceptBtn"
                class="auth-2fa-modal__footer--button-accept"
                color="accent"
                type="submit">
          {{'CONFIRM_MODAL.ACCEPT' | translate}}
        </button>
        <button mat-flat-button
                class="auth-2fa-modal__footer--button-cancel"
                color="accent"
                type="button"
                (click)="cancel()">
          {{'CONFIRM_MODAL.CANCEL' | translate}}
        </button>
      </div>
    </div>
  </form>
</div>
