<div class="collapsible-item-menu-container"
     [routerLinkActive]="modulePath ? 'item-menu-selected' : 'nonPossibleRoute'">
  <div class="collapsible-item-menu" [class.collapsible-item-menu-expanded]="!expanded">
    <div class="collapsible-left-group" [routerLink]="getRoute()"
         (click)="expanded = true; verticalMenuState.setVerticalMenuState(false)">
      <span class="collapsible-item-icon material-icons">{{moduleIcon}}</span>
      <span class="collapsible-item-name">{{'MENU_MODULES.' + moduleName | translate}}</span>
    </div>
    <div class="collapsible-right-symbol"
         [class.collapsible-right-symbol-hidden]="!isRoot || subModules?.length === 0"
         (click)="expanded = !expanded"> +
    </div>
  </div>
</div>

<div class="collapsible-item-submenu-container"
     [class.collapsible-item-submenu-hidden]="expanded"
     *ngFor="let subModule of subModules">
  <app-collapsible-menu-item *ngIf="subModule"
                             [moduleIcon]="subModule.icon"
                             [moduleName]="subModule.name"
                             [isRoot]="subModule.isRoot"
                             [modulePath]="subModule.path">
  </app-collapsible-menu-item>
</div>
