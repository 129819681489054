import {createAction, props} from '@ngrx/store';
import {CompaniesResponse} from '../../domain/companies/companies.response';
import {BaseListResponse} from '../../../../../shared/domain/Response/BaseListResponse.entity';
import {Report} from '../../domain/reports-list/reports-list.response';


export const addReports = createAction('[reports] Add reports', props<{ reports: BaseListResponse<Report> }>());
export const addCompanies = createAction('[reports] Add reports', props<{ companies: Array<CompaniesResponse> }>());
export const addReportsError = createAction('[reports] Add Error of reports', props<{ error: string }>());
export const clearReports = createAction('[reports] clear reports');
