<div class="delete-factors-modal">
  <div class="delete-factors-modal-body">
    <div class="delete-factors-modal-body__button">
      <button>
        <mat-icon (click)="cancel()"> close</mat-icon>
      </button>
    </div>
    <div class="delete-factors-modal-body__title">
      <span>{{"AUTH_FACTORS.DELETE" | translate}}</span>
    </div>
    <div class="delete-factors-modal-body__info">
      <div class="delete-factors-modal-body__info-value form">
        <form class="delete-factors-modal-body__info-value-actions" [formGroup]="deleteFvForm"
              (ngSubmit)="delete2FA($event)">
          <div class="delete-factors-modal-body__info-value-actions--form-field">
            <span>{{"AUTH_FACTORS.APP_CODE" | translate}}</span>
            <p>{{"AUTH_FACTORS.ACTIVATE_MS_APP" | translate}}</p>
            <mat-form-field
              class="form-field"
              appearance="fill">
              <input formControlName="appCode" type="text" pattern="[0-9]{6}" matInput>
              <mat-error
                *ngIf="showError('appCode')">{{getErrorMessage('appCode') | translate}}</mat-error>
              <img *ngIf="deleteFvForm.controls['appCode'].valid" ngSrc="/assets/img/general/checked-icon.svg"
                   disableOptimizedSrcset
                   width="26"
                   height="26"
                   alt="..."
                   class="form-field__btn img" matSuffix>
            </mat-form-field>
          </div>
          <div class="delete-factors-modal-body__info-value-actions--form-field">
            <span>{{"AUTH_FACTORS.EMAIL_CODE" | translate}}</span>
            <p>{{"AUTH_FACTORS.ACTIVATE_MS_EMAIL" | translate}}</p>
            <mat-form-field
              class="form-field"
              appearance="fill">
              <input formControlName="emailCode" type="text" pattern="[0-9]{6}" matInput maxlength="6">
              <mat-error *ngIf="showError('emailCode')">{{getErrorMessage('emailCode') | translate}}</mat-error>
              <button *ngIf="deleteFvForm.controls['emailCode'].invalid || !emailSent" class="form-field__btn"
                      mat-icon-button matSuffix (click)="sendEmailOtp()" type="button">
                {{ (emailSent ? 'WALLETS.PIN.SENT' : 'WALLETS.PIN.SEND') | translate }}
              </button>
              <img *ngIf="deleteFvForm.controls['emailCode'].valid && emailSent" alt="..."
                   ngSrc="/assets/img/general/checked-icon.svg" disableOptimizedSrcset width="26" height="26" class="form-field__btn img" matSuffix>
            </mat-form-field>
          </div>
          <button mat-flat-button
                  color="warn"
                  type="submit">
            {{'TRADER-INFORMATION.DELETE' | translate}}
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
