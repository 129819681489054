import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class BusyNotifierService {

  public busy$: BehaviorSubject<boolean>;

  constructor() {
    this.busy$ = new BehaviorSubject<boolean>(false);
    this.busy$.next(false);
  }
}
