import {Swap} from '../../domain/entities/swap.entity';
import {Action, createReducer, on} from '@ngrx/store';
import {addSwap, addSwapDetail, addSwapError, clearSwap} from './swap-store.actions';
import {BaseListResponse} from '../../../../../shared/domain/Response/BaseListResponse.entity';

export interface SwapState {
  swap: BaseListResponse<Swap>;
  swapDetail: Swap;
  error: string;
}

const initialSwapState: SwapState = {
  swap: null,
  swapDetail: null,
  error: null
};

const reducer = createReducer(
  initialSwapState,
  on(addSwap, (state, payload) =>
    ({
      ...state,
      swap: payload.swap,
      swapDetail: state.swapDetail,
      error: state.error
    })),
  on(addSwapDetail, (state, payload) =>
    ({
      ...state,
      swap: state.swap,
      swapDetail: payload.swapDetail,
      error: state.error
    })),
  on(addSwapError, (state, payload) =>
    ({
      ...state,
      swap: state.swap,
      swapDetail: state.swapDetail,
      error: payload.error
    })),
  on(clearSwap, () => initialSwapState)
);

export function SwapReducer(state: SwapState, action: Action) {
  return reducer(state, action);
}
