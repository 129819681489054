import {Component, OnInit} from '@angular/core';
import {VerticalMenuStateService} from '../../../../../shared/Infrastructure/Services/vertical-menu-state.service';

@Component({
  selector: 'app-mobile-vertical-menu',
  templateUrl: './mobile-vertical-menu.component.html',
  styleUrls: ['./mobile-vertical-menu.component.scss']
})
export class MobileVerticalMenuComponent implements OnInit {

  public menuState: boolean;

  constructor(public verticalMenuState: VerticalMenuStateService) {
  }

  ngOnInit(): void {
    this.verticalMenuState.menuState$.subscribe(menuState => this.menuState = menuState);
  }

  toggleMenu() {
    this.verticalMenuState.setVerticalMenuState(!this.menuState);
  }
}
