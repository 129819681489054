import {InjectionToken, ValueProvider} from '@angular/core';

export const STRING_TOKEN = new InjectionToken<string>('string');

export const DATE_TOKEN = new InjectionToken<Date>('date');

export const NUMBER_TOKEN = new InjectionToken<number>('number');

export const BOOLEAN_TOKEN = new InjectionToken<boolean>('boolean');


export const primitiveTokens: Array<ValueProvider> = [
  {
    provide: STRING_TOKEN,
    useValue: ''
  },
  {
    provide: DATE_TOKEN,
    useValue: new Date()
  },
  {
    provide: NUMBER_TOKEN,
    useValue: 0
  },
  {
    provide: BOOLEAN_TOKEN,
    useValue: false
  }
];
