import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../../../Infrastructure/Services/snack-bar.service';


@Component({
  selector: 'app-auth-2fa-modal',
  templateUrl: './auth-2fa-modal.component.html',
  styleUrls: ['./auth-2fa-modal.component.scss']
})
export class Auth2faModalComponent implements OnInit {

  public pinForm: FormGroup;

  public disabledAcceptBtn = false;

  constructor(private snackBarService: SnackBarService,
              private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<Auth2faModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  async ngOnInit(): Promise<void> {
    this.buildGeneratePinForm();
  }


  accept = () => this.dialogRef.close({success: true, code: this.pinForm.controls?.twoFactorAuthCode.value});
  cancel = () => this.dialogRef.close(false);

  googleCode = () => this.pinForm.get('twoFactorAuthCode');


  isGoogleCodeInvalid = () => this.pinForm.get('twoFactorAuthCode').invalid && this.pinForm.get('twoFactorAuthCode').touched;

  buildGeneratePinForm() {
    this.pinForm = this.formBuilder.group(
      {
        twoFactorAuthCode: ['', [Validators.required, Validators.pattern(/[0-9]{6}/)]],
      }
    );
  }
}
