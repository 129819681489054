<form class="search">

<!-- Search  -->

  <mat-form-field *ngIf="usingSearch" appearance="fill" subscriptSizing="dynamic" class="search__field field">
    <mat-label class="field__label">{{"TRANSACTIONS.SEARCH_BY" | translate}}</mat-label>
    <mat-select class="field__select" hideSingleSelectionIndicator [(ngModel)]="selectedSearchParam"
                name="selectedSearchParam"
                (valueChange)="paramChange()">
      <mat-option *ngFor="let param of searchParamsOpts"
                  [value]="param.value">{{param.name | translate}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="usingSearch" class="search__field field" appearance="fill" subscriptSizing="dynamic">
    <mat-label class="field__label">{{"WALLETS.INPUT.PLACEHOLDER" | translate}}</mat-label>
    <mat-icon class="field__suffix" matSuffix *ngIf="selectedSearchValue"
              (click)="clearSearch()">close
    </mat-icon>
    <input matInput *ngIf="!useStatusAsParam && !useCoinsAsParam && !useTypeOrMovementAsParam"
           autocomplete="off"
           (input)="searchParam()"
           [(ngModel)]="selectedSearchValue"
           name="selectedSearchValue"
           placeholder="{{'WALLETS.INPUT.PLACEHOLDER' | translate}}">
    <mat-select class="field__select" hideSingleSelectionIndicator *ngIf="useStatusAsParam" [(ngModel)]="selectedSearchValue"
                name="selectedSearchValue"
                (selectionChange)="searchParam()">
      <mat-option *ngFor="let status of searchStatusOpts" [value]="status">{{ statusI18nDir + status | translate }}</mat-option>
    </mat-select>
    <mat-select class="field__select" hideSingleSelectionIndicator *ngIf="useTypeOrMovementAsParam" [(ngModel)]="selectedSearchValue"
                name="selectedSearchValue"
                (selectionChange)="searchParam()">
      <mat-option *ngFor="let opt of (selectedSearchParam === 'type' ? typesOpts : movementsOpts)" [value]="opt">{{  (selectedSearchParam === 'type' ? typeJsonDir : movementJsonDir) + opt | translate }}</mat-option>
    </mat-select>
    <mat-select class="field__select" hideSingleSelectionIndicator *ngIf="useCoinsAsParam" [(ngModel)]="selectedSearchValue"
                name="selectedSearchValue"
                (selectionChange)="searchParam()">
      <mat-option *ngFor="let coin of (useDefaultCoinsOpts ? defaultSearchCoinOpts : searchCoinsOpts)" [value]="coin.value">{{ coin.name }}</mat-option>
    </mat-select>
  </mat-form-field>

<!--  Sort  -->

  <mat-form-field *ngIf="!usingSearch" appearance="fill" subscriptSizing="dynamic" class="search__field field">
    <mat-label class="field__label">{{"TRANSACTIONS.SORT_BY" | translate}}</mat-label>
    <mat-select class="field__select" hideSingleSelectionIndicator [(ngModel)]="selectedSortParam"
                name="selectedSortParam"
                (ngModelChange)="paramChange(true)">
      <mat-option *ngFor="let param of sortParamOpts"
                  [value]="param.name">{{ param.value | translate}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="!usingSearch" class="search__field field" appearance="fill" subscriptSizing="dynamic">
    <mat-label class="field__label">{{"WALLETS.INPUT.PLACEHOLDER" | translate}}</mat-label>
    <mat-icon class="field__suffix" matSuffix *ngIf="selectedSortParam"
              (click)="clearSearch(true)">close
    </mat-icon>
    <mat-select class="field__select" hideSingleSelectionIndicator [(ngModel)]="selectedSortValue"
                name="selectedSortValue"
                (selectionChange)="sortData()">
      <mat-option *ngIf="!isNumeric" [value]="sortValueOpt.desc">{{ 'A-Z' }}</mat-option>
      <mat-option *ngIf="!isNumeric" [value]="sortValueOpt.asc">{{ 'Z-A' }}</mat-option>
      <mat-option *ngIf="isNumeric" [value]="sortValueOpt.desc">{{ 'TRANSACTIONS.L_TO_H' | translate }}</mat-option>
      <mat-option *ngIf="isNumeric" [value]="sortValueOpt.asc">{{ 'TRANSACTIONS.H_TO_L' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>

  <button matRipple class="search__btn btn" (click)="usingSearch ? searchParam() : sortData()">
    <mat-icon class="btn__icon">search</mat-icon>
  </button>
</form>
