<div class="attach-file">
  <div class="attach-file__content">
    <input type="file" class="file-input"
           (change)="onFileSelected($event)" #fileUpload>

    <button matRipple [id]="sharedBtnId"
             (click)="openSelectCoinModal()">
      <mat-icon>backup</mat-icon>
      <span>{{"SHARED.UPLOAD_FILE" | translate}}</span>
    </button>
  </div>
</div>
