import {Component, OnInit} from '@angular/core';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';


@Component({
  selector: 'app-home-main',
  templateUrl: './home-main.component.html',
  styleUrls: ['./home-main.component.scss']
})
export class HomeMainComponent implements OnInit{

  public mode: ProgressSpinnerMode = 'indeterminate';

  constructor() {
  }

  ngOnInit() {

  }
}
