import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {DateUtils} from '../../../domain/Utils/DateUtils';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {

  @Input() startDate: Date;
  @Input() endDate: Date;

  @Input() formGroup: FormGroup;
  @Input() noBtn: boolean;
  public groupDataRange: FormGroup;

  @Output() startDateChange = new EventEmitter<Date>();
  @Output() endDateChange = new EventEmitter<Date>();
  @Output() search: EventEmitter<unknown> = new EventEmitter<unknown>();


  constructor(
  ) {
  }

  ngOnInit(): void {
    this.groupDataRange = new FormGroup({
      startDate: new FormControl(this.startDate),
      endDate: new FormControl(this.endDate),
    });
  }

  emitStartDate() {
    let start: any = this.groupDataRange.get('startDate').value._d;
    start = new Date(start);
    start = DateUtils.setStartHours(start);
    this.startDateChange.emit(start);
  }

  emitEndDate() {
    let end = new Date(this.groupDataRange.get('endDate').value?._d);
    end = DateUtils.setEndHours(end);
    this.endDateChange.emit(end);
  }

  onSearch() {
    this.search.emit();
  }
}
