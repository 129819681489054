import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {SharedModule} from '../shared/shared.module';
import {AsyncPipe, DatePipe} from '@angular/common';
import {environment} from '../environments/environment';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {AppRoutingModule} from './app-routing.module';
import {HomeModule} from './home/home.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {StoreModule} from '@ngrx/store';
import {appReducers} from '../shared/Infrastructure/store/app.reducer';
import {register} from 'swiper/element/bundle';
import {interceptors} from '../shared/Infrastructure/Interceptors/interceptors.config';

register();

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    HomeModule,
    SharedModule,
    FormsModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(appReducers),
    environment.production ? [] : StoreDevtoolsModule.instrument({name: 'FTSWP'}),
  ],
  providers: [
    AsyncPipe,
    DatePipe,
    ...interceptors
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
