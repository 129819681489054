import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CoinConstants} from '../../../domain/constants/coin.constants';

export interface Param {
  param?: string;
  name?: string;
  value?: string;
  type?: string;
  page?: number;
}

@Component({
  selector: 'app-search-param',
  templateUrl: './search-param.component.html',
  styleUrls: ['./search-param.component.scss']
})
export class SearchParamComponent {

  @Input('searchParams')
  searchParamsOpts: Array<Param>;

  @Input('statusOpts')
  searchStatusOpts: Array<string>;

  @Input()
  statusI18nDir: string;

  @Input('coins')
  searchCoinsOpts: Array<Param>;

  @Input('sortParams')
  sortParamOpts: Array<Param>;

  @Input('movements')
  movementsOpts: Array<string>;

  @Input()
  movementJsonDir: string;

  @Input('types')
  typesOpts: Array<string>;

  @Input()
  typeJsonDir: string;

  @Output('search')
  searchChange: EventEmitter<Param> = new EventEmitter();

  @Output('sort')
  sortChange: EventEmitter<Param> = new EventEmitter();

  @Input('defaultSearchParam') selectedSearchParam: string;
  @Input('defaultSearchValue') selectedSearchValue: string;
  @Input('defaultSortParam') selectedSortParam: string;
  @Input('defaultSortValue') selectedSortValue: string;

  public defaultSearchCoinOpts: Array<Param> = [
    {
      name: this.coinConstants.BTC.symbol,
      value: this.coinConstants.BTC.symbol
    },
    {
      name: this.coinConstants.ETH.symbol,
      value: this.coinConstants.ETH.symbol
    },
    {
      name: this.coinConstants.USDT.symbol,
      value: this.coinConstants.USDT.symbol
    },
    {
      name: this.coinConstants.BNB.symbol,
      value: this.coinConstants.BNB.symbol
    }
  ];

  public sortValueOpt = {
    asc: 'DOWNWARD',
    desc: 'UPWARD'
  };

  public isNumeric: boolean;

  constructor() {
  }

  searchParam() {
    this.searchChange.emit({
      param: this.selectedSearchParam,
      value: this.selectedSearchValue,
      type: null
    });
  }

  sortData() {
    this.sortChange.emit({
      param: this.selectedSortParam,
      type: this.selectedSortValue
    });
  }

  clearSearch(sort?: boolean) {
    if (sort) {
      this.selectedSortParam = null;
      this.selectedSortValue = null;
      this.sortChange.emit({
        param: this.selectedSortParam,
        type: this.selectedSortValue
      });
    } else {
      this.selectedSearchParam = null;
      this.selectedSearchValue = null;
      this.searchParam();
    }
  }

  paramChange(sort?: boolean) {
    if (sort) {
      this.selectedSortValue = null;
      this.isNumber(this.selectedSortParam);
    } else {
      this.selectedSearchValue = null;
    }
  }

  btnClick() {
    if (this.usingSearch &&
      ((this.selectedSearchParam && this.selectedSearchValue) || (this.selectedSortParam && this.selectedSortValue))) {
      this.searchParam();
    } else {
      this.sortData();
    }
  }

  isNumber(field: string) {
    this.isNumeric = !(field === 'coinSymbol' || field === 'status');
  }

  get useCoinsAsParam(): boolean {
    return (this.selectedSearchParam === 'coinSymbol' ||
      this.selectedSearchParam === 'to.coinSymbol' ||
    this.selectedSearchParam === 'from.coinSymbol');
  }

  get useStatusAsParam(): boolean {
    return (this.selectedSearchParam === 'status' || this.selectedSearchParam === 'invoiceStatus');
  }

  get useTypeOrMovementAsParam(): boolean {
    return (this.selectedSearchParam === 'type' || this.selectedSearchParam === 'movement');
  }

  get useDefaultCoinsOpts(): boolean {
    return !this.searchCoinsOpts || this.searchCoinsOpts.length <= 0;
  }

  get usingSearch(): boolean {
    return !!this.searchParamsOpts && this.searchParamsOpts.length > 0;
  }

  get coinConstants() {
    return CoinConstants;
  }
}
