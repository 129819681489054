import {Injectable} from '@angular/core';
import {ModuleActions} from '../../domain/Response/Modules.entity';
import {RolModules} from '../../domain/Response/RolModules.entity';
import {BehaviorSubject, retry} from 'rxjs';
import {map} from 'rxjs/operators';

interface Filters {
  [index: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public company$: BehaviorSubject<string>;

  constructor() {
    this.company$ = new BehaviorSubject<string>('');
  }

  LANG_ISO_CODE = 'LANG_ISO_CODE';
  USER_TOKEN = 'USER_TOKEN';
  COMPANY_ID = 'COMPANY_ID';
  SELECTED_COMPANY_NAME = 'SELECTED_COMPANY_NAME';
  SELECTED_COMPANY_ID = 'SELECTED_COMPANY_ID';
  DATA_MODULES = 'DATA_MODULES';
  DATA_ROLES = 'DATA_ROLES';
  DATA_AUTH = 'DATA_AUTH';
  DATA_CLIENT = 'DATA_CLIENT';
  TABLE_FILTERS = 'TABLE_FILTERS';
  REPORT_SELECTED_COMPANY_ID = 'REPORT_SELECTED_COMPANY';
  ADMIN_ROOT_COMPANY_ID = 'ADMIN_ROOT_COMPANY';

  setLangIsoCode(langCode: string) {
    localStorage.setItem(this.LANG_ISO_CODE, langCode);
  }

  getLangIsoCode(): string {
    return localStorage.getItem(this.LANG_ISO_CODE);
  }

  setToken(token: string) {
    localStorage.setItem(this.USER_TOKEN, token);
  }

  getUserToken() {
    return localStorage.getItem(this.USER_TOKEN);
  }

  setCompanyID(companyID: string) {
    localStorage.setItem(this.COMPANY_ID, companyID);
  }

  getCompanyID() {
    return localStorage.getItem(this.COMPANY_ID);
  }

  setSelectedCompanyName(companyName: string) {
    localStorage.setItem(this.SELECTED_COMPANY_NAME, companyName);
  }

  getSelectedCompanyName() {
    return localStorage.getItem(this.SELECTED_COMPANY_NAME);
  }

  clearSelectedCompanyName() {
    localStorage.removeItem(this.SELECTED_COMPANY_NAME);
  }

  setSelectedCompanyId(companyId: string) {
    localStorage.setItem(this.SELECTED_COMPANY_ID, companyId);
  }

  getSelectedCompanyId() {
    return localStorage.getItem(this.SELECTED_COMPANY_ID);
  }

  clearSelectedCompanyId() {
    localStorage.removeItem(this.SELECTED_COMPANY_ID);
  }

  setDataModule(data) {
    const dataModules = JSON.stringify(data);
    localStorage.setItem(this.DATA_MODULES, dataModules);
  }

  setDataRole(data) {
    const dataRoles = JSON.stringify(data);
    localStorage.setItem(this.DATA_ROLES, dataRoles);
  }

  setDataClient(data) {
    const dataClient = JSON.stringify(data);
    localStorage.setItem(this.DATA_CLIENT, dataClient);
  }

  setDataWithdrawalAuth(data) {
    const dataAuth = JSON.stringify(data);
    localStorage.setItem(this.DATA_AUTH, dataAuth);
  }

  setAdminRootCompanyId(id: string): void {
    localStorage.setItem(this.ADMIN_ROOT_COMPANY_ID, id);
  }

  getAdminRootCompanyId(): string {
    return localStorage.getItem(this.ADMIN_ROOT_COMPANY_ID);
  }

  getDataModule(): Array<ModuleActions> {
    return JSON.parse(localStorage.getItem(this.DATA_MODULES));
  }

  getDataRole(): RolModules {
    return JSON.parse(localStorage.getItem(this.DATA_ROLES)) as RolModules;
  }

  getDataClient() {
    return localStorage.getItem(this.DATA_CLIENT);
  }

  getDataWithdrawalAuth() {
    return localStorage.getItem(this.DATA_AUTH);
  }

  clearDataWithdrawalAuth() {
    localStorage.removeItem(this.DATA_AUTH);
  }

  logOut() {
    localStorage.clear();
  }

  setTableFilters(module, filterType, filterValue) {
    const filters: Filters = JSON.parse(this.getTableFilters());
    let obj;
    if (filters) {
      if (filters[module]) {
        obj = {
          ...filters,
          [module]: {
            ...filters[module],
            [filterType]: filterValue
          }
        };
      } else {
        obj = {
          ...filters,
          [module]: {
            [filterType]: filterValue
          }
        };
      }
    } else {
      obj = {
        [module]: {
          [filterType]: [filterValue]
        }
      };
    }
    const data = JSON.stringify(obj);
    sessionStorage.setItem(this.TABLE_FILTERS, data);
  }

  getTableFilters() {
    return sessionStorage.getItem(this.TABLE_FILTERS);
  }

  clearTableFilters(module?: string, filterType?: string, invert?: boolean) {
    const tableFilters = this.getTableFilters();
    if (tableFilters) {
      const filters = JSON.parse(tableFilters);
      if (filters) {
        if (module && filterType) {
          delete filters[module][filterType];
          const data = JSON.stringify(filters);
          sessionStorage.setItem(this.TABLE_FILTERS, data);
        } else if (module && !invert) {
          delete filters[module];
          const data = JSON.stringify(filters);
          sessionStorage.setItem(this.TABLE_FILTERS, data);
        } else if (module && invert) {
          Object.keys(filters).forEach(itm => {
            if (itm !== module) {
              delete filters[itm];
            }
          });
          const data = JSON.stringify(filters);
          sessionStorage.setItem(this.TABLE_FILTERS, data);
        } else {
          sessionStorage.removeItem(this.TABLE_FILTERS);
        }
      }
    } else {
      sessionStorage.removeItem(this.TABLE_FILTERS);
    }
  }

  setReportSelectedCompany(selectedCompany: string) {
    localStorage.setItem(this.REPORT_SELECTED_COMPANY_ID, selectedCompany);
  }

  getReportSelectedCompany() {
    return localStorage.getItem(this.REPORT_SELECTED_COMPANY_ID);
  }

  clearReportSelectedCompany() {
    localStorage.removeItem(this.REPORT_SELECTED_COMPANY_ID);
  }
}
