<div class="card">
  <div class="card__wrapper content">
    <span *ngIf="!loading" class="content__title">{{ coinParsedName }}</span>
    <span *ngIf="!loading" class="content__balance">{{ currencyUtils.formatCurrency(coin.balance, coin.symbol) }}</span>
    <span *ngIf="!loading" class="content__balance content__balance--usd"><i>≈</i> {{ coin.usdValue ? currencyUtils.formatCurrency(coin.usdValue) : 0 }} USD </span>
    <div *ngIf="loading" class="content__title content__title--loading"></div>
    <div *ngIf="loading" class="content__balance content__balance--loading"></div>
    <div *ngIf="loading" class="content__balance content__balance--loading"></div>
  </div>
  <img *ngIf="!loading" class="card__img" [ngSrc]="coin.iconPath" disableOptimizedSrcset [width]="50" [height]="50" alt="coin_path"/>
  <div *ngIf="loading" class="card__img card__img--loading"></div>
</div>
