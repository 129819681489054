import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {SelectCoinModalComponent} from '../select-coin/select-coin-modal.component';


export interface SelectedCoin {
  img: string;
  name: string;
  isSelected?: boolean;
  network: string;
  coinSymbol: string;
}

@Component({
  selector: 'app-select-coin-attach-file',
  templateUrl: './select-coin-attach-file.component.html',
  styleUrls: ['./select-coin-attach-file.component.scss']
})
export class SelectCoinAttachFileComponent implements OnInit {

  @Input() sharedBtnId: string;
  @Input() matDialog: MatDialog;
  @Output() fileEmitter = new EventEmitter<{ file: File, selectedCoin: SelectedCoin }>();
  @ViewChild('fileUpload') fileUpload: ElementRef;

  fileName;
  file: File;
  selectedCoin: SelectedCoin;


  constructor() {
  }

  ngOnInit(): void {
    this.file = null;
    this.fileName = null;
  }

  onFileSelected(event) {
    this.file = event.target.files[0];
    if (this.file) {
      this.fileName = this.file.name;
      const response = {file: this.file, selectedCoin: this.selectedCoin};
      this.fileEmitter.emit(response);
    }
  }

  public openSelectCoinModal() {
    const matDialogConfig = new MatDialogConfig();
    // matDialogConfig.data = this.billList;

    let dialogModal: MatDialogRef<SelectCoinModalComponent>;
    dialogModal = this.matDialog.open(SelectCoinModalComponent, matDialogConfig);
    return dialogModal.afterClosed()
      .subscribe(async selectedCoin => {
        if (selectedCoin) {
          // this.snackBarService.openSuccessToast('BILL.GENERATE_BILL.BILL_SUCCESS');
          console.log(selectedCoin);
          this.selectedCoin = selectedCoin;
          this.fileUpload.nativeElement.click();
        }
      });
  }

}
