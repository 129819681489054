import * as HomeModulesActions from './menu-store-actions';
import {Action, createReducer, on} from '@ngrx/store';
import {Module} from '../../../../shared/domain/Response/Modules.entity';
import {BaseListResponse} from '../../../../shared/domain/Response/BaseListResponse.entity';
import {Alerts} from '../../domain/alerts';
import {RolModules} from '../../../../shared/domain/Response/RolModules.entity';

export interface HomeModulesState {
  homeModules: Module;
  roles: RolModules;
  alerts: BaseListResponse<Alerts>;
  modulesError: string;
}

const initialHomeModulesState: HomeModulesState = {
  homeModules: null,
  roles: null,
  alerts: null,
  modulesError: null
};

const reducer = createReducer(initialHomeModulesState,
  on(HomeModulesActions.addHomeModules, (state, payload) =>
    ({
      ...state,
      homeModules: payload.homeModules,
      roles: state.roles,
      alerts: state.alerts,
      modulesError: state.modulesError
    })),
  on(HomeModulesActions.addRoles, (state, payload) =>
    ({
      ...state,
      homeModules: state.homeModules,
      roles: payload.roles,
      alerts: state.alerts,
      modulesError: state.modulesError
    })),
  on(HomeModulesActions.addAlerts, (state, payload) =>
    ({
      ...state,
      homeModules: state.homeModules,
      roles: state.roles,
      alerts: payload.alerts,
      modulesError: state.modulesError
    })),
  on(HomeModulesActions.addModulesError, (state, payload) =>
    ({
      ...state,
      homeModules: state.homeModules,
      roles: state.roles,
      alerts: state.alerts,
      modulesError: payload.modulesError
    })),
  on(HomeModulesActions.clearHomeModulesStore, () => initialHomeModulesState)
);

export function HomeModulesReducer(state: HomeModulesState, action: Action) {
  return reducer(state, action);
}

