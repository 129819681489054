import {SwapReportsList} from '../../domain/swap-reports-list/swap-report.entity';
import {Action, createReducer, on} from '@ngrx/store';
import {addSwapReportsError, addSwapReports, clearReports} from './swap-reports-store.actions';


export interface SwapReportsState {
  swapReports: SwapReportsList;
  error: string;
}

const initialSwapReportsState: SwapReportsState = {
  swapReports: null,
  error: null
};

const reducer = createReducer(
  initialSwapReportsState,
  on(addSwapReports, (state, payload) =>
    ({
      ...state,
      swapReports: payload.swapReports,
      error: state.error
    })),
  on(addSwapReportsError, (state, payload) =>
    ({
      ...state,
      swapReports: state.swapReports,
      error: payload.error
    })),
  on(clearReports, () => initialSwapReportsState)
);

export function SwapReportsReducer(state: SwapReportsState, action: Action) {
  return reducer(state, action);
}
