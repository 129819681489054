import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {HttpResponse} from './HttpResponse';
import {DateUtils} from '../../domain/Utils/DateUtils';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpClientBase {

  serverApi = environment.urlApi;

  constructor(private httpClient: HttpClient, protected dateUtils: DateUtils) {
  }

  public post(resource: string, body: any): Observable<any> {
    return this.httpClient.post(resource, body)
      .pipe(
        catchError((err: HttpErrorResponse) => throwError(err)),
        map((response: HttpResponse<any>) => response)
      );
  }

  public patch(resource: string, body: any): Observable<any> {
    return this.httpClient.patch(resource, body)
      .pipe(
        catchError((err: HttpErrorResponse) => throwError(err)),
        map((response: HttpResponse<any>) => response)
      );
  }

  public postWithHeader(resource: string, body: any, headers: any): Observable<any> {
    return this.httpClient.post(resource, body, {headers})
      .pipe(
        catchError((err: HttpErrorResponse) => throwError(err)),
        map((response: HttpResponse<any>) => response)
      );
  }

  public get(resource: string, data?: any): Observable<any> {
    return data ? this.httpClient.get(`${resource}/${data}`) : this.httpClient.get(`${resource}`)
      .pipe(
        catchError((err: HttpErrorResponse) => throwError(err)),
        map((response: HttpResponse<any>) => response)
      );
  }

  public getWithParameters(resource: string, params: any, headers?: any): Observable<any> {
    return this.httpClient.get(`${resource}`, {params, headers})
      .pipe(
        catchError((err: HttpErrorResponse) => throwError(err)),
        map((response: HttpResponse<any>) => response),
      );
  }

  public put(resource: string, data: any): Observable<any> {
    return this.httpClient.put(`${resource}`, data)
      .pipe(
        catchError((err: HttpErrorResponse) => throwError(err)),
        map((response: HttpResponse<any>) => response)
      );
  }

  public putFormData(resource: string, formData: FormData): Observable<any> {
    return this.httpClient.put(`${resource}`, formData)
      .pipe(
        catchError((err: HttpErrorResponse) => throwError(err)),
        map((response: HttpResponse<any>) => response)
      );
  }

  public delete(resource: string, body: any): Observable<any> {
    return this.httpClient.delete(`${resource}`, {body})
      .pipe(
        catchError((err: HttpErrorResponse) => throwError(err)),
        map((response: HttpResponse<any>) => response)
      );
  }

  public deleteWithParameters(resource: string, params: any): Observable<any> {
    return this.httpClient.delete(`${resource}`, {params})
      .pipe(
        catchError((err: HttpErrorResponse) => throwError(err)),
        map((response: HttpResponse<any>) => response)
      );
  }

  public getDownloadFile(resource: string, params: any, headers?: any) {
    return this.httpClient.get(`${resource}`, {responseType: 'blob', params, headers});
  }

  public getDownloadFileWithParams(resource: string, params1: string, params2: string) {
    return this.httpClient.get(`${resource}/${params1}/${params2}`, {responseType: 'blob'});
  }
}
