import {LoginUserEmail} from './LoginUserEmail';
import {LoginUserPassword} from './LoginUserPassword';

export class LoginUser {

  readonly username: LoginUserEmail;
  readonly password: LoginUserPassword;

  constructor(username: LoginUserEmail, password: LoginUserPassword) {
    this.username = username;
    this.password = password;
  }
}
