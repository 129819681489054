import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-alert-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  public description: string;

  constructor(private translateService: TranslateService,
              public dialogRef: MatDialogRef<ConfirmModalComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.description = this.translateService.instant(data);
  }

  ngOnInit(): void {
  }

  accept = () => this.dialogRef.close(true);
  cancel = () => this.dialogRef.close(false);
}
