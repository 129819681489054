import {LoginUserRepository} from '../Domain/login-user/LoginUserRepository';
import {LoginUser} from '../Domain/login-user/LoginUser';
import {LoginUserEmail} from '../Domain/login-user/LoginUserEmail';
import {LoginUserPassword} from '../Domain/login-user/LoginUserPassword';
import {LoginUserStore} from '../Domain/LoginUser.store';
import {Observable, Subscription} from 'rxjs';
import {BaseClient} from '../../../shared/domain/Response/BaseClient.entity';

export class LoginUserApplication {
  private repository: LoginUserRepository;
  private facadeLoginUserStore: LoginUserStore;
  private subscriptionLogin$: Subscription;

  constructor(repository: LoginUserRepository, facadeLoginUserStore: LoginUserStore) {
    this.repository = repository;
    this.facadeLoginUserStore = facadeLoginUserStore;
  }

  destroySubscriptionLogin(): void {
    if (this.subscriptionLogin$) {
      this.subscriptionLogin$.unsubscribe();
    }
  }

  get token$(): Observable<string> {
    return this.facadeLoginUserStore.token$();
  }

  get error$(): Observable<string> {
    return this.facadeLoginUserStore.error$();
  }

  get userSessionInfo$(): Observable<BaseClient> {
    return this.facadeLoginUserStore.userSessionInfo$();
  }

  userLogOut() {
    this.facadeLoginUserStore.clearLogin();
  }

  async login(username: string, password: string): Promise<void> {
    const login = new LoginUser(new LoginUserEmail(username), new LoginUserPassword(password));
    this.subscriptionLogin$ = await this.repository.login(login)
      .subscribe(
        (accessToken) => this.facadeLoginUserStore.successfulLogin(accessToken),
        (error) => this.facadeLoginUserStore.errorLogin(error)
      );
  }

  getUserSessionInfo(): void {
    this.subscriptionLogin$ = this.repository.getUserSessionInfo()
      .subscribe(
        (userSessionInfo) => this.facadeLoginUserStore.successfulUserSessionInfo(userSessionInfo),
        (error) => this.facadeLoginUserStore.errorLogin(error)
      );
  }
}
