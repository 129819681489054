import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RouterConstants} from '../shared/Infrastructure/router.constants';
import {PageNotFoundComponent} from '../shared/ui/components/page-not-found/page-not-found.component';
import {HomeMainComponent} from './home/ui/home-main/home-main.component';
import {USER_ADMIN, USER_LOGGED_GUARD_CHILD, USER_LOGGED_GUARD_DEACTIVATE} from './core/auth.guard';
import {SecondFactorAuthComponent} from './admin/clients/ui/second-factor-auth/second-factor-auth.component';

const sharedRoutes: Routes = [
  {
    path: RouterConstants.SECOND_FACTOR_AUTH,
    component: SecondFactorAuthComponent
  },
  {
    path: RouterConstants.WITHDRAWALS,
    loadChildren: () => import('./withdrawals/withdrawals.module').then(m => m.WithdrawalsModule)
  },
  {
    path: RouterConstants.TRADER_INFORMATION,
    loadChildren: () => import('./trader-information/trader-information.module').then(m => m.TraderInformationModule)
  },
  {
    path: RouterConstants.TRANSACTIONS,
    loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsModule)
  },
  {
    path: RouterConstants.BILLS,
    loadChildren: () => import('./bills/bills.module').then(m => m.BillsModule)
  },
  {
    path: RouterConstants.WALLETS,
    loadChildren: () => import('./wallets/wallets.module').then(m => m.WalletsModule)
  },
  {
    path: RouterConstants.BILLING,
    loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule)
  },
  {
    path: RouterConstants.DEPOSITS_LIST,
    loadChildren: () => import('./deposits/deposits.module').then(m => m.DepositsModule)
  },
  {
    path: RouterConstants.API_KEY,
    loadChildren: () => import('../app/token/token.module').then(m => m.TokenModule)
  },
  {
    path: RouterConstants.OTC_LIST,
    loadChildren: () => import('./otc/otc.module').then(m => m.OtcModule)
  }
];

const routes: Routes = [
  {
    path: RouterConstants.LOGIN,
    canDeactivate: [USER_LOGGED_GUARD_DEACTIVATE],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    component: HomeMainComponent,
    canActivate: [USER_ADMIN],
    canActivateChild: [USER_LOGGED_GUARD_CHILD],
    children: [
      ...sharedRoutes,
      {
        path: '',
        redirectTo: RouterConstants.DASHBOARD,
        pathMatch: 'full'
      },
      {
        path: RouterConstants.DASHBOARD,
        loadChildren: () => import('./admin/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: RouterConstants.PROFILE,
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
      }
    ]
  },
  {
    path: RouterConstants.ADMIN,
    component: HomeMainComponent,
    canActivate: [USER_ADMIN],
    canActivateChild: [USER_LOGGED_GUARD_CHILD],
    children: [
      ...sharedRoutes,
      {
        path: '',
        redirectTo: RouterConstants.CLIENTS_LIST,
        pathMatch: 'full'
      },
      {
        path: RouterConstants.NOTIFICATIONS_LIST,
        loadChildren: () => import('./admin/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: RouterConstants.CLIENTS_LIST,
        loadChildren: () => import('./admin/clients/clients.module').then(m => m.ClientsModule)
      },
      {
        path: RouterConstants.EARNINGS,
        loadChildren: () => import('./admin/earn/earn.module').then(m => m.EarnModule)
      },
      {
        path: RouterConstants.REPORTS,
        loadChildren: () => import('./admin/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: RouterConstants.SALES,
        loadChildren: () => import('./admin/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: RouterConstants.FUTCARD,
        loadChildren: () => import('./admin/futcard/futcard.module').then(m => m.FutcardModule)
      },
      {
        path: RouterConstants.SWAP,
        loadChildren: () => import('./admin/swap/swap.module').then(m => m.SwapModule)
      },
      {
        path: RouterConstants.RECHARGE_REQUEST,
        loadChildren: () => import('./admin/recharge-request/recharge-request.module').then(m => m.RechargeRequestModule)
      },
      {
        path: RouterConstants.CARD_REQUEST,
        loadChildren: () => import('./admin/card-request/card-request.module').then(m => m.CardRequestModule)
      },
      {
        path: RouterConstants.SWAP_REPORT,
        loadChildren: () => import('./admin/swap-reports/swap-reports.module').then(m => m.SwapReportsModule)
      },
      {
        path: RouterConstants.USERS,
        loadChildren: () => import('./admin/users/users.module').then(m => m.UsersModule)
      },
      {
        path: RouterConstants.ROLES,
        loadChildren: () => import('./admin/roles/roles.module').then(m => m.RolesModule)
      }
    ]
  },
  {
    path: RouterConstants.BILL,
    loadChildren: () => import('./bill/bill.module').then(m => m.BillModule)
  },
  {
    path: RouterConstants.WITHDRAWAL_AUTHORIZATION,
    loadChildren: () => import('./withdrawal-authorization/withdrawal-authorization.module').then(m => m.WithdrawalAuthorizationModule),
  },
  {
    path: RouterConstants.NOT_FOUND,
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
