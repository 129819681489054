import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {RouterConstants} from '../../../Infrastructure/router.constants';
import {RolesConstants} from '../../../domain/constants/roles.constants';
import {StorageService} from '../../../Infrastructure/Services/storage.service';
import {RolModules} from '../../../domain/Response/RolModules.entity';

@Component({
  selector: 'app-back-link',
  templateUrl: './back-link.component.html',
  styleUrls: ['./back-link.component.scss']
})
export class BackLinkComponent implements OnInit {

  public roles: RolModules;
  public role: string;
  public roleAdmin: boolean;

  constructor(public location: Location,
              public router: Router,
              private storageService: StorageService) {
    this.roles = this.storageService.getDataRole();
    this.role = this.roles?.name;
    this.roleAdmin = !(this.role === RolesConstants.ACCOUNT_MANAGER ||
      this.role === RolesConstants.SUPPORT || this.role === RolesConstants.ADMIN);
  }

  ngOnInit(): void {
  }

  backButton() {
    const urlFragments: string[] = this.router.url.split('/');
    if (this.roleAdmin) {
      urlFragments.length === 2 ? this.router.navigate([RouterConstants.TRADER_INFORMATION]) : this.location.back();
    } else {
      urlFragments.length === 2 ? this.router.navigate([RouterConstants.DASHBOARD]) : this.location.back();
    }
  }

}
