import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {HttpClientBase} from '../../../../shared/Infrastructure/Http/HttpClientBase';
import {HomeModulesRepository} from '../../domain/home-modules.repository';
import {HttpParams} from '@angular/common/http';
import {Company} from '../../../../shared/domain/Response/Company.entity';
import {BaseListResponse} from '../../../../shared/domain/Response/BaseListResponse.entity';
import {Alerts} from '../../domain/alerts';
import {Module} from '../../../../shared/domain/Response/Modules.entity';
import {RolModules} from '../../../../shared/domain/Response/RolModules.entity';

@Injectable({providedIn: 'root'})
export class VerticalMenuServices extends HttpClientBase implements HomeModulesRepository {

  serverApi = environment.urlApi;

  getAllHomeModules(): Observable<Module> {
    const resource = 'module/all';
    return this.get(`${this.serverApi}/${resource}`)
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  getRoleAccess(): Observable<RolModules> {
    const resource = 'role/access';
    return this.get(`${this.serverApi}/${resource}`)
      .pipe(
        map(response => {
          if (response.success) {
            return response.data;
          }
        }),
        catchError(err => throwError(err))
      );
  }

  getCompanyFinder(value: string, limit: number): Observable<Array<Company>> {
    const resource = 'company/finder';
    let params;
    params = new HttpParams()
      .set('value', value.toString())
      .set('limit', limit.toString());

    return this.getWithParameters(`${this.serverApi}/${resource}`, params)
      .pipe(
        map(response => {
          if (response.success) {
            return response.data;
          }
        }),
        catchError(err => throwError(err))
      );
  }

  getAlerts(page: string, limit: string): Observable<BaseListResponse<Alerts>> {
    const resource = 'alert';
    let params;
    params = new HttpParams()
      .set('page', page)
      .set('limit', limit);

    return this.getWithParameters(`${this.serverApi}/${resource}`, params)
      .pipe(
        map(response => {
          if (response.success) {
            return response;
          }
        }),
        catchError(err => throwError(err))
      );
  }

}
