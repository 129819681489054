import {StorageService} from '../../Infrastructure/Services/storage.service';
import {DateUtils} from './DateUtils';
import {DatePipe} from '@angular/common';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class FiltersUtils {

  private currentDate: any;
  private filters: any;

  constructor(
    private datePipe: DatePipe,
    private storageService: StorageService
  ) {
  }

  setFilterModule(actualModule: string) {
    this.filters = JSON.parse(this.storageService?.getTableFilters())?.[actualModule];
    this.currentDate = DateUtils.getCurrentDate();
  }

  getFilters(actualModule: string) {
    this.setFilterModule(actualModule);
    const startDate = this.filters?.dateRange ? this.filters?.dateRange.startDate : this.currentDate.startDate;
    const endDate = this.filters?.dateRange ? this.filters?.dateRange.finalDate : this.currentDate.endDate;
    const paramSearch = this.filters?.search ? this.filters?.search.type : null;
    const paramValue = this.filters?.search ? this.filters?.search.value : null;
    const sortParam = this.filters?.sort ? this.filters?.sort.param : null;
    const sortType = this.filters?.sort ? this.filters?.sort.type : null;
    const userSearch = this.filters?.userSearch ?? null;
    const selectedCoin = this.filters?.selectedCoin ?? null;
    const page = this.filters?.page ?? 1;
    return {
      startDate,
      endDate,
      page,
      paramSearch,
      paramValue,
      sortParam,
      sortType,
      userSearch,
      selectedCoin
    };
  }
}
