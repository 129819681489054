import {HomeModulesRepository} from '../domain/home-modules.repository';
import {HomeModulesStore} from '../domain/home-modules.store';
import {Observable} from 'rxjs';
import {Module} from '../../../shared/domain/Response/Modules.entity';
import {BaseListResponse} from '../../../shared/domain/Response/BaseListResponse.entity';
import {Alerts} from '../domain/alerts';
import {RolModules} from '../../../shared/domain/Response/RolModules.entity';

export class HomeModulesApplication {

  constructor(private homeModulesRepository: HomeModulesRepository,
              private homeModulesStore: HomeModulesStore) {
  }

  get homeModules$(): Observable<Module> {
    return this.homeModulesStore.homeModules$();
  }

  get roles$(): Observable<RolModules> {
    return this.homeModulesStore.roles$();
  }

  get alerts$(): Observable<BaseListResponse<Alerts>> {
    return this.homeModulesStore.alerts$();
  }

  get modulesError$(): Observable<string> {
    return this.homeModulesStore.modulesError$();
  }

  getAllHomeModules() {
    this.homeModulesRepository.getAllHomeModules()
      .subscribe(
        (modules) => this.homeModulesStore.successHomeModules(modules),
        (error) => this.homeModulesStore.errorHomeModules(error)
      );
  }

  getRoleAccess() {
    this.homeModulesRepository.getRoleAccess()
      .subscribe(
        (roles) => this.homeModulesStore.successRolesAccess(roles),
        (error) => this.homeModulesStore.errorHomeModules(error)
      );
  }

  getAlerts(page: string, limit: string) {
    this.homeModulesRepository.getAlerts(page, limit)
      .subscribe(
        (response) => this.homeModulesStore.successAlerts(response),
        (error) => this.homeModulesStore.errorHomeModules(error)
      );
  }
}
