import {createAction, props} from '@ngrx/store';
import {BaseClient} from '../../../../../shared/domain/Response/BaseClient.entity';

export const addToken = createAction('[Login User] add Token', props<{ token: string }>());
export const loginFailure = createAction('[Login User] login failure', props<{ error: string }>());
export const clearLogin = createAction('[Login User] clear auth');
export const addUserSessionInfo = createAction('[Login User] Add user session info', props<{
  sessionInfo: BaseClient
}>());


