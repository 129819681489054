import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {PaginatorComponent} from './ui/components/paginator/paginator.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LogoutModalComponent} from './ui/modals/logout-modal/logout-modal.component';
import {PageNotFoundComponent} from './ui/components/page-not-found/page-not-found.component';
import {WithoutResultsComponent} from './ui/components/without-results/without-results.component';
import {BackLinkComponent} from './ui/components/back-link/back-link.component';
import {TabsComponent} from './ui/components/tabs/tabs.component';
import {MaterialModule} from '../app/core/material/material.module';
import {GenerateBillModalComponent} from '../app/bills/ui/generate-bill-modal/generate-bill-modal.component';
import {DatePickerComponent} from './ui/components/date-picker/date-picker.component';
import {SubstringPipe} from './domain/pipe/substring.pipe';
import {SubstringLongPipe} from './domain/pipe/substring-long.pipe';
import {MomentDateModule} from '@angular/material-moment-adapter';
import {MAT_DATE_FORMATS, MatRippleModule} from '@angular/material/core';
import {ChangePasswordModalComponent} from './ui/modals/change-password-modal/change-password-modal.component';
import {
  GenerateNotificationModalComponent
} from '../app/admin/notifications/ui/generate-notification-modal/generate-notification-modal.component';
import {LoginRedirectionComponent} from './ui/modals/login-redirection/login-redirection.component';
import {NotificationSubstringPipe} from './domain/pipe/notification-substring.pipe';
import {ThousandPipe} from './domain/pipe/thousand.pipe';
import {SelectCoinModalComponent} from './ui/components/select-coin/select-coin-modal.component';
import {SelectCoinAttachFileComponent} from './ui/components/select-coin-attach-file/select-coin-attach-file.component';
import {ListCardsComponent} from './ui/components/list-cards/list-cards.component';
import {ListTableComponent} from './ui/components/list-table/list-table.component';
import {ConfirmModalComponent} from './ui/modals/confirm-modal/confirm-modal.component';
import {FixedNoRoundPipe} from './domain/pipe/fixed-no-round.pipe';
import {Auth2faModalComponent} from './ui/modals/auth-2fa-modal/auth-2fa-modal.component';
import {SwiperDirective} from './directives/swiper.directive';
import {SearchParamComponent} from './ui/components/search-param/search-param.component';
import {ListModulesHeaderComponent} from './ui/components/list-modules-header/list-modules-header.component';
import {BusyIndicatorDirective} from './directives/busy-indicator.directive';
import {primitiveTokens} from './domain/DI_Token/for-primitives.token';
import {FtswpInputComponent} from './ui/components/ftswp-input/ftswp-input.component';
import {FtswpToggleComponent} from './ui/components/ftswp-toggle/ftswp-toggle.component';
import {SubstringParamPipe} from './domain/pipe/substring-param';
import {CoinCardComponent} from './ui/components/coin-card/coin-card.component';
import {UserSearchInputComponent} from './ui/components/user-search-input/user-search-input.component';
import {CdkMenu, CdkMenuItem, CdkMenuItemCheckbox, CdkMenuTrigger} from '@angular/cdk/menu';
import {LoaderComponent} from './ui/modals/loader/loader.component';


@NgModule({
  declarations: [
    PaginatorComponent,
    LogoutModalComponent,
    PageNotFoundComponent,
    WithoutResultsComponent,
    BackLinkComponent,
    TabsComponent,
    GenerateBillModalComponent,
    GenerateNotificationModalComponent,
    DatePickerComponent,
    SubstringPipe,
    SubstringLongPipe,
    SubstringParamPipe,
    NotificationSubstringPipe,
    ThousandPipe,
    ChangePasswordModalComponent,
    LoginRedirectionComponent,
    SelectCoinModalComponent,
    SelectCoinAttachFileComponent,
    ListCardsComponent,
    ListTableComponent,
    ConfirmModalComponent,
    FixedNoRoundPipe,
    Auth2faModalComponent,
    SwiperDirective,
    SearchParamComponent,
    ListModulesHeaderComponent,
    BusyIndicatorDirective,
    FtswpInputComponent,
    FtswpToggleComponent,
    CoinCardComponent,
    UserSearchInputComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MomentDateModule,
    MatRippleModule,
    NgOptimizedImage,
    CdkMenuTrigger,
    CdkMenu,
    CdkMenuItem,
    CdkMenuItemCheckbox
  ],
  exports: [
    PaginatorComponent,
    PageNotFoundComponent,
    WithoutResultsComponent,
    BackLinkComponent,
    TabsComponent,
    LogoutModalComponent,
    GenerateBillModalComponent,
    GenerateNotificationModalComponent,
    DatePickerComponent,
    SubstringPipe,
    SubstringLongPipe,
    SubstringParamPipe,
    NotificationSubstringPipe,
    SelectCoinModalComponent,
    SelectCoinAttachFileComponent,
    ListTableComponent,
    ListCardsComponent,
    ConfirmModalComponent,
    NotificationSubstringPipe,
    ThousandPipe,
    FixedNoRoundPipe,
    Auth2faModalComponent,
    SwiperDirective,
    SearchParamComponent,
    BusyIndicatorDirective,
    ListModulesHeaderComponent,
    NgOptimizedImage,
    FtswpInputComponent,
    FtswpToggleComponent,
    CoinCardComponent,
    LoaderComponent
  ],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        display: {
          dateInput: 'DD/MM/YY',
          monthYearLabel: 'YYYY'
        }
      }
    },
    ...primitiveTokens
  ]
})
export class SharedModule {
}
