import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'substringLong'
})
export class SubstringLongPipe implements PipeTransform {

  transform(value: string): any {
    return value?.substr(0, 40) + '...';
  }

}
