import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TraderInformationServices} from '../../infrastructure/services/trader-information.services';
import {FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {SnackBarService} from '../../../../shared/Infrastructure/Services/snack-bar.service';


@Component({
  selector: 'app-auth-factors-modal',
  templateUrl: './auth-factors-modal.component.html',
  styleUrls: ['./auth-factors-modal.component.scss']
})
export class AuthFactorsModalComponent implements OnInit {

  public qrInfo: string;
  public base32S: string;
  public geneQr: boolean;

  public secondFvForm: FormGroup;

  public emailSent: boolean;

  constructor(private translateService: TranslateService,
              private formBuilder: FormBuilder,
              private tradeInformationService: TraderInformationServices,
              public dialogRef: MatDialogRef<AuthFactorsModalComponent>,
              private matDialog: MatDialog,
              private snackBarService: SnackBarService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.geneQr = false;
    const validators: ValidatorFn[] = [Validators.required, Validators.pattern(/[0-9]{6}/)];
    this.secondFvForm = this.formBuilder.group({
      twoFactorAuthenticationCode: new FormControl<string>(null, Validators.compose(validators)),
      emailCode: new FormControl<string>(null, Validators.compose(validators))
    });
  }

  showError = (field: string): boolean => this.secondFvForm.controls[field].invalid && this.secondFvForm.controls[field].dirty;
  getErrorMessage(field: string) {
    return this.secondFvForm.controls[field]?.hasError('required') ? 'FORM_ERRORS.FIELD_REQUIRED' :
      this.secondFvForm.controls[field]?.hasError('pattern') ? 'FORM_ERRORS.INVALID_FORMAT' :
        '';
  }
  activate2FA(event: Event) {
    event.preventDefault();
    const {twoFactorAuthenticationCode, emailCode} = this.secondFvForm.value;
    if (this.secondFvForm.valid) {
      this.secondFvForm.disable();
      this.tradeInformationService.activate2FA(String(twoFactorAuthenticationCode), String(emailCode))
        .subscribe(
          (res) => {
            if (res) {
              this.accept();
              this.snackBarService.openSuccessToast(this.translateService.instant('AUTH_FACTORS.SUCCESS'));
              this.dialogRef.close(true);
            } else {
              this.snackBarService.openErrorToast(this.translateService.instant('AUTH_FACTORS.ERROR'));
              this.secondFvForm.enable();
              this.emailSent = false;
            }
          },
          (err) => {
            this.snackBarService.openErrorToast(this.translateService.instant(err ? 'AUTH_FACTORS.' + err : 'AUTH_FACTORS.ERROR'));
            this.secondFvForm.enable();
            this.emailSent = false;
          }
        );
    } else {
      switch (true) {
        case !emailCode:
          this.snackBarService.openErrorToast(this.translateService.instant('AUTH_FACTORS.OTP_CODE_REQUIRED'));
          break;
        case !twoFactorAuthenticationCode:
          this.snackBarService.openErrorToast(this.translateService.instant('AUTH_FACTORS.TWOFA_CODE_REQUIRED'));
          break;
        case String(emailCode).length !== 6:
          this.snackBarService.openErrorToast(this.translateService.instant('AUTH_FACTORS.INVALID_OTP_LENGTH'));
          break;
        case String(twoFactorAuthenticationCode).length !== 6:
          this.snackBarService.openErrorToast(this.translateService.instant('AUTH_FACTORS.INVALID_TWOFA_CODE_LENGTH'));
          break;
      }
      this.secondFvForm.markAllAsTouched();
    }
  }

  sendEmailOtp() {
    const langIsoCode = 'en';
    this.tradeInformationService.send2faOtp(langIsoCode)
      .subscribe((res) => {
        if (res) {
          this.emailSent = true;
          this.snackBarService.openSuccessToast(this.translateService.instant('WALLETS.PIN.SEND_EMAIL'));
        }
      }, () => {
        this.snackBarService.openErrorToast(this.translateService.instant('WALLETS.PIN.SEND_EMAIL_ERROR'));
      });
  }

  isNotQRCreate = () => !this.geneQr ? ' blur' : '';


  async generateQr() {
    this.geneQr = true;
    this.tradeInformationService.faGenerateQr().subscribe((value) => {
      this.qrInfo = value.url;
      this.base32S = value.base32Secret;
    });
  }

  accept = () => this.dialogRef.close(true);
  cancel = () => {
    this.dialogRef.close(false);
  }
}
