import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {LoginUser} from '../../Domain/login-user/LoginUser';
import {HttpClientBase} from '../../../../shared/Infrastructure/Http/HttpClientBase';
import {LoginUserRepository} from '../../Domain/login-user/LoginUserRepository';
import {BaseClient} from '../../../../shared/domain/Response/BaseClient.entity';
import {RolModules} from '../../../../shared/domain/Response/RolModules.entity';

@Injectable({providedIn: 'root'})
export class LoginUserService extends HttpClientBase implements LoginUserRepository {

  serverApi = environment.urlApi;

  login(login: LoginUser): Observable<string> {
    const resource = 'login';
    const body = {
      email: login.username.toString(),
      password: login.password.toString()
    };

    return this.post(`${this.serverApi}/${resource}`, body)
      .pipe(
        map(response => {
          return response.data.accessToken;
        }),
        catchError(err => throwError(err))
      );
  }

  getUserSessionInfo(): Observable<BaseClient> {
    const resource = 'me';
    return this.get(`${this.serverApi}`, resource)
      .pipe(
        map(response => response.success ? response.data : null),
        catchError(err => throwError(err))
      );
  }

  changePassword(currentPassword: string, newPassword: string): Observable<string> {
    const resource = 'user/update-password';
    const body = {
      password: currentPassword,
      newPassword
    };
    return this.put(`${this.serverApi}/${resource}`, body)
      .pipe(
        map(response => response)
      );
  }

  sendEmailToRecoverPassword(email: string): Observable<string> {
    const resource = 'user/send-recover-password-email';
    const body = {
      email
    };
    return this.post(`${this.serverApi}/${resource}`, body)
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  createNewPassword(token: string, newPassword: string): Observable<string> {
    const resource = 'user/recover-password';
    const body = {
      token,
      newPassword
    };
    return this.put(`${this.serverApi}/${resource}`, body)
      .pipe(
        map(response => response)
      );
  }

  getRoleAccess(): Observable<RolModules> {
    const resource = 'role/access';
    return this.get(`${this.serverApi}/${resource}`)
      .pipe(
        map(response => {
          if (response.success) {
            return response.data;
          }
        }),
        catchError(err => throwError(err))
      );
  }
}
