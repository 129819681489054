<div class="generate-bill-modal">
  <div class="generate-bill-modal__header">
    <img class="generate-bill-modal__header--title" ngSrc="../../../../assets/img/home-main/logo_dark.svg" priority width="425" height="104" disableOptimizedSrcset alt="logo">
  </div>
  <form [formGroup]="billForm" (ngSubmit)="createBill($event)">
    <div class="generate-bill-modal__main">

      <mat-form-field class="generate-bill-modal__main--field">
        <mat-label class="generate-bill-modal__main--field-name">{{'BILL.GENERATE_BILL.USD_VALUE' | translate}}
          <span class="generate-bill-modal__main--field-name-required">*</span>
        </mat-label>
        <input formControlName="usdValue" matInput type="text" id="usd-value"
               [placeholder]="'BILL.GENERATE_BILL.USD_VALUE' | translate">
        <mat-error *ngIf="isUsdValueInvalid()">{{'FORM_ERRORS.FIELD_REQUIRED' | translate}}</mat-error>
        <mat-error *ngIf="usdValue().hasError('pattern')">{{'FORM_ERRORS.INVALID_FORMAT' | translate}}</mat-error>
      </mat-form-field>

      <div class="generate-bill-modal__main-message-body">
        <label
          class="generate-bill-modal__main-message-body_complete">{{'BILL.GENERATE_BILL.MESSAGE_BODY' | translate}}</label>
      </div>
      <br>

      <label class="generate-bill-modal__main-select"> {{'BILL.GENERATE_BILL.SELECT_PAYMENT' | translate}}</label>
      <div class="generate-bill-modal__main-select--wrapper">
        <div class="generate-bill-modal__main--field" *ngFor="let item of bills; index as i">
          <mat-card (click)="select(item)"
                    [class.mat-elevation-z2]="!item.isSelected"
                    [class.mat-elevation-z8]="item.isSelected">
            <mat-card-content [class.style-border]="item.isSelected">
              <img [ngSrc]="item.img" disableOptimizedSrcset width="60" height="60" alt="coin"/>
              <label>{{item.name}}</label>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <mat-error *ngIf="isCoinSymbolInvalid">{{'FORM_ERRORS.FIELD_REQUIRED' | translate}}</mat-error>
    </div>

    <div class="generate-bill-modal__footer">
      <div class="generate-bill-modal__footer--button">
        <button mat-flat-button
                color="primary"
                [disabled]="btnDisabled"
                type="submit">
          {{'CONFIRM_MODAL.ACCEPT' | translate}}
        </button>
        <button mat-stroked-button
                class="generate-bill-modal__footer--button-cancel"
                color="primary"
                type="button"
                (click)="cancel()">
          {{'CONFIRM_MODAL.CANCEL' | translate}}
        </button>
      </div>
    </div>
  </form>
</div>
