export enum RouterConstants {
  LOGIN = '',
  FORGOT_PASSWORD = 'forgot-password',
  RECOVER_PASSWORD = 'recover-password',
  ADMIN = 'admin',
  CLIENTS_LIST = 'clients',
  DASHBOARD = 'dashboard',
  SECOND_FACTOR_AUTH = '2fa',
  CLIENT_DETAIL = 'client-detail',
  TRANSACTIONS = 'transactions',
  TRADER_INFORMATION = 'client',
  WITHDRAWALS = 'withdrawals',
  WITHDRAWAL_DETAIL = 'withdrawal-detail',
  WITHDRAWAL_AUTHORIZATION = 'withdrawal-authorization',
  TRANSACTION_DETAIL = 'transaction-detail',
  WALLETS = 'wallets',
  BILL = 'bill',
  BILLS = 'bills',
  BILL_DETAIL = 'bill-detail',
  SEND_MONEY = 'send-money',
  BILLING = 'billing',
  BILLING_LIST = 'billing-list',
  BILLING_DETAIL = 'billing-detail',
  CALCULATE_BILLING = 'calculate-billing',
  DEPOSITS_LIST = 'deposits',
  DEPOSIT_DETAIL = 'deposit-detail',
  DEPOSIT_ADDRESS = 'address-deposit',
  API_KEY = 'api-key',
  NOTIFICATIONS_LIST = 'notifications',
  PROFILE = 'profile',
  LANDING_PAGE = TRADER_INFORMATION,
  EARNINGS = 'earnings',
  EARNING_DETAIL = 'earning-detail',
  REPORTS = 'reports',
  SALES = 'sales',
  FUTCARD = 'futcard',
  FUTCARD_DETAIL = 'futcard-detail',
  SWAP = 'swap',
  SWAP_DETAIL = 'swap-detail',
  RECHARGE_REQUEST = 'recharge-request',
  CARD_REQUEST = 'card-request',
  RECHARGE_REQUEST_DETAIL = 'recharge-request-detail',
  CARD_REQUEST_DETAIL = 'card-request-detail',
  SWAP_REPORT = 'swap-report',
  USERS = 'users',
  ROLES = 'roles',
  ADD_ROLE = 'configuration-role',
  OTC_LIST = 'otc',
  OTC = 'otc-detail',

  NOT_FOUND = '**'
}
