import {Injectable} from '@angular/core';
import {formatCurrency} from '@angular/common';
import {CoinConstants} from '../constants/coin.constants';
import {FixedNoRoundPipe} from '../pipe/fixed-no-round.pipe';
import {ThousandPipe} from '../pipe/thousand.pipe';
import {TxTarget} from '../Response/TxTarget.entity';
import {environment} from '../../../environments/environment';


@Injectable(
  {providedIn: 'root'}
)
export class CurrencyUtils {

  private fixedPipe: FixedNoRoundPipe;
  private thousandPipe: ThousandPipe;

  constructor() {
    this.fixedPipe = new FixedNoRoundPipe();
    this.thousandPipe = new ThousandPipe();
  }

  static toUSDCurrencyFormat(amount: number, currency: string = '$'): string {
    return formatCurrency(amount, 'en-US', currency);
  }


  static fixedValues(val: number, coinSymbol) {
    switch (coinSymbol) {
      case CoinConstants.ETH.symbol:
        return val.toFixed(3);
      case CoinConstants.BTC.symbol:
        return val.toFixed(6);
      default:
        return val.toFixed(2);
    }
  }

  static precision(coinSymbol: string, forDetail?: boolean): number {
    if (forDetail) {
      switch (coinSymbol) {
        case CoinConstants.BTC.symbol:
          return 8;
      }
    }
    // if (forCard) {
    //   return 4;
    // }
    switch (coinSymbol) {
      case CoinConstants.USDT.symbol:
      case CoinConstants.USD:
        return 2;
      case '1':
        return 1;
      default:
        return 6;
    }
  }


  public formatCurrency(value: number, currency: string = 'USD', forDetail?: boolean): string {
    if (isNaN(value) || (!value && value !== 0) || isNaN(Number(value))) {
      return '';
    }
    if (Number.isInteger(value)) {
      return this.thousandPipe.transform(value);
    }
    const fixedValue = this.fixedPipe.transform(value, CurrencyUtils.precision(currency, forDetail));
    const splitByDot = fixedValue.split('.');
    const valueWithThousands = this.thousandPipe.transform(splitByDot[0]);
    return splitByDot[1] ? [valueWithThousands, splitByDot[1]].join('.') : splitByDot[0];
  }

  public getUrlHash(tx: TxTarget) {
    switch (tx?.coinSymbol) {
      case CoinConstants.BTC.symbol:
        if (environment.production) {
          return `https://www.blockchain.com/btc/tx/${tx?.hash}`;
        } else {
          return `https://www.blockchain.com/btc-testnet/tx/${tx?.hash}`;
        }
      case CoinConstants.ETH.symbol:
        if (environment.production) {
          return `https://etherscan.io/tx/${tx?.hash}`;
        } else {
          return `https://goerli.etherscan.io/tx/${tx?.hash}`;
        }
      case CoinConstants.ETHE:
        if (environment.production) {
          return `https://etherscan.io/tx/${tx?.hash}`;
        } else {
          return `https://goerli.etherscan.io/tx/${tx?.hash}`;
        }
      case CoinConstants.USDT.networkSymbol:
        if (environment.production) {
          return `https://tronscan.org/#/transaction/${tx?.hash}`;
        } else {
          return `https://shasta.tronscan.org/#/transaction/${tx?.hash}`;
        }
      case CoinConstants.BNB.networkSymbol:
        if (environment.production) {
          return `https://bscscan.com/tx/${tx?.hash}`;
        } else {
          return `https://testnet.bscscan.com/tx/${tx?.hash}`;
        }
      case CoinConstants.MATIC.networkSymbol:
        if (environment.production) {
          return `https://polygonscan.com/tx/${tx?.hash}`;
        } else {
          return `https://mumbai.polygonscan.com/tx/${tx?.hash}`;
        }
    }
  }
}
