import {createAction, props} from '@ngrx/store';
import {Module} from '../../../../shared/domain/Response/Modules.entity';
import {BaseListResponse} from '../../../../shared/domain/Response/BaseListResponse.entity';
import {Alerts} from '../../domain/alerts';
import {RolModules} from '../../../../shared/domain/Response/RolModules.entity';

export const addHomeModules = createAction('[VerticalMenu] Add HomeModules', props<{ homeModules: Module }>());
export const addRoles = createAction('[VerticalMenu] Add Roles', props<{ roles: RolModules }>());
export const addAlerts = createAction('[VerticalMenu] Add Alerts', props<{ alerts: BaseListResponse<Alerts> }>());
export const addModulesError = createAction('[VerticalMenu] Add error of HomeModules', props<{
  modulesError: string
}>());
export const clearHomeModulesStore = createAction('[VerticalMenu] clear HomeModules store');
