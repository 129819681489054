<div class="header">
    <div class="header__top top" [class.increased]="UserSearch || useCoinSelect">
        <app-back-link class="top__back-link"></app-back-link>
        <div class="top__container">
            <span class="top__title">{{title | translate}}</span>
            <mat-icon class="top__icon"
                      matTooltip="{{tooltip | translate}}"
                      aria-label="Button that displays a tooltip when focused or hovered over">
                info
            </mat-icon>
        </div>
        <user-search-input *ngIf="useUserSearch" class="top__user-search" [(userSearch)]="UserSearch"
                           (userSearchChange)="onUserSearch()">
        </user-search-input>
        <div class="top__coin-select coin-select" #Select *ngIf="useCoinSelect" [cdkMenuTriggerFor]="CoinsMenu"
             (cdkMenuOpened)="setMenuSize(Select)">
            <img *ngFor="let coin of coinSelectOptions" [ngSrc]="coin.iconPath" width="60" height="60"
                 disableOptimizedSrcset alt="..."
                 [class.d-none]="!(coin.symbol === selectedCoin.symbol && coin.blockchainSymbol === selectedCoin.blockchainSymbol)"
                 class="coin-select__img">
            <span class="coin-select__text">{{ coinOptionToShow(selectedCoin) }}</span>
            <span class="coin-select__icon material-symbols-outlined material-icons">expand_more</span>
        </div>
    </div>
    <div class="header__content content">
        <div class="content__filter content__filter--user-search" *ngIf="useUserSearch">
            <user-search-input [(userSearch)]="UserSearch" (userSearchChange)="onUserSearch()">
            </user-search-input>
        </div>
        <div class="content__filter" *ngIf="searchParamOptions || statusOpts">
            <app-search-param (search)="setParams($event)"
                              [searchParams]="searchParamOptions"
                              [statusI18nDir]="statusI18nDir"
                              [movements]="swapMovements"
                              [types]="swapTypes"
                              [typeJsonDir]="typeI18nDir"
                              [movementJsonDir]="movementI18nDir"
                              [defaultSearchParam]="selectedSearchParam"
                              [defaultSearchValue]="selectedSearchValue"
                              [statusOpts]="statusOpts"></app-search-param>
        </div>
        <div class="content__filter" *ngIf="startDate && finalDate">
            <app-date-picker [(startDate)]="startDate"
                             [(endDate)]="finalDate"
                             (search)="onSearch()">
            </app-date-picker>
        </div>
        <div class="content__filter content__filter--sort" *ngIf="sortParamOptions">
            <app-search-param (sort)="setSortParams($event)"
                              [defaultSortValue]="selectedSortValue"
                              [defaultSortParam]="selectedSortParam"
                              [sortParams]="sortParamOptions"></app-search-param>
        </div>
        <div class="content__filter content__filter--actions actions" *ngIf="actions.length > 0">
            <div class="actions__coin-select coin-select" #Select *ngIf="useCoinSelect" [cdkMenuTriggerFor]="CoinsMenu"
                 (cdkMenuOpened)="setMenuSize(Select)">
                <img *ngFor="let coin of coinSelectOptions" [ngSrc]="coin.iconPath" width="60" height="60"
                     disableOptimizedSrcset alt="..."
                     [class.d-none]="!(coin.symbol === selectedCoin.symbol && coin.blockchainSymbol === selectedCoin.blockchainSymbol)"
                     class="coin-select__img">
                <span class="coin-select__text">{{ coinOptionToShow(selectedCoin) }}</span>
                <span class="coin-select__icon material-symbols-outlined material-icons">expand_more</span>
            </div>
            <app-select-coin-attach-file class="actions__btn actions__btn--from-component"
                                         *ngIf="actions.includes(action.UPLOAD_XLS)"
                                         [matDialog]="matDialog"
                                         [sharedBtnId]="sharedBtnId"
                                         (fileEmitter)="upload($event)">
            </app-select-coin-attach-file>
            <button matRipple class="actions__btn" [class.in-swaps]="useCoinSelect"
                    *ngIf="actions.includes(action.DOWNLOAD_XLS)"
                    (click)="download({searchParam, searchValue, startDate, finalDate})">
                <mat-icon>cloud_download</mat-icon>
                <span>{{"DEPOSITS.EXCEL" | translate}}</span>
            </button>
            <button matRipple class="actions__btn" *ngIf="actions.includes(action.CREATE_BILL)"
                    (click)="openGenerateBillModal()">
                <mat-icon>receipt</mat-icon>
                <span>{{"BILL.CREATE_BILL" | translate}}</span>
            </button>
            <button matRipple class="actions__btn" *ngIf="actions.includes(action.GO_DEPOSIT)" (click)="goToDeposit()">
                <mat-icon class="material-icons-outlined">wallet</mat-icon>
                <span>{{"DEPOSITS.DEPOSIT" | translate}}</span>
            </button>
            <button matRipple class="actions__btn" *ngIf="actions.includes(action.CREATE_NOTIFICATION)"
                    (click)="createNotification()">
                <mat-icon>add_alert</mat-icon>
                <span>{{'NOTIFICATIONS.LIST.CREATE' | translate}}</span>
            </button>
            <button matRipple class="actions__btn actions__btn--create-user" *ngIf="actions.includes(action.CREATE_USER)"
                    (click)="onCreateUser()">
                <mat-icon class="material-icons-outlined">person_add</mat-icon>
                <span>{{"USERS.NEW_USERS" | translate}}</span>
            </button>
            <button matRipple class="actions__btn actions__btn--create-user" *ngIf="actions.includes(action.CREATE_ROLE)"
                    (click)="onCreateRol()">
                <mat-icon class="material-icons-outlined">manage_accounts</mat-icon>
                <span>{{"ROLES.NEW_ROLES" | translate}}</span>
            </button>
        </div>
    </div>
</div>

<ng-template #CoinsMenu>
    <div class="coins-menu" cdkMenu>
        <div class="coins-menu__wrapper">
            <div class="coins-menu__option option" *ngFor="let coin of coinSelectOptions" cdkMenuItemCheckbox
                 [cdkMenuItemChecked]="coin === selectedCoin" (cdkMenuItemTriggered)="onSelectedCoin(coin)">
                <img [ngSrc]="coin.iconPath" width="60" height="60" disableOptimizedSrcset alt="..."
                     class="option__img">
                <span class="option__text">{{ coinOptionToShow(coin) }}</span>
            </div>
        </div>
    </div>
</ng-template>
