import {createAction, props} from '@ngrx/store';
import {TraderInfo} from '../../domain/entities/trader-info.entity';
import {HttpErrorResponse} from '@angular/common/http';
import {OtcPlanEntity} from '../../domain/entities/otc-plan.entity';
import {CountryCodeEntity} from '../../domain/entities/country-code.entity';

export const addTraderInformation = createAction
('[TraderInformation] Add TraderInformation', props<{ traderInformation: TraderInfo }>());
export const addOtcPlan = createAction
('[TraderInformation] Add OtcPlan', props<{ otcPlan: Array<OtcPlanEntity> }>());
export const addCountryCode = createAction
('[TraderInformation] Add CountryCode', props<{ countryCode: Array<CountryCodeEntity> }>());
export const addSecond2FAStatus = createAction
('[TraderInformation] Add Second2FAStatus', props<{ faQrStatus: { status: boolean } }>());
export const addTraderInformationError = createAction('[TraderInformation] Add error of TraderInformation', props<{
  traderError: string | HttpErrorResponse
}>());
export const clearTraderInformationStore = createAction('[TraderInformation] clear TraderInformation store');
