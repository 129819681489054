import {Action, createReducer, on} from '@ngrx/store';

import * as RolesActions from './roles-store-actions';
import {RolesResponse} from '../../domain/roles-list/roles.response';
import {RoleDetailResponse} from '../../domain/role-detail/role-detail.response';

export interface RolesState {
  roles: RolesResponse;
  roleDetail: RoleDetailResponse;
  error: string;
}

const initialRolesState: RolesState = {
  roles: null,
  roleDetail: null,
  error: null
};

const reducer = createReducer(
  initialRolesState,
  on(RolesActions.addRoles, (state, payload) =>
    ({
      ...state,
      roles: payload.roles,
      roleDetail: state.roleDetail,
      error: null
    })),
  on(RolesActions.addRoleDetail, (state, payload) =>
    ({
      ...state,
      roles: state.roles,
      roleDetail: payload.roleDetail,
      error: null
    })),
  on(RolesActions.addError, (state, payload) => {
    return ({
      ...state,
      roles: state.roles,
      roleDetail: state.roleDetail,
      error: payload.error
    });
  }),
  on(RolesActions.clearRoles, state => initialRolesState)
);

export function RolesReducer(state: RolesState, action: Action) {
  return reducer(state, action);
}
