import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'ftswp-input',
  templateUrl: './ftswp-input.component.html',
  styleUrls: ['./ftswp-input.component.scss']
})
export class FtswpInputComponent {

}
