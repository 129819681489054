import {Component, Input, OnInit} from '@angular/core';
import {ConsolidateTableResponse} from '../../../domain/Response/consolidate-table.response';
import {DateUtils} from '../../../domain/Utils/DateUtils';
import {Router} from '@angular/router';
import {CoinConstants} from '../../../domain/constants/coin.constants';
import {RouterConstants} from '../../../Infrastructure/router.constants';
import {StorageService} from '../../../Infrastructure/Services/storage.service';
import {CurrencyUtils} from '../../../domain/Utils/CurrencyUtils';

@Component({
  selector: 'app-list-cards',
  templateUrl: './list-cards.component.html',
  styleUrls: ['./list-cards.component.scss']
})
export class ListCardsComponent implements OnInit {

  @Input() withdrawals: Array<ConsolidateTableResponse>;
  @Input() deposits: Array<ConsolidateTableResponse>;
  @Input() client: string;
  @Input() totalWithdrawals: number;
  @Input() totalDeposits: number;
  @Input() total: number;
  @Input() coinSymbol: string;
  @Input() clientId: string;


  public fields = [
    'REPORTS.DATE',
    'REPORTS.ID',
    'REPORTS.QUANTITY',
    'REPORTS.VALUE',
    'Fee',
    'Total',
    'TRANSACTIONS.DETAIL'
  ];
  constructor(
    public dateUtils: DateUtils,
    private router: Router,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
  }

  toCurrency(amount: number){
    amount = Number(CurrencyUtils.fixedValues(amount, this.coinSymbol));
    switch (this.coinSymbol) {
      case CoinConstants.USDT.symbol:
      case CoinConstants.USDTT.symbol:
      case CoinConstants.USDTE.symbol:
        return CurrencyUtils.toUSDCurrencyFormat(amount);
      default:
        return amount;
    }
  }

  async goToDetail(invoiceId: string) {
    this.storageService.setReportSelectedCompany(this.clientId);
    await this.router.navigate([RouterConstants.BILLING, RouterConstants.BILLING_DETAIL],
      {queryParams: {invoiceId}});
  }

}
