import {StringValueObject} from '../../../../shared/domain/ValueObject/StringValueObject';
import {InvalidArgumentError} from '../../../../shared/domain/ValueObject/InvalidArgumentError';

export class LoginUserPassword extends StringValueObject {

  constructor(value: string) {
    super(value);
    this.ensureTextIsNotEmpty(value);
    this.ensureLengthIsGreaterThan3Characters(value);
  }

  private ensureTextIsNotEmpty(value: string): void {
    if (!value) {
      throw new InvalidArgumentError('PASSWORD_IS_REQUIRED');
    }
  }

  private ensureLengthIsGreaterThan3Characters(value: string): void {
    if (value.length > 30) {
      throw new InvalidArgumentError('PASSWORD_MUST_BE_GREATER_THAN_3_CHARACTERS');
    }
  }

  toString(): string {
    return super.toString();
  }
}
