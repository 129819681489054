import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'user-search-input',
  templateUrl: './user-search-input.component.html',
  styleUrls: ['./user-search-input.component.scss']
})
export class UserSearchInputComponent {

  @Input()
  userSearch: string;

  @Output()
  userSearchChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  search() {
    this.userSearchChange.emit(this.userSearch);
  }
}
